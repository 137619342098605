// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountBlanketPurchaseOrder } from './account-blanket-purchase-order';
import { AccountProcurementCard } from './account-procurement-card';
import { ActiveStatus } from './active-status';
import { ShippingUserGroupAddressMap } from './shipping-user-group-address-map';
import { ShippingUserGroupMap } from './shipping-user-group-map';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ShippingUserGroup extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  getStatusParents() {
    return [this.account];
  }

  getStatusId() {
    return this.activeStatusId;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountBlanketPurchaseOrder */
  accountBlanketPurchaseOrderId?: string;
  /** Guid FK account */
  accountId!: string;
  /** Guid FK accountProcurementCard */
  accountProcurementCardId?: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** String(60) */
  name!: string;
  /** Binary */
  rowVersion!: any;
  /** FK accountId */
  account!: Account;
  /** FK accountBlanketPurchaseOrderId */
  accountBlanketPurchaseOrder!: AccountBlanketPurchaseOrder;
  /** FK accountProcurementCardId */
  accountProcurementCard!: AccountProcurementCard;
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK shippingUserGroupId */
  shippingUserGroupAddressMaps!: ShippingUserGroupAddressMap[];
  /** Inv FK shippingUserGroupId */
  shippingUserGroupMaps!: ShippingUserGroupMap[];
}

