import { Injectable } from '@angular/core';
import { ActiveStatus, ActiveStatusEnum } from '@models';

@Injectable({
  providedIn: 'root'
})
export class ProgramStatusService {
  opts: ActiveStatus[] = [];
  checklist: StatusChangeChecklist[] = [];

  constructor() {
    this.checklist.push({
      index: 0,
      description: 'Program is not expired',
      pass: false
    });
    this.checklist.push({
      index: 1,
      description: 'All products have working status',
      pass: false
    });
    this.checklist.push({
      index: 2,
      description: 'One or more Program User Group(s) exist',
      pass: false
    });
    this.checklist.push({
      index: 3,
      description: 'All Program User Groups included in Proximity Account Organization Tree',
      pass: false
    });
    this.checklist.push({
      index: 4,
      description: 'Purchase order is current',
      pass: false
    });
    this.checklist.push({
      index: 5,
      description: 'All issuance program products are tagged with valid issuance categories',
      pass: false
    });

  }

  nextActiveStatusOptions(currentStatus: ActiveStatusEnum, allStatuses: ActiveStatus[]): ActiveStatus[] {
    this.opts = [];
    if (currentStatus == ActiveStatusEnum.Hold) {
      this.opts.push(allStatuses.filter(x => x.id == ActiveStatusEnum.Active)[0]);
      this.opts.push(allStatuses.filter(x => x.id == ActiveStatusEnum.Inactive)[0]);
    }
    return this.opts;
  }
}

export interface StatusChangeChecklist {
  index: number;
  description: string;
  pass: boolean;
}
