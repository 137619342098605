/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GetRowIdParams, GridOptions, GridReadyEvent, RowSelectedEvent, ValueSetterParams } from '@ag-grid-community/core';
import {
  Component
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgFns, ISortModel, ProxAgFns, SaveAndQueryComponent, StatusService } from '@core';

import { BaseService } from '@core';

import { UtilFns } from '@utils';

import * as _ from 'lodash';
import { EntityFns } from '@data';

import { Entity, EntityError } from 'breeze-client';
import { AccountAdminGroup, AccountAdmin, AccountUser, ApprovalTree, ApprovalTreeAdminGroup, ApprovalTreeUserGroup, Program, ProgramUserGroup, Account, ProximityRightEnum } from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountAdminGroupFinderDialog } from '../account-administrator/account-admin-group-finder.dialog';
import { ProgramUserGroupFinderDialog } from '../account-users/program-user-group-finder.dialog';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'prox-approval-trees',
  templateUrl: './approval-trees.component.html',
})
export class ApprovalTreesComponent extends SaveAndQueryComponent {
  supplierId!: string;
  accountId!: string;
  account!: Account;
  accountAdminGroups!: AccountAdminGroup[];
  programUserGroups!: ProgramUserGroup[];
  atGridOptions!: GridOptions<ApprovalTree>;
  approvalTrees?: ApprovalTree[];
  selectedApprovalTree?: ApprovalTree;
  treeGridOptions!: GridOptions;
  treeData!: any[];
  selectedTreeItem: any;
  userGridOptions!: GridOptions;
  showUsers = false;
  dims = [40, 60, 99, 1];
  usersOrAdmins?: AccountUser[] | AccountAdmin[];
    
  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: StatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, "accountId");

    this.account = await this.dbQueryService.getAccountById(this.accountId);
    UtilFns.assertNonNull(this.account, 'Account');

    this.baseService.storeLocationParams(this.route);
    this.approvalTrees = await this.dbQueryService.getApprovalTrees(this.accountId);
    this.atGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAtGridReady,
      onRowSelected: this.onAtRowSelected,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const at = rowIdParams.data as ApprovalTree;
        return at.id;
      },
    });

    AgFns.captureGridRouteParams(this.atGridOptions, this.route, 'id');
    this.treeGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onTreeGridReady,
      onRowSelected: this.onTreeGridRowSelected,
      groupDefaultExpanded: -1,
      treeData: true,
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data;
        return x.orgPath.join('|')
      },
      getDataPath: (data: any) => {
        return data.orgPath;
      }
    } );

    this.userGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onUserGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const x = rowIdParams.data as AccountUser | AccountAdmin;
        return x?.id;
      },
    });
    this.setTitle('Proximity Organization in ' + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  onShowUsers() {
    if (!this.showUsers) {
      this.showUsers = true;
      this.dims = [25, 75, 50, 50];
    } else {
      this.showUsers = false;
      this.dims = [40, 60, 99, 1];
    }
  }

  addAdminGroupsLabel() {
    if (this.selectedApprovalTree) {
      return "Add Administration Groups to " + this.selectedApprovalTree.name;
    }
    return "Add Administration Groups";
  }

  showUsersLabel() {
    if (!this.selectedTreeItem) {
      return "";
    }

    let sLabel = "Show";
    if (this.showUsers) {
     sLabel = 'Hide';
    };

    if ((this.selectedTreeItem?.entity as Entity) instanceof ApprovalTreeAdminGroup) {
      sLabel += " Administrators in " + (this.selectedTreeItem?.entity as ApprovalTreeAdminGroup)?.accountAdminGroup?.name + ' Administrator Group';
    };

    if ((this.selectedTreeItem?.entity as Entity) instanceof ApprovalTreeUserGroup) {
      sLabel += " Users in " + (this.selectedTreeItem?.entity as ApprovalTreeUserGroup)?.programUserGroup?.name + ' User Group';
    }; 

    return sLabel;
  }

  // Proximity Account Organization Tree grid
  onAtGridReady(event: GridReadyEvent<ApprovalTree>) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getAtColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    AgFns.refreshGrid(this.atGridOptions, this.approvalTrees);
    AgFns.applyGridRouteParams(this.atGridOptions);
  }

  getAtColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Name', field: 'name', editable: !this.isReadOnly(), filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', editable: !this.isReadOnly(), filter: 'agTextColumnFilter'},
      ProxAgFns.getEntityDeleteColDef(this.onAtDelete.bind(this), { canDisplay: this.canDelete.bind(this)})
    ];
    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }
  
  onAtRowSelected(event: RowSelectedEvent<ApprovalTree>) {
    if (!event.node.isSelected()) return;
    const at = event.data as ApprovalTree;
    if (at == null) return;
    this.selectedApprovalTree = at;
    if (at.entityAspect.entityState.isDeleted()) {
      this.selectedApprovalTree = undefined;
      this.atGridOptions.api?.deselectAll();
      return;
    }
    this.updateGridRouteParams(this.atGridOptions, at.id);
    this.hydrateApprovalTree(at);
  }

  onAtAdd() {
    const ac = this.dbSaveService.createEntity(ApprovalTree, { accountId: this.accountId });
    ac.entityAspect.validateEntity();
    this.approvalTrees?.push(ac);
    AgFns.refreshGrid(this.atGridOptions, this.approvalTrees);
    AgFns.selectGridRowByKey(this.atGridOptions, e => e.id, ac.id);
  }

  async onAtDelete(at: ApprovalTree) {
    const inUse = await this.dbQueryService.checkIfInUse(at, Program, 'approvalTreeId');
    if (inUse) {
      this.toastr.warning(`You cannot delete an Account User Group that is in use.`, 'Cannot Delete');
      return;
    }

    // if it's an added record - cascade delete won't catch them.
    if (at.entityAspect.entityState.isAdded()) {
      at.approvalTreeAdminGroups.slice().forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    }
    EntityFns.deleteOrDetach(at.entityAspect);
    this.selectedApprovalTree = undefined;
    if (this.approvalTrees) {
      _.remove(this.approvalTrees, at);
      AgFns.refreshGrid(this.atGridOptions, this.approvalTrees);
    }
  }

  // ---------- Tree  Grid ------------------------------

  onTreeGridReady() {
    const colDefs = [
      { ...AgFns.createIconProps('', '', () => null, { label: '', getIcon: this.getIcon.bind(this) } ), maxWidth: 40, width: 40},
      { headerName: 'Type', field: 'type', sortable: false, width: 120, maxWidth: 120 },
      { ...AgFns.createButtonProps('View/Edit', this.onGotoGroup.bind(this), { label: 'View' }), maxWidth: 100, width: 100},
      { headerName: 'Approval Limit',  
        editable: (item) =>  { 
          return !this.isReadOnly() && item.data.entity instanceof ApprovalTreeAdminGroup && item.data.entity.parentApprovalTreeAdminGroupId != null; 
        },
        sortable: false,
        type: 'rightAligned',
        cellStyle: { 'justify-content': "flex-end" },
        valueGetter: p => {
          const atag = p.data.entity as ApprovalTreeAdminGroup;
          if (atag instanceof ApprovalTreeUserGroup ) return '';
          if (atag.parentApprovalTreeAdminGroupId == null ) return ' - No Limit -';
          const amt = atag.budgetApprovalLimitAmt;
          return amt ? UtilFns.fmtCurrencyA(amt, 2) : '';
        },
        valueSetter: (p: ValueSetterParams) => {
          const atag = p.data.entity as ApprovalTreeAdminGroup;
          if (!atag ) return false;
          if (atag.parentApprovalTreeAdminGroupId == null ) return false;
          atag.budgetApprovalLimitAmt =   UtilFns.parseNumber(p.newValue);
          return true;
        }
      },
      { headerName: 'Approvals Amt YTD',  
      
      sortable: false,
      type: 'rightAligned',
      cellStyle: { 'justify-content': "flex-end" },
      valueGetter: p => {
        const atag = p.data.entity as ApprovalTreeAdminGroup;
        if (atag instanceof ApprovalTreeUserGroup ) return '';
        const amt =  atag.calcPrevAmtFromLogs()
        return amt ? UtilFns.fmtCurrencyA(amt, 2) : '';
      },
    },
      { ...AgFns.createButtonProps('', this.onTreeAttachToAdminGroup.bind(this), { 
          label: 'Attach Admin Group',
          style: 'button',
          canDisplay: this.canAttachGroup.bind(this)  
        }), 
        width: 100 
      },
      { ...AgFns.createButtonProps('', this.onTreeAttachToUserGroup.bind(this), { 
          label: 'Attach User Group', 
          style: 'button', 
          canDisplay: this.canAttachGroup.bind(this)  
        }), 
        width: 100 
      },
      // { headerName: 'Budget Trigger Amt', field: 'budgetTriggerAmt', filter: 'agNumberColumnFilter', editable: true, sortable: false },
       ProxAgFns.getEntityDeleteColDef(this.onTreeItemDelete.bind(this), { canDisplay: this.canDelete.bind(this)})
    ];
    // don't sort grids with a DisplayOrderColumnDef
    const sortModel: ISortModel = [     ];
    AgFns.initGrid(this.treeGridOptions, colDefs, sortModel);
    AgFns.selectFirstRow(this.treeGridOptions);

  }

  onGotoGroup(treeItem: any) {
    const entity = treeItem.entity as Entity;
    if (entity instanceof ApprovalTreeAdminGroup) {
      this.router.navigate(['account/accounts', this.accountId, 'manage', 'account-admin-groups'], { queryParams: { id: entity.accountAdminGroupId } });
    } else if (entity instanceof ApprovalTreeUserGroup) {
      this.router.navigate(['account/accounts', this.accountId, 'manage', 'program-user-groups'], { queryParams: { id: entity.programUserGroupId } });
    }
  }

  async onTreeGridRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const group = event.data;
    if (group == null) return;
    this.selectedTreeItem = group;
    const entity = group.entity as Entity;
    if (entity.entityAspect.entityState.isDeleted()) {
      this.selectedApprovalTree = undefined;
      this.atGridOptions.api?.deselectAll();
      return;
    }
    if (entity instanceof ApprovalTreeAdminGroup) {
      this.usersOrAdmins = await this.dbQueryService.getAccountAdminGroupAdmins(entity.accountAdminGroupId);
    } else if (entity instanceof ApprovalTreeUserGroup) {
      this.usersOrAdmins = await this.dbQueryService.getAccountUsersForProgramUserGroup(entity.programUserGroupId);
    }
    AgFns.refreshGrid(this.userGridOptions, this.usersOrAdmins);
  }

  canAttachGroup(treeItem: any) {
    const e = treeItem.entity as (ApprovalTreeAdminGroup | ApprovalTreeUserGroup)
    return (e instanceof ApprovalTreeAdminGroup) && !this.isReadOnly();
  }

  async onTreeAttachToAdminGroup(treeItem?: any) {
    if (this.selectedApprovalTree == null) return;
    const approvalTree = this.selectedApprovalTree;
    let parentId: string | undefined = undefined;
    if (treeItem != null) {
      const atAg = treeItem.entity as ApprovalTreeAdminGroup;
      parentId = atAg.id;
    }
    
    if (!this.accountAdminGroups) {
      this.accountAdminGroups = await this.dbQueryService.getAccountAdminGroups(this.accountId);
    }
    const aagSet = new Set(approvalTree.approvalTreeAdminGroups.map(x => x.accountAdminGroup));
    const unusedAccountAdminGroups = this.accountAdminGroups.filter(x => !aagSet.has(x));
    const newAdminGroups = await this.dialogService.createFinder(AccountAdminGroupFinderDialog, {
      accountAdminGroups: unusedAccountAdminGroups,
      rowSelection: 'multiple',
    }) ;
    
    const atAgs = newAdminGroups.map(ent => {
      return this.dbSaveService.createEntity(ApprovalTreeAdminGroup, {
        id: Guid.create().toString(),
        approvalTreeId: approvalTree.id,
        accountAdminGroupId: ent.id,
        parentApprovalTreeAdminGroupId: parentId
      });
    });
    
    this.hydrateApprovalTree(approvalTree);
  }

  async onTreeAttachToUserGroup(treeItem: any) {
    const atAg = treeItem.entity as ApprovalTreeAdminGroup;
    const approvalTree = atAg.approvalTree;
    
    if (!this.programUserGroups) {
      this.programUserGroups = (await this.dbQueryService.getProgramUserGroups(this.accountId));
    }
    const augSet = new Set(_.flatten(approvalTree.approvalTreeAdminGroups.map(x => x.approvalTreeUserGroups.map(y => y.programUserGroup))));
    const unusedProgramUserGroups = this.programUserGroups.filter(x => !augSet.has(x));
    const newUserGroups = await this.dialogService.createFinder(ProgramUserGroupFinderDialog, {
      programUserGroups: unusedProgramUserGroups,
      rowSelection: 'multiple',
    }) ;
    
    const atPugs = newUserGroups.map(ent => {
      return this.dbSaveService.createEntity(ApprovalTreeUserGroup, {
        approvalTreeId: approvalTree.id,
        approvalTreeAdminGroupId: atAg.id,
        programUserGroupId: ent.id
      });
    });
    
    this.hydrateApprovalTree(approvalTree);
  }

  async onTreeItemDelete(treeItem: any) {
    if (this.selectedApprovalTree == null) return;
    const e = treeItem.entity as (ApprovalTreeAdminGroup | ApprovalTreeUserGroup)
    if (e instanceof ApprovalTreeAdminGroup) {
      this.onTreeAdminGroupDelete(e);
    } else {
      EntityFns.deleteOrDetach(e.entityAspect);
    }
    this.hydrateApprovalTree(this.selectedApprovalTree);
  }

  // recursive deletion
  async onTreeAdminGroupDelete(atAg: ApprovalTreeAdminGroup) {
    if (this.selectedApprovalTree == null) return;
    atAg.childrenApprovalTreeAdminGroups.slice().forEach(e => this.onTreeAdminGroupDelete(e));
    atAg.approvalTreeUserGroups.slice().forEach(e => EntityFns.deleteOrDetach(e.entityAspect));

    EntityFns.deleteOrDetach(atAg.entityAspect);
  }

  getIcon(treeItem: any) {
    const e = treeItem.entity as (ApprovalTreeAdminGroup | ApprovalTreeUserGroup)
    if (e instanceof ApprovalTreeAdminGroup) {
      return 'fa-regular fa-sitemap';
    } else {
      return 'pi pi-user';
    }
  }

  // -------------- Users or Admins Grid --------------------------

  onUserGridReady(event: GridReadyEvent<ApprovalTree>) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getUserColDefsAndSortModel();
    AgFns.initGrid(gridOptions, colDefs, sortModel);
  }

  getUserColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      { headerName: 'Last Name', field: 'proximityUser.lastName', maxWidth: 150, filter: 'agTextColumnFilter',   },
      { headerName: 'First Name', field: 'proximityUser.firstName', maxWidth: 150, filter: 'agTextColumnFilter',  },
      { headerName: 'Middle Name', field: 'proximityUser.middleName', maxWidth: 100, filter: 'agTextColumnFilter', },
      { headerName: 'Salutation', field: 'proximityUser.salutation', maxWidth: 100, filter: 'agTextColumnFilter',  }, 
      { headerName: 'E-Mail', field: 'proximityUser.email', filter: 'agTextColumnFilter',  },
    ];
    const sortModel: ISortModel = [
      { colId: 'proximityUser.lastName', sort: 'asc' },
      { colId: 'proximityUser.firstName', sort: 'asc' },
    ]
    return [colDefs, sortModel] as const;
  }

  // -------------- Prepare Proximity Account Organization Tree for display -----------------------

  async hydrateApprovalTree(at: ApprovalTree) {
    const treeData: any[] = [];
    at.approvalTreeAdminGroups.filter(atag => atag.parentApprovalTreeAdminGroup == null).forEach(atag => {
      treeData.push(...this.hydrateApprovalTreeAdminGroup(atag, [  ]) );
    })
    this.treeData = treeData;
    AgFns.refreshGrid(this.treeGridOptions, this.treeData);
    this.usersOrAdmins = [];
    AgFns.refreshGrid(this.userGridOptions, this.usersOrAdmins);
    
    AgFns.selectFirstRow(this.treeGridOptions);
    return treeData;
  }

  hydrateApprovalTreeAdminGroup(atag: ApprovalTreeAdminGroup, parentPath: string[]) {
    const treeData: any[] = [];
    const path = [ ...parentPath, atag.accountAdminGroup.name ];
    treeData.push( {
      orgPath: path,
      entity: atag,
      type: 'Admin Group',
      name: atag.accountAdminGroup.name,
      adminGroup: atag.accountAdminGroup,
    });
    atag.approvalTreeUserGroups.forEach(child => {
      treeData.push(... this.hydrateApprovalTreeUserGroup(child, path));
    })
    atag.childrenApprovalTreeAdminGroups.forEach(child => {
      treeData.push(... this.hydrateApprovalTreeAdminGroup(child, path));
    })
    return treeData;
  } 

  hydrateApprovalTreeUserGroup(atug: ApprovalTreeUserGroup, parentPath: string[]) {
    const treeData: any[] = [];
    const path = [ ...parentPath, atug.programUserGroup.name ];
    treeData.push( {
      orgPath: path,
      entity: atug,
      type: 'User Group',
      name: atug.programUserGroup.name,
      userGroup: atug.programUserGroup
    });
    return treeData;
  }

    // ---------------------------------------------------------------------------------------

  override async addCrossValidationErrors() {
    if (!this.approvalTrees) return;
    EntityFns.checkForDupErrors(this.approvalTrees, e => e.name,
       (e, dupName) => this.createValidationError(e, 'name', `This name: '${dupName}' is a duplicate.`));
    
    this.approvalTrees.forEach(x => x.approvalTreeAdminGroups.forEach(y => {
      if (y.parentApprovalTreeAdminGroupId != null) {
        if (y.budgetApprovalLimitAmt == null || y.budgetApprovalLimitAmt == 0)  {
          this.createValidationError(y, 'budgetApprovalLimitAmt', `Admin Groups must have an approval limit > 0 :  ${ y.accountAdminGroup.name }` );
        } else if (y.parentApprovalTreeAdminGroup.parentApprovalTreeAdminGroupId != null
            && y.budgetApprovalLimitAmt  >= (y.parentApprovalTreeAdminGroup?.budgetApprovalLimitAmt ?? 0)) {
          this.createValidationError(y, 'budgetApprovalLimitAmt', `An Admin Groups must have a budget limit less than its parent admin group:  ${ y.accountAdminGroup.name }` );
        }
      }
    }));
  }    

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    this.approvalTrees = await this.dbQueryService.getApprovalTrees(this.accountId);
    AgFns.refreshGrid(this.atGridOptions, this.approvalTrees);

    if (this.selectedApprovalTree == null || this.selectedApprovalTree.entityAspect.entityState.isDetached()) {
      AgFns.selectFirstRow(this.atGridOptions);
    } else {
      this.hydrateApprovalTree(this.selectedApprovalTree);
    }
    
    AgFns.selectGridRowByKey(this.atGridOptions, e => e.id, this.selectedApprovalTree?.id || '')
    
  } 

  override navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    if (errEnt instanceof ApprovalTree) {
      AgFns.selectGridRowByKey(this.atGridOptions, (e: ApprovalTree) => e.id, errEnt.id);
    // } else  if (errEnt instanceof ApprovalTreeDetail) {
    //   AgFns.selectGridRowByKey(this.atGridOptions, (e: ApprovalTree) => e.id, errEnt.approvalChainId);
    //   AgFns.selectGridRowByKey(this.atDetailGridOptions, (e: ApprovalTreeDetail) => e.accountUserGroupId, errEnt.accountUserGroupId, prop);
    }
  }

}
