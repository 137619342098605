import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, SaveAndQueryComponent } from '@core';
import { environment } from '@env';
import { AccountUser } from '@models';
import { UtilFns } from '@utils';
import { firstValueFrom } from 'rxjs';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { PaymentPayload, PaymentProfile, Transaction, } from './payment-interfaces';
import { PaymentProfileDialogComponent, PaymentProfileDialogData, } from './payment-profile-dialog.component';

@Component({
  selector: 'prox-transaction-payments',
  templateUrl: './payments.component.html',
})
export class PaymentsComponent extends SaveAndQueryComponent {
  amount?: number;
  paymentProfiles?: PaymentProfile[];
  selectedProfile?: PaymentProfile;
  customerProfileId?: string;
  customerProfileName?: string;
  accountUser?: AccountUser;
  message?: string;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    private http: HttpClient
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    // need to be an account user (or impersonating one) to use this page
    const accountUserId =
      this.authUser?.accountUserId || params['accountUserId'];
    UtilFns.assertNonEmptyString(accountUserId, 'accountUserId');
    this.accountUser = await this.dbQueryService.getAccountUserById(
      accountUserId
    );

    await this.getCustomerProfile();

    this.isPageReady = true;
  }

  /** Load the user's payment profiles from Authorize.net */
  private async getCustomerProfile() {
    try {
      const result = await firstValueFrom(
        this.http.get<any>(
          environment.transactionUrl + '/GetPaymentsCustomerProfile',
        )
      );
      this.customerProfileId = result.profile.customerProfileId;
      this.customerProfileName = result.profile.description;
      this.paymentProfiles = result.profile.paymentProfiles as PaymentProfile[];
      if (this.paymentProfiles) {
        const def = this.paymentProfiles.find((x) => x.defaultPaymentProfile);
        this.selectedProfile = def || undefined;
      }
    } catch (e: any) {
      this.setMessage(e.error);
    }
  }

  /** Show a dialog for managing the user's payment profiles */
  async getProfilePage() {
    await this.dialogService.create<PaymentProfileDialogData>(
      PaymentProfileDialogComponent,
      { title: '' },
      PaymentProfileDialogComponent.config
    );
    // reload profile list after dialog closes
    this.getCustomerProfile();
  }

  /** Submit a payment using the currently selected profile */
  async submitPayment() {
    if (!this.selectedProfile) {
      return;
    }
    const payload = {
      customerProfileId: this.customerProfileId,
      customerPaymentProfileId: this.selectedProfile.customerPaymentProfileId,
      amount: this.amount,
      poNumber: 'test',
    } as PaymentPayload;
    this.amount = undefined;
    this.selectedProfile = undefined;
    try {
      const result = await firstValueFrom(
        this.http.post<any>(
          environment.transactionUrl + '/SubmitPayment',
          payload
        )
      );
      const tx = result.transactionResponse as Transaction;
      if (tx.errors) {
        this.setMessage(result);
      } else {
        this.message = `Transaction successful.  Amount: ${payload.amount},  Account Type: ${tx.accountType}  Account Number: ${tx.accountNumber}  Transaction ID: ${tx.transId}`;
      }
    } catch (e: any) {
      this.setMessage(e.error);
    }
  }

  /** Set the message content using error messages from the result */
  private setMessage(result: any) {
    console.log(result);
    let msg;
    if (result.transactionResponse?.errors) {
      msg = result.transactionResponse.errors
        .map((x) => `${x.errorText} Code: ${x.errorCode}`)
        .join('; ');
    } else {
      msg = result.messages.message.map((x) => x.text).join('; ');
    }
    this.message = msg;
  }
}
