import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface Choice {
  label: string;
  value: any;
}

export interface ChoiceDialogData {
  /** Choices in the list */
  choices: Choice[];
  /** Message above the list.  May be HTML */
  message: string;
  /** Whether to allow typing a value not in the list */
  editable?: boolean;
  /** Value to preselect in the list */
  selectedValue?: any;
  /** Label to preselect in the list (if selectedValue not provided) */
  selectedLabel?: string;
}

@Component({
  template: `
  <div class="h-full flex flex-column">
    <div class="text-l mb-3" [innerHTML]="message"></div>
    <div class="flex-grow-1 ml-6">
      <p-dropdown styleClass="w-full" [options]="data.choices" [(ngModel)]="selectedChoice" optionLabel="label" [editable]="!!data.editable" placeholder="Choose" />      
    </div>
    <div class="text-right mt-3">
      <button (click)="ok()" pButton type="button" icon="pi pi-check" label="Ok"
            class="p-button-raised mr-2" [disabled]="!selectedChoice"></button>
      <button (click)="cancel()" pButton type="button" icon="pi pi-times" label="Cancel"
            class="p-button-raised"></button>
    </div>
  </div>
  `,
})
export class ChoiceDialogComponent {

  data: ChoiceDialogData;
  selectedChoice?: Choice;
  message: SafeHtml;

  constructor(dialogConfig: DynamicDialogConfig, private ref: DynamicDialogRef, sanitizer: DomSanitizer) {
    this.data = dialogConfig.data;
    // this.selectedChoice = this.data.selectedChoice;
    this.message = sanitizer.bypassSecurityTrustHtml(dialogConfig.data.message);
    if (this.data.selectedValue) {
      this.selectedChoice = this.data.choices.find(x => x.value == this.data.selectedValue);
    } else if (this.data.selectedLabel) {
      this.selectedChoice = this.data.choices.find(x => x.label == this.data.selectedLabel);
      if (!this.selectedChoice && this.data.editable) {
        // if selectedLabel is not in the list, create a choice for it if list is editable
        this.selectedChoice = { label: this.data.selectedLabel, value: undefined };
      }
    }
  }

  cancel() {
    this.ref.close();
  }

  ok() {
    let sc = this.selectedChoice as any;
    if (this.data.editable && sc && !sc.label && !sc.value) {
      // user-typed value is returned as a string; convert it to a choice
      sc = { label: sc, value: undefined };
    }
    this.ref.close(sc);
  }

}
