<prox-main [parent]="this" [bodyTemplate]="bodyTemplate"></prox-main>

<ng-template #bodyTemplate>
  <div class="card h-full flex flex-column">
    <h4 class="card-header">
      <span>Payments</span>
    </h4>

    <div class="card-body flex-grow-1">
      <div class="mb-3">
        <h5>Payment profile: {{ customerProfileName }}</h5>
      </div>
      <div class="flex flex-column h-full w-full gap-2">
        <div class="flex gap-2">
          <div class="w-6">
            <prox-field label="Select payment method" *ngIf="paymentProfiles">
              <p-listbox
                [options]="paymentProfiles"
                [(ngModel)]="selectedProfile"
                [listStyle]="{ 'max-height': '250px' }"
              >
                <ng-template let-pm pTemplate="item">
                  <div
                    class="flex w-full gap-2"
                    *ngIf="pm.payment.Item.accountNumber"
                  >
                    <i class="fa fa-bank w-1"></i>
                    <div class="w-6">{{ pm.payment.Item.bankName }}</div>
                    <div class="w-5">{{ pm.payment.Item.accountNumber }}</div>
                  </div>
                  <div
                    class="flex w-full gap-2"
                    *ngIf="pm.payment.Item.cardNumber"
                  >
                    <i class="fa fa-credit-card w-1"></i>
                    <div class="w-6">{{ pm.payment.Item.cardType }}</div>
                    <div class="w-5">{{ pm.payment.Item.cardNumber }}</div>
                  </div>
                </ng-template>
              </p-listbox>
            </prox-field>
            <div class="card" *ngIf="!paymentProfiles">
              <a (click)="getProfilePage()"
                ><span class="font-italic"
                  >No payment methods found. Click to add a payment
                  method.</span
                ></a
              >
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <div class="w-3">
            <button
              pButton
              (click)="getProfilePage()"
              label="Manage payment methods"
              class="p-button-raised"
            ></button>
          </div>
        </div>

        <div class="flex gap-2">
          <div class="w-3">
            <prox-field label="Enter payment amount">
              <input
                type="text"
                pInputText
                [(ngModel)]="amount"
                name="amount"
              />
            </prox-field>
          </div>
        </div>

        <div class="flex gap-2">
          <div class="w-9">
            <span class="font-italic">{{ message }}</span>
          </div>
        </div>

        <div class="flex gap-2">
          <button
            [disabled]="!selectedProfile || !(amount && amount > 0)"
            (click)="submitPayment()"
            pButton
            type="button"
            label="Submit Payment"
            class="p-button-raised"
          ></button>
          <button
            (click)="onCancel()"
            pButton
            type="button"
            icon="pi pi-backward"
            label="Go Back"
            class="p-button-raised"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
