<div class="h-full flex flex-column">
  <h4>
    <span>{{ title }}</span>
  </h4>

  <div class="flex flex-column flex-grow-1 w-full gap-3">
    <h5 class="mb-0">Payment profile: {{ customerProfileName }}</h5>

    <div class="flex gap-2">
      <div class="w-full">
        <prox-field label="Select payment method" *ngIf="paymentProfiles">
          <p-listbox
            [options]="paymentProfiles"
            [(ngModel)]="selectedProfile"
            [listStyle]="{ 'max-height': '250px' }"
          >
            <ng-template let-pm pTemplate="item">
              <div
                class="flex w-full gap-2"
                *ngIf="pm.payment.Item.accountNumber"
              >
                <i class="fa fa-bank w-1"></i>
                <div class="w-6">{{ pm.payment.Item.bankName }}</div>
                <div class="w-5">{{ pm.payment.Item.accountNumber }}</div>
              </div>
              <div class="flex w-full gap-2" *ngIf="pm.payment.Item.cardNumber">
                <i class="fa fa-credit-card w-1"></i>
                <div class="w-6">{{ pm.payment.Item.cardType }}</div>
                <div class="w-5">{{ pm.payment.Item.cardNumber }}</div>
              </div>
            </ng-template>
          </p-listbox>
        </prox-field>
        <div class="card" *ngIf="!paymentProfiles">
          <a (click)="getProfilePage()"
            ><span class="font-italic"
              >No payment methods found. Click to add a payment method.</span
            ></a
          >
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-6">
        <button
          pButton
          (click)="getProfilePage()"
          label="Manage payment methods"
          class="p-button-raised"
        ></button>
      </div>
    </div>

    <div class="flex mt-3">
      <div class="w-full" *ngIf="!!selectedProfile">
        <p class="text-lg">
          Pay {{ amount | currency }} using {{ paymentName }}
        </p>
        <p class="text-sm">
          Payment method will be charged when Order is approved.
        </p>
      </div>
    </div>

    <div class="flex flex-grow-1">
      <div class="w-9">
        <span class="font-italic">{{ message }}</span>
      </div>
    </div>

    <div class="flex gap-2">
      <button
        [disabled]="!selectedProfile || !(amount && amount > 0)"
        (click)="ok()"
        pButton
        type="button"
        label="Ok"
        icon="pi pi-check"
        class="p-button-raised"
      ></button>
      <button
        (click)="cancel()"
        pButton
        type="button"
        icon="pi pi-times"
        label="Cancel"
        class="p-button-raised"
      ></button>
    </div>
  </div>
</div>
