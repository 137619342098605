import { ApplicationRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, SaveAndQueryComponent } from '@core';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { Program, ActiveStatus, ActiveStatusEnum } from '@models';
import { UtilFns } from '@utils';
import { ProgramStatusService } from '../services/program-status.service';

@Component({
  selector: 'prox-program-change-status-frm',
  templateUrl: './program-change-status-frm.component.html',
})
export class ProgramChangeStatusFrmComponent extends SaveAndQueryComponent {
  programId!: string;
  program!: Program;
  currentStatus?: ActiveStatusEnum;
  allStatuses: ActiveStatus[] = [];
  nextStatusOptions: ActiveStatus[] = [];
  selectedNextStatus?: ActiveStatus;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private statusService: ProgramStatusService,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    private appRef: ApplicationRef
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }
  
  override async updateFromParams(params: object): Promise<void> {
    this.programId = params['programId'];
    UtilFns.assertNonEmptyString(this.programId, 'programId');
    this.program = await this.dbQueryService.getProgramChangeState(this.programId);
    this.allStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.currentStatus = this.program.activeStatusId;
    this.nextStatusOptions = this.statusService.nextActiveStatusOptions(this.program.activeStatusId, this.allStatuses);
    console.log("start: " + this.nextStatusOptions[0]);
    this.isPageReady = true;
  }

  onChangeNextStatus(event) {
    console.log("onChange: " + event.value);
  }
}
