// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { Account } from './account';
import { AccountProcurementCardLog } from './account-procurement-card-log';
import { ActiveStatus } from './active-status';
import { Program } from './program';
import { ProgramUserGroup } from './program-user-group';
import { ShippingUserGroup } from './shipping-user-group';
import { ActiveStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class AccountProcurementCard extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get namedesc() { return this.name + ' ' + this.description; }
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK account */
  accountId!: string;
  /** Enum FK activeStatus */
  activeStatusId!: ActiveStatusEnum;
  /** Decimal */
  amt!: number;
  /** String(100) */
  description!: string;
  /** DateTime */
  endDate!: Date;
  /** String(100) */
  name!: string;
  /** String(50) */
  paymentProfileId!: string;
  /** Binary */
  rowVersion!: any;
  /** DateTime */
  startDate!: Date;
  /** FK accountId */
  account!: Account;
  /** Inv FK accountProcurementCardId */
  accountProcurementCardLogs!: AccountProcurementCardLog[];
  /** FK activeStatusId */
  activeStatus!: ActiveStatus;
  /** Inv FK accountProcurementCardId */
  programUserGroups!: ProgramUserGroup[];
  /** Inv FK accountProcurementCardId */
  programs!: Program[];
  /** Inv FK accountProcurementCardId */
  shippingUserGroups!: ShippingUserGroup[];
}

