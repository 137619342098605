/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ApplicationRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UtilDialogService } from '@core';
import {
  // AccountAdminGroup,
  AccountBlanketPurchaseOrder,
  ApprovalTree,
  OrderPaidByType,
  Program,
  ProgramApplicabilityType,
  ProgramStartDateType,
  ProgramStartDateTypeEnum,
  ActiveStatus,
  ShipFrequency,
  ProgramPurchaseOrderType,
  ProgramPcardType,
  AccountProcurementCard
} from '@models';
import { DateFns, UtilFns } from '@utils';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import * as _ from 'lodash';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { Entity } from 'breeze-client';

import { AccountDbSaveService } from '../services/account-db-save.service';

@Component({
  selector: 'prox-program-info-sub',
  templateUrl: './program-info.sub.component.html',
})
export class ProgramInfoSubComponent implements OnInit, OnChanges {
  ProgramStartDateTypeEnum = ProgramStartDateTypeEnum;

  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;

  // lookup types
  activeStatuses!: ActiveStatus[];
  orderPaidByTypes!: OrderPaidByType[];
  programStartDateTypes!: ProgramStartDateType[];
  shipFrequencies!: ShipFrequency[];
  programApplicabilityTypes!: ProgramApplicabilityType[];
  // accountAdminGroups: AccountAdminGroup[] = [];
  approvalTrees!: ApprovalTree[];
  //currentApprovalTreeId = UtilFns.EmptyGuid;
  currentApprovalTreeId = null as any;
  programs!: Program[];
  availableNextPrograms!: Program[];
  isExpired: boolean = false;
  programPurchaseOrderTypes: ProgramPurchaseOrderType[] = [];
  programPcardTypes: ProgramPcardType[] = [];
  pcards!: AccountProcurementCard[];
  blanketPos!: AccountBlanketPurchaseOrder[];
  

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    private appRef: ApplicationRef
  ) {}

  get program() {
    return this.parent.program;
  }

  get manifests() {
    return this.program?.manifests;
  }

  async ngOnInit() {

    const [r0, r1, r2, r3, r4] = await Promise.all([
      this.dbQueryService.getAll(ActiveStatus),
      this.dbQueryService.getAll(OrderPaidByType),
      this.dbQueryService.getAll(ProgramApplicabilityType),
      this.dbQueryService.getAll(ProgramStartDateType),
      this.dbQueryService.getAll(ShipFrequency),
    ]);

    this.activeStatuses = r0;
    this.orderPaidByTypes = r1;
    this.programApplicabilityTypes = r2;
    this.programStartDateTypes = r3;
    this.shipFrequencies = r4;

    const [p0, p1, p2, p3, p4] = await Promise.all([
      this.dbQueryService.getAccountAdminGroups( this.program.accountId ),
      this.dbQueryService.getApprovalTrees( this.program.accountId ),
      this.dbQueryService.getPrograms( this.program.accountId ),
      this.dbQueryService.getAccountBlanketPurchaseOrders( this.program.accountId ),
      this.dbQueryService.getAccountProcurementCards( this.program.accountId ),
    ]);

    // this.accountAdminGroups = p0;
    this.approvalTrees = p1;
    this.programs = p2;
    this.blanketPos = p3;
    this.pcards = p4;

    /*     if (this.program.accountAdminGroupId == UtilFns.EmptyGuid) {
      this.accountAdminGroups.unshift({
        id: UtilFns.EmptyGuid,
        name: '- None -',
      } as AccountAdminGroup);
    } */

    // this.accountAdminGroups.unshift({
    //   id: null as any,
    //   name: '- None -',
    // } as AccountAdminGroup);

    /*     if (this.program.approvalTreeId == UtilFns.EmptyGuid) {
      this.approvalTrees.unshift({
        id: UtilFns.EmptyGuid,
        name: '- None -',
      } as ApprovalTree);
    } */

    this.approvalTrees.unshift({
      id: null as any,
      name: '- None -',
    } as ApprovalTree);

    
/*     this.blanketPos.unshift({
      id: null as any,
      purchaseOrder: '- None -',
    } as AccountBlanketPurchaseOrder); */

    this.isExpired = this.program.endDate! < (new Date());
    this.currentApprovalTreeId = this.program.approvalTreeId!;

    this.programPurchaseOrderTypes = this.dbQueryService.getAllCached(ProgramPurchaseOrderType);
    this.programPcardTypes = this.dbQueryService.getAllCached(ProgramPcardType);

/*     this.poTypes.push(
      { name: "None", value: "None", description: "" },
      { name: "Per Order", value: "ByAdminPerOrder", description: "" },
      { name: "By Program", value: "ByProgram", description: "" },
      { name: "By Shipping User Group", value: "ByShippingUserGroup", description: "" },
      { name: "By Program User Group", value: "ByProgramUserGroup", description: "" }
    ) */
  }

  

  ngOnChanges(changes: SimpleChanges): void {
    this.parent.updateComponentNameMap(this);
    this.refresh();
  }

  async refresh() {
    //
  }

  async onProgramApplicabilityTypeChanged(programApplicabilityTypeId: string) {
    // do nothing yet
  }

  async onProgramStartDateTypeChanged(programStartDateTypeId: string) {
    // do nothing yet
  }
  

  async onApprovalTreeChanged(approvalTreeId: string) {
    const newAt = this.approvalTrees.find((at) => at.id == approvalTreeId);

    UtilFns.assertNonNull(
      newAt,
      'Proximity Account Organization Tree should have been found - bug'
    );
    if (!newAt.id) return;
    this.approvalTrees = this.approvalTrees.filter(
      (x) => x.id != UtilFns.EmptyGuid
    );
    const treeUserGroups = _.flatten(
      newAt.approvalTreeAdminGroups.map((x) =>
        x.approvalTreeUserGroups.map((y) => y.programUserGroup)
      )
    );
    const treeUgIdSet = new Set(treeUserGroups.map((ug) => ug.id));

    const paugs = _.flatten(
      this.program.programAllowances.map((pa) =>
        pa.programAllowanceUserGroupMaps.filter(
          (x) => !treeUgIdSet.has(x.programUserGroupId)
        )
      )
    );
    if (paugs.length > 0) {
      const errs = paugs
        .map(
          (x) =>
            `Program Allowance: '${x.programAllowance.name}' - User Group: '${x.programUserGroup.name}'`
        )
        .join('<br/>');
      await this.dialogService.okDialog(
        'Cannot change Proximity Account Organization Tree',
        `This Account Organization Tree does not contain the following user groups that are referenced within this program </br></br> ${errs} `
      );
      // Needed if we use a toast instead
      // this.appRef.tick();
      this.currentApprovalTreeId = this.program.approvalTreeId!;
      return;
    }
    const piugs = _.flatten(
      this.program.programIssuances.map((pa) =>
        pa.programIssuanceUserGroupMaps.filter(
          (x) => !treeUgIdSet.has(x.programUserGroupId)
        )
      )
    );
    if (piugs.length > 0) {
      const errs = piugs
        .map(
          (x) =>
            `Program Issuance: '${x.programIssuance.name}' - User Group: '${x.programUserGroup.name}'`
        )
        .join('<br/>');
      await this.dialogService.okDialog(
        'Cannot change Proximity Account Organization Tree',
        `This Account Organization Tree does not contain the following user groups that are referenced within this program </br></br> ${errs} `
      );
      // Needed if we use a toast instead
      this.appRef.tick();
      this.currentApprovalTreeId = <string>this.program.approvalTreeId;
      return;
    }

    this.program.approvalTree = newAt;
    // TODO: review if needed.
    await this.dbQueryService.getProgramUserGroupsForApprovalTree(
      <string>this.program.approvalTreeId
    );
  }

  async markError(errEnt: Entity, propName?: string) {
    UtilFns.focusInputByEntity('#topLevel', errEnt, propName || '');
  }

  /* createManifest() {
    const manifest = this.dbSaveService.createEntity(Manifest, {
      name: 'Manifest for: ' + this.program.name
    });
    this.program.manifest = manifest;
  }

  deleteManifest() {
    if (this.manifest == null) return;
    EntityFns.deleteOrDetach(this.manifest.entityAspect);
    this.program.manifest == null;
  }

  async editManifestSchedule() {
    const r = await EventScheduleDialog.openDialog(this.pngDialogService, {
      rruleString: this.manifest.rRule
    });
    if (r != null) {
      this.manifest.rRule = r;
    }
    
  }

  getManifestScheduleText() {
    if (this.manifest?.rRule == null) {
      return ''
    }
    const rrule = RRuleFns.fromString(this.manifest.rRule);
    return RRuleFns.toText(rrule);
  } */
}
