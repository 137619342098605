import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

/** Action for the dialog */
type actionType = 'manage'|'addPayment'|'editPayment';
/** Messages from the dialog */
type messageType = 'resizeWindow'|'successfulSave'|'cancel'|'transactResponse';

export interface PaymentProfileDialogData {
  title: string;
  /** Whether to load account pcard data (not user data) */
  isAccount?: boolean;
  /** Action for the dialog */
  action?: actionType;
  /** When editing a payment, id of payment to be edited */
  paymentProfileId?: string;
}

@Component({
  selector: 'prox-payment-profile-dialog',
  template: `
    <div class="h-full flex flex-column" style="max-height: 90vh">
      <form
        id="tokenform"
        #tokenform
        action="{{ formAction }}"
        method="post"
        target="load_profile"
      >
        <input type="hidden" name="token" [ngModel]="profilePageToken" />
        <input type="hidden" name="paymentProfileId" [ngModel]="data.paymentProfileId" />        
      </form>

      <iframe
        id="load_profile"
        name="load_profile"
        width="800px"
        frameborder="0"
        style="height: 850px"
      >
      </iframe>
      <div *ngIf="message">
        <span class="font-italic">{{ message }}</span>
      </div>
      <div class="flex justify-content-end mt-2">
        <button
          (click)="close(false)"
          pButton
          type="button"
          icon="pi pi-check"
          label="Ok"
          class="p-button-raised"
        ></button>
      </div>
    </div>
  `,
})
export class PaymentProfileDialogComponent implements OnInit {
  static config: DynamicDialogConfig = {
    header: 'Manage Payment Profiles',
    width: '840px',
    contentStyle: { height: '850px', 'padding-bottom': '1rem' },
  };
  @ViewChild('tokenform') tokenform!: ElementRef;
  data: PaymentProfileDialogData;
  message?: string;
  profilePageToken = '';
  formAction = '';

  constructor(
    dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private http: HttpClient
  ) {
    this.data = dialogConfig.data;
  }

  ngOnInit(): void {
    this.getProfilePage(this.data.isAccount || false);
  }

  private async getProfilePage(isAccount: boolean) {
    const params = { returnUrl: window.location.origin + '/assets/proximity/IFrameCommunicator.html', isAccount };
    try {
      const result = await firstValueFrom(
        this.http.get<any>(
          environment.transactionUrl + '/GetPaymentsProfilePage',
          { params }
        )
      );
      this.profilePageToken = result.token;
      this.formAction = this.data.action ? result.sessionToken.replace('/manage', '/' + this.data.action) : result.sessionToken;
      this.setupCommunicator();
      setTimeout(() => this.tokenform.nativeElement.submit(), 1);
    } catch (e: any) {
      this.setMessage(e.error);
    }
  }

  private setupCommunicator() {
    (window as any).CommunicationHandler = {
      onReceiveCommunication: (argument) => {
        const searchParams = new URL('http://x.y?' + argument.qstr).searchParams;
        const action = searchParams.get('action');
        if (action == 'cancel') {
          this.close(false);
        } else if (action == 'successfulSave') {
          this.close(true);
        }
      }
    };
  }

  private setMessage(result: any) {
    console.log(result);
    const msg = result.messages ? result.messages.message.map((x) => x.text).join(';') : result.message || result.Message;
    this.message = msg;
  }

  close(saved: boolean) {
    this.ref.close(saved);
  }
}
