import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransactionDetails } from './payment-interfaces';
import { PaymentService } from './payment-service';

export interface PaymentViewDialogData {
  transId: string;
  poNumber: string;
}

@Component({
  selector: 'prox-payment-view-dialog',
  templateUrl: './payment-view-dialog.component.html',
})
export class PaymentViewDialogComponent implements OnInit {
  static config = {
    header: 'View Transaction',
    width: '740px',
    contentStyle: { height: '400px' },
  };
  transaction?: TransactionDetails;
  transId?: string;
  message?: string;
  poNumber?: string;

  constructor(
    dialogConfig: DynamicDialogConfig<PaymentViewDialogData>,
    private ref: DynamicDialogRef,
    private paymentService: PaymentService
  ) {
    this.transId = dialogConfig.data?.transId;
    this.poNumber = dialogConfig.data?.poNumber;
  }

  async ngOnInit(): Promise<void> {
    // TODO user can see own payments

    await this.getPaymentDetails();
  }

  get transactionDisplay() {
    if (!this.transaction) {
      return '';
    }
    return JSON.stringify(this.transaction, undefined, '    ');
  }

  /** Load the user's payment profiles from Authorize.net */
  private async getPaymentDetails() {
    if (!this.transId) {
      return;
    }
    try {
      const result = await this.paymentService.getTransactionDetails(
        this.transId
      );
      this.transaction = result.transaction;
    } catch (e: any) {
      this.setMessage(e);
    }
  }

  /** Set the message content using error messages from the result */
  private setMessage(result: any) {
    const msg = this.paymentService.getErrorMessage(result);
    this.message = msg;
  }

  close() {
    this.ref.close();
  }
}
