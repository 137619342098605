<div class="h-full flex flex-column">
  <div class="flex flex-column flex-grow-1 w-full gap-3">
    <div>
      <pre>{{ transactionDisplay }}</pre>
    </div>

    <div class="flex flex-grow-1">
      <div class="w-9">
        <span class="font-italic">{{ message }}</span>
      </div>
    </div>

    <div class="flex mb-4">
      <button
        (click)="close()"
        pButton
        type="button"
        icon="pi pi-times"
        label="Close"
        class="p-button-raised"
      ></button>
    </div>
  </div>
</div>
