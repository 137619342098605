import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { firstValueFrom } from 'rxjs';
import {
  BankAccount,
  CreateTransactionResponse,
  CreditCard,
  CustomerProfileResponse,
  PaymentPayload,
  PaymentProfile,
  RefundPayload,
  TransactionDetailsResponse,
} from './payment-interfaces';

/** For connecting to Payments API via TransactionController */
@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  /** Return the CustomerProfile for the current AccountUser or Account */
  getCustomerProfile(isAccount?: boolean) {
    const params = { isAccount: isAccount || false };
    return firstValueFrom(
      this.http.get<CustomerProfileResponse>(
        environment.transactionUrl + '/GetPaymentsCustomerProfile',
        { params }
      )
    );
  }

  /** Submit a payment for the given customer profile */
  submitProfilePayment(payload: PaymentPayload) {
    return firstValueFrom(
      this.http.post<CreateTransactionResponse>(
        environment.transactionUrl + '/SubmitPayment',
        payload
      )
    );
  }

  /** Submit a payment for the given customer profile */
  submitPayment(
    customerProfileId: string,
    customerPaymentProfileId: string,
    amount: number,
    poNumber?: string
  ) {
    const payload = {
      customerProfileId,
      customerPaymentProfileId,
      amount,
      poNumber,
    } as PaymentPayload;
    return this.submitProfilePayment(payload);
  }

  // voidTransaction(transId: string, poNumber: string) {
  //   const payload: RefundPayload = { refTransId: transId, poNumber };
  //   return firstValueFrom(this.http.post<CreateTransactionResponse>(environment.transactionUrl + '/VoidTransaction', payload));
  // }

  // refundTransaction(transId: string, poNumber: string, amount?: number) {
  //   const payload: RefundPayload = { refTransId: transId, poNumber, amount };
  //   return firstValueFrom(this.http.post<CreateTransactionResponse>(environment.transactionUrl + '/RefundTransaction', payload));
  // }

  voidOrRefundTransaction(transId: string, poNumber: string) {
    const payload: RefundPayload = { refTransId: transId, poNumber };
    return firstValueFrom(
      this.http.post<CreateTransactionResponse>(
        environment.transactionUrl + '/VoidOrRefundTransaction',
        payload
      )
    );
  }

  /** Get the details of a prior transaction */
  getTransactionDetails(transId: string) {
    const url =
      environment.transactionUrl + '/GetTransactionDetails/' + transId;
    return firstValueFrom(this.http.get<TransactionDetailsResponse>(url));
  }

  /** Set the message content using error messages from the result */
  getErrorMessage(result: any) {
    result = result.error || result;
    console.log(result);
    let msg;
    if (result.transactionResponse?.errors) {
      msg = result.transactionResponse.errors
        .map((x) => `${x.errorText} Code: ${x.errorCode}`)
        .join('; ');
    } else if (result.messages) {
      msg = result.messages.message.map((x) => x.text).join('; ');
    } else {
      msg = result.message || result;
    }
    return msg;
  }

  /** Get display name for a payment profile */
  getProfileDisplayName(paymentProfile?: PaymentProfile) {
    const item = paymentProfile?.payment.Item as any;
    if (!item) {
      return '';
    }
    if ((item as CreditCard).cardType) {
      return item.cardType + ' ending in ' + item.cardNumber;
    }
    if ((item as BankAccount).accountNumber) {
      return (
        (item.bankName || 'Bank') + ' account ending in ' + item.accountNumber
      );
    }
    return '';
  }
}
