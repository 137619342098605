
import { GetRowIdParams, GridOptions } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProxAgFns, UtilDialogService } from '@core';
import { Program, ProgramRapidTemplate, ProgramRapidTemplateProductTypeConfig } from '@models';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ProductTypeConfigFinderDialog } from './product-type-config.finder.dialog';
import { Entity } from 'breeze-client';


@Component({
  selector: 'prox-program-rapid-templates-sub',
  templateUrl: './program-rapid-templates.sub.component.html',
})
export class ProgramRapidTemplatesSubComponent implements OnInit, OnChanges {
  @Input() parent!: ProgramFrmComponent;
  @Input() visible!: boolean;
    
  rtGridOptions: GridOptions = {};
  rapidTemplates?: ProgramRapidTemplate[];
  
  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService,
    public toastr: ToastrService
  ) {
    //
  }

  get program() {
    return this.parent.program;
  }

  async ngOnInit() {
    this.rtGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onRtGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
      },
      { detailProperty: 'programRapidTemplateProductTypeConfigs' }
    );
    
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    this.program.programRapidTemplates
    const isLoaded = this.program.entityAspect.isNavigationPropertyLoaded("programRapidTemplates");
    if (isLoaded && !forceRefresh) {
      this.rapidTemplates = this.program.programRapidTemplates;
    } else {
      this.rapidTemplates = await this.dbQueryService.getRapidOrders(this.program.id);
      
      this.program.entityAspect.markNavigationPropertyAsLoaded('programRapidTemplates');
      AgFns.refreshGrid(this.rtGridOptions, this.rapidTemplates);
    }
        
    // this.parent.calcPaFeatures();
    

    AgFns.sizeColumnsToFit(this.rtGridOptions);

    AgFns.selectDefaultRow(this.rtGridOptions);
    
  }

  onRtGridReady() {
    const colDefs = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        editable: true,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Description',
        field: 'description',
        editable: true,
        filter: 'agTextColumnFilter',
      },
      {
        ...AgFns.createButtonProps('', this.onRapidTemplateAttach.bind(this), {
          label: 'Attach Products',
        }),
        minWidth: 150,
        maxWidth: 150,
      },
      ProxAgFns.getEntityDeleteColDef(this.onRoDelete.bind(this)),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    this.updateRoMasterDetail(this.rtGridOptions);
    AgFns.initGrid(this.rtGridOptions, colDefs, sortModel);
  }

  updateRoMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    const prodTypePath = 'productTypeConfig.pricedProductType.productType';
    detailGridOptions.columnDefs = [
      {
        headerName: 'Supplier',
        field: prodTypePath + '.supplier.name',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Manufacturer',
        field: prodTypePath + '.manufacturer.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SKU',
        field: prodTypePath + '.productKey',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Name',
        field: prodTypePath + '.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Description',
        field: prodTypePath + '.description',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Product Group',
        field: 'productTypeConfig.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Qty',
        editable: true,
        field: 'qty'
      },
      {
        ...AgFns.createButtonProps('', this.onRapidTemplateDetach.bind(this), {
          label: 'Detach Product',
        }),
        minWidth: 150,
        maxWidth: 150,
      },
    ];
    detailGridOptions.getRowId = (rowIdParams: GetRowIdParams) => {
      const x = rowIdParams.data as ProgramRapidTemplateProductTypeConfig;
      return x.productTypeConfigId;
    };
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',

      getDetailRowData: (params) => {
        const rt = params.data as ProgramRapidTemplate;
        params.successCallback(rt.programRapidTemplateProductTypeConfigs);
      },
    };
  }

  // async handleRapidOrders() {
  //   if (this.isBeingAdded) {
  //     this.rapidOrders = this.rapidOrders ?? [];
  //   } else if (this.rapidOrders == null) {
  //     this.rapidOrders = await this.dbQueryService.getRapidOrders(
  //       this.program.id
  //     );
  //     // rapid orders reference productTypeConfigs.
  //     await this.ensureRapidOrderDetails();
  //   }
  //   AgFns.refreshGrid(this.roGridOptions, this.rapidOrders, true);
  //   AgFns.selectFirstRow(this.roGridOptions);
  // }

  // async ensureRapidOrderDetails() {
  //   if (this.programProductTypeConfigs == null) {
  //     this.programProductTypeConfigs =
  //       await this.dbQueryService.getProgramProductTypeConfigs(this.program.id);
  //     this.calcPaFeatures();
  //   }
  // }

  async onRtAdd() {
    if (this.rapidTemplates == null) return;

    const newView = this.dbSaveService.createEntity(ProgramRapidTemplate, {
      programId: this.program.id,
    });
    const x = newView.programRapidTemplateProductTypeConfigs;
    this.rapidTemplates.push(newView);
    AgFns.refreshGrid(this.rtGridOptions, this.rapidTemplates);
  }

  async onRoDelete(ro: ProgramRapidTemplate) {
    if (!this.rapidTemplates) return;
    ro.programRapidTemplateProductTypeConfigs
      .slice()
      .forEach((x) => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(ro.entityAspect);
    _.remove(this.rapidTemplates, ro);
    AgFns.refreshGrid(this.rtGridOptions, this.rapidTemplates);
  }

  async onRapidTemplateAttach(row: ProgramRapidTemplate) {
    const existingPtcIds = new Set(
      row.programRapidTemplateProductTypeConfigs.map((x) => x.productTypeConfigId)
    );
    const finderPtcs = this.program.programProductTypeConfigs
      ?.map((x) => x.productTypeConfig)
      .filter((x) => !existingPtcIds.has(x.id));
    const newPtcs = await this.dialogService.createFinder(
      ProductTypeConfigFinderDialog,
      {
        productTypeConfigs: finderPtcs,
        rowSelection: 'multiple',
      }
    );
    newPtcs.forEach((ptc) => {
      if (
        !this.dbSaveService.uow.undoIfDeleted(ProgramRapidTemplateProductTypeConfig, [
          row.id,
          ptc.id,
        ])
      ) {
        this.dbSaveService.createEntity(ProgramRapidTemplateProductTypeConfig, {
          programRapidTemplateId: row.id,
          productTypeConfigId: ptc.id,
        });
      }
    });
    // HACK - AgGrid issue - complete hack - needed to show expando on grid  if row doesn't already have it.
    AgFns.refreshGrid(this.rtGridOptions, []);
    AgFns.refreshGrid(this.rtGridOptions, this.rapidTemplates);

    const rowNode = this.rtGridOptions.api?.getRowNode(row.id);
    if (rowNode) {
      rowNode?.setExpanded(true);
      this.rtGridOptions.api?.redrawRows({ rowNodes: [rowNode] });
    }
    AgFns.refreshDetailRowHeights(this.rtGridOptions);
  }

  async onRapidTemplateDetach(row: ProgramRapidTemplateProductTypeConfig) {
    if (this.rapidTemplates == null) return;
    const parentRowNode = this.rtGridOptions.api?.getRowNode(row.programRapidTemplateId);
    EntityFns.deleteOrDetach(row.entityAspect);

    AgFns.refreshGrid(this.rtGridOptions, this.rapidTemplates);
    parentRowNode?.setExpanded(true);
    AgFns.refreshDetailRowHeights(this.rtGridOptions);
  }

  async markError(errEnt: Entity, propName?: string) {
    const ent = errEnt as ProgramRapidTemplate;
    AgFns.selectGridRowByKey(
      this.rtGridOptions,
      (e: ProgramRapidTemplate) => e.id,
      ent.id,
      propName
    );
  }
}
