/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';

// import { DividerFormlyComponent } from './renderers/formly/divider.type';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
// import { FormlyModule } from '@ngx-formly/core';
// import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import {FullCalendarModule} from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
// import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { TemplateRendererComponent } from './components/ag-grid/template-renderer.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ImageCropperModule } from 'ngx-image-cropper';

import {
  AddressFrmComponent,
  AgGridWrapperComponent,
  BaseListEditFrmComponent,
  ButtonRendererComponent,
  BadgeRendererComponent,
  ChangePasswordComponent,
  DateCellEditorPngComponent,
  EntityFinderDialog,
  ErrorDialogComponent,
  TextboxDialogComponent,
  TextPromptDialogComponent,
  FileGalleryDialogComponent,
  FileSelectDialogComponent,
  FileViewDialogComponent,
  GridToolTipValueDialog,
  IconRendererComponent,
  LoginComponent,
  UserFrmComponent,
  UserRightsFrmComponent,
  ValidationDialogComponent,
  ChangeLogGridComponent,
  ChangeLogDialogComponent,
  CoreUserFinderDialog,
  CoreAccountFinderDialog,
  CoreJobOrderFinderDialog,
  CoreSupplierFinderDialog,
} from './components';
// import { PanelWrapperComponent } from './renderers/old-formly/panel-wrapper.component';
import { ProxSaveCancelComponent, QuickSearchComponent } from './components';
// import { USDInputComponent } from './renderers/old-formly/usd-input.type';

import { AngularSplitModule } from 'angular-split';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
// import { ProxFieldComponent } from './components/prox-field.component';
import { BreezeValidatorDirective } from './components/breeze-validator.directive';
import { UtilDialogService } from './services/dialog.service';
import { BaseService } from './services/base.service';
import { FileService } from './services/file.service';
// import { FocusedInputComponent } from './renderers';
import { EntityStateRendererComponent } from './components/ag-grid/entity-state-renderer.component';
import { NuvoImporterModule } from 'nuvo-angular';
import { PricedProductTypeFinderDialog } from './shared/priced-product-type-finder.dialog';
import { ProxFieldComponent } from './components/prox-field.component';
import { ProxMainComponent } from './components/prox-main.component';
import { EditorComponent } from './components/editor/editor.component';
import { ImageEditComponent } from './components/file/image-edit.component';
import { BlobRendererComponent } from './components/ag-grid/blob-renderer.component';
import { NgxTiptapModule } from 'ngx-tiptap';
import { HtmlEditorComponent } from './components/editor/html-editor.component';
import { KeyMenuDirective } from './components/editor/key-menu.directive';
import { HelpDialogComponent } from './components/help/help-dialog.component';
import { ImpersonateDialogComponent } from './components/impersonation/impersonate-dialog.component';
import { SupplierEntityManagerProvider } from '@data';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { GlobalErrorHandler } from './services/global-error-handler';
import { StatusChangeDialog } from './components/base/status-change.dialog';
import { ProxSaveCancelDeleteComponent } from './components/prox-save-cancel-delete.component';
import { StatusService } from './services/status.service';
import { StatusExpFormComponent } from './services/status-exp-form.component';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { SubMenuitemComponent } from './components/sub-menu/sub-menuitem.component';
import { EventScheduleDialog  } from './shared/event-schedule-dialog';

import { BusyService } from './services/busy.service';
import { ChoiceDialogComponent } from './components/choice-dialog.component';

const CUSTOM_COMPONENTS = [QuickSearchComponent];
const ANGULAR_MODULES = [FormsModule, ReactiveFormsModule];
const THIRD_PARTY_MODULES: any[] = [
  AngularSplitModule,
  AgGridModule,
  NuvoImporterModule,
  EditorModule,
  ImageCropperModule,
  NgxTiptapModule
];
const PROX_COMPONENTS = [
  TemplateRendererComponent,
  AgGridWrapperComponent,
  BaseListEditFrmComponent,
  EntityFinderDialog,
  EventScheduleDialog,

  ButtonRendererComponent,
  BadgeRendererComponent,
  EntityStateRendererComponent,
  GridToolTipValueDialog,
  StatusChangeDialog,
  PricedProductTypeFinderDialog,
  DateCellEditorPngComponent,
  IconRendererComponent,
  BlobRendererComponent,
  ProxMainComponent,
  ChangeLogDialogComponent,
  ChangeLogGridComponent,
  CoreUserFinderDialog,
  CoreAccountFinderDialog,
  CoreJobOrderFinderDialog,
  CoreSupplierFinderDialog,
  HtmlEditorComponent,
  ImpersonateDialogComponent,
  KeyMenuDirective,
];

const PRIMENG_MODULES: unknown[] = [
  AccordionModule,
  AutoCompleteModule,
  AvatarModule,
  AvatarGroupModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CarouselModule,
  CascadeSelectModule,
  CheckboxModule,
  ChipModule,
  ChipsModule,
  ConfirmDialogModule,
  ConfirmPopupModule,
  ColorPickerModule,
  ContextMenuModule,
  DataViewModule,
  DialogModule,
  DividerModule,
  DropdownModule,
  FieldsetModule,
  FileUploadModule,
  // FullCalendarModule, <- @fullcalendar not Angular 13 ready
  GalleriaModule,
  ImageModule,
  InplaceModule,
  InputNumberModule,
  InputMaskModule,
  InputSwitchModule,
  InputTextModule,
  InputTextareaModule,
  KnobModule,
  // LightboxModule,
  ListboxModule,
  MegaMenuModule,
  MenuModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  OrderListModule,
  OrganizationChartModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  PanelMenuModule,
  PasswordModule,
  PickListModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  RatingModule,
  RippleModule,
  ScrollPanelModule,
  ScrollTopModule,
  SelectButtonModule,
  SidebarModule,
  SkeletonModule,
  SlideMenuModule,
  SliderModule,
  SplitButtonModule,
  SplitterModule,
  StepsModule,
  TableModule,
  TabMenuModule,
  TabViewModule,
  TagModule,
  TerminalModule,
  TimelineModule,
  TieredMenuModule,
  ToastModule,
  ToggleButtonModule,
  ToolbarModule,
  TooltipModule,
  //TreeModule, <= development time error re: @ciclon/angular-tree-component
  TreeTableModule,
  VirtualScrollerModule,
];

export function minlengthValidationMessage(err: any, field: any) {
  return `Must be at least ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err: any, field: any) {
  return `Must be be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err: any, field: any) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.max}`;
}

export function requiredValidationMessage(err: any, field: any) {
  return `${field.templateOptions.label} is required`;
}

export function emailValidator(control: AbstractControl): ValidationErrors {
  if (control.value?.trim().length <= 0) return null as any;
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(control.value)
    ? (null as any)
    : { email: true };
}

export function emailValidationMessage() {
  return `Not a valid email address`;
}

@NgModule({
  imports: [
    CommonModule,
    ANGULAR_MODULES,
    PRIMENG_MODULES,
    THIRD_PARTY_MODULES,
  ],
  declarations: [
    PROX_COMPONENTS,
    CUSTOM_COMPONENTS,
    // USDInputComponent,
    // FocusedInputComponent,
    QuickSearchComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    ErrorDialogComponent,
    ValidationDialogComponent,
    TextboxDialogComponent,
    TextPromptDialogComponent,
    ChoiceDialogComponent,
    HelpDialogComponent,
    LoginComponent,
    UserFrmComponent,
    UserRightsFrmComponent,
    ProxFieldComponent,
    BreezeValidatorDirective,
    ProxSaveCancelComponent,
    ProxSaveCancelDeleteComponent,
    AddressFrmComponent,
    FileGalleryDialogComponent,
    FileSelectDialogComponent,
    FileViewDialogComponent,
    ImageEditComponent,
    StatusExpFormComponent,
    EditorComponent,
    
    SubMenuComponent,
    SubMenuitemComponent
  ],
  exports: [
    ANGULAR_MODULES,
    PRIMENG_MODULES,
    THIRD_PARTY_MODULES,
    PROX_COMPONENTS,
    CUSTOM_COMPONENTS,
    // FocusedInputComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    ErrorDialogComponent,
    ValidationDialogComponent,
    TextboxDialogComponent,
    TextPromptDialogComponent,
    HelpDialogComponent,
    LoginComponent,
    UserFrmComponent,
    UserRightsFrmComponent,
    ProxFieldComponent,
    BreezeValidatorDirective,
    ProxSaveCancelComponent,
    ProxSaveCancelDeleteComponent,
    AddressFrmComponent,
    FileGalleryDialogComponent,
    FileSelectDialogComponent,
    FileViewDialogComponent,
    ImageEditComponent,
    
    StatusExpFormComponent,
    SubMenuComponent,
    SubMenuitemComponent
  ],
  providers: [
    ConfirmationService,
    DialogService,
    BreadcrumbService,
    UtilDialogService,
    BaseService,
    FileService,
    StatusService,
    BusyService,
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {
  constructor(http: HttpClient) {
    ModelLibraryBackingStoreAdapter.register();
    UriBuilderJsonAdapter.register();
    AjaxHttpClientAdapter.register(http);
    DataServiceWebApiAdapter.register();

    const emProvider = inject(SupplierEntityManagerProvider);
    emProvider.prepare();
  }
}
