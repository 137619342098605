
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, createUrlTreeFromSnapshot } from '@angular/router';
import { AuthService, AuthUser, Roles, RouteFns } from '@core';
import { AccountEntityManagerProvider } from '@data';
import { ToastrService } from 'ngx-toastr';
import { AccountDbQueryService } from './account-db-query.service';

/** path to user edit page, so user can edit themselves */
const userEditRegex = /\/accounts\/[a-z0-9-]+\/manage\/account-users\/[a-z0-9-]+$/;

export const accountCanActivateGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const emProvider = inject(AccountEntityManagerProvider);

  emProvider.prepare();
  const dbQueryService = inject(AccountDbQueryService);
  const toastr = inject(ToastrService);

  const isLoggedIn = await authService.redirectIfNotLoggedIn(state.url);
  if (!isLoggedIn) {
    return false;
  }

  const user = await authService.getLoggedInUser() as AuthUser;
  const roles = state.url.startsWith('/account-user/') || userEditRegex.test(state.url) ? 
    [Roles.AccountAdmin, Roles.AccountUser] : [Roles.AccountAdmin];
  const ok = RouteFns.checkRoutePermissions(route, roles, user, toastr);
  if (ok) {
    if (user.accountId && !route.paramMap.has('accountId')) {
      // add accountId to URL params; navigates to account-specific page instead of account chooser
      // e.g. /transaction/account -> /transaction/account/:accountId
      return createUrlTreeFromSnapshot(route, [user.accountId]);
    } else if (user.accountId && route.paramMap.get('accountId') != user.accountId) {
      // replace accountId in URL params; navigates to correct page for account
      // only really happens in impersonation scenarios
      return createUrlTreeFromSnapshot(route.parent || route.root, [user.accountId]);
    } else if (user.accountUserId &&  route.paramMap.has('accountUserId') && route.paramMap.get('accountUserId') != user.accountUserId) {
      // Trying to navigate to another user's page - go back to home
      return createUrlTreeFromSnapshot(route.root, ['/']);
    }
    // insure that all lookups are cached. 
    await dbQueryService.cacheAllLookups();
  } else {
    // navigate to a safe place
    return createUrlTreeFromSnapshot(route.root, ['/']);
  }
  return ok;
}
