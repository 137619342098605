import { Component, OnInit } from '@angular/core';
import { BaseService } from '@core';
import { AccountUser } from '@models';
import { UtilFns } from '@utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { PaymentPayload, PaymentProfile, TransactionPayment, } from './payment-interfaces';
import { PaymentProfileDialogComponent, PaymentProfileDialogData, } from './payment-profile-dialog.component';
import { PaymentService } from './payment-service';

export interface PaymentDialogData {
  title: string;
  amount: number;
  accountUserId: string;
  allowAmountEdit?: boolean;
  /** JO number or other Proximity reference number for transaction */
  poNumber: string;
}

@Component({
  selector: 'prox-payment-dialog',
  templateUrl: './payment-dialog.component.html',
})
export class PaymentDialogComponent implements OnInit {
  static config = {
    header: 'Make Payment',
    width: '740px',
    contentStyle: { height: '400px' },
  };
  amount?: number;
  accountUserId?: string;
  poNumber?: string;
  title?: string;
  allowAmountEdit: boolean;
  paymentProfiles?: PaymentProfile[];
  selectedProfile?: PaymentProfile;
  customerProfileId?: string;
  customerProfileName?: string;
  accountUser?: AccountUser;
  message?: string;
  paymentResponse?: TransactionPayment;

  constructor(
    dialogConfig: DynamicDialogConfig<PaymentDialogData>,
    private ref: DynamicDialogRef,
    private baseService: BaseService,
    private dbQueryService: AccountDbQueryService,
    private paymentService: PaymentService
  ) {
    // super(baseService, route, dbSaveService, dbQueryService);
    this.amount = dialogConfig.data?.amount;
    this.accountUserId = dialogConfig.data?.accountUserId;
    this.poNumber = dialogConfig.data?.poNumber;
    this.title = dialogConfig.data?.title;
    this.allowAmountEdit = !!dialogConfig.data?.allowAmountEdit;
  }

  async ngOnInit(): Promise<void> {
    // need to be an account user (or impersonating one) to use this page
    UtilFns.assertNonEmptyString(this.accountUserId, 'accountUserId');
    this.accountUser = await this.dbQueryService.getAccountUserById(
      this.accountUserId
    );

    await this.getCustomerProfile();
  }

  /** Load the user's payment profiles from Authorize.net */
  private async getCustomerProfile() {
    try {
      const result = await this.paymentService.getCustomerProfile();
      this.customerProfileId = result.profile.customerProfileId;
      this.customerProfileName = result.profile.description;
      this.paymentProfiles = result.profile.paymentProfiles as PaymentProfile[];
      if (this.paymentProfiles) {
        const def = this.paymentProfiles.find((x) => x.defaultPaymentProfile);
        this.selectedProfile = def || undefined;
      }
    } catch (e: any) {
      this.setMessage(e);
    }
  }

  /** Show a dialog for managing the user's payment profiles */
  async getProfilePage() {
    await this.baseService.dialogService.create<PaymentProfileDialogData>(
      PaymentProfileDialogComponent,
      { title: '' },
      PaymentProfileDialogComponent.config
    );
    // reload profile list after dialog closes
    this.getCustomerProfile();
  }

  /** Submit a payment using the currently selected profile */
  async submitPayment() {
    if (!this.selectedProfile) {
      return;
    }
    const payload = {
      customerProfileId: this.customerProfileId,
      customerPaymentProfileId: this.selectedProfile.customerPaymentProfileId,
      amount: this.amount || 0,
      poNumber: this.poNumber,
    } as PaymentPayload;
    this.amount = undefined;
    this.selectedProfile = undefined;
    try {
      // const tx2 = this.paymentService.submitProfilePayment(payload);
      // const result = await firstValueFrom(this.http.post<any>(environment.transactionUrl + '/SubmitPayment', payload));
      const result = await this.paymentService.submitProfilePayment(payload);
      const tx = result.transactionResponse as TransactionPayment;
      if (tx.errors) {
        this.setMessage(result);
      } else {
        tx.amount = payload.amount;
        this.paymentResponse = tx;
        this.message = `Transaction successful.  Amount: ${payload.amount},  Account Type: ${tx.accountType}  Account Number: ${tx.accountNumber}  Transaction ID: ${tx.transId}`;
      }
    } catch (e: any) {
      this.setMessage(e);
    }
  }

  /** Set the message content using error messages from the result */
  private setMessage(result: any) {
    const msg = this.paymentService.getErrorMessage(result);
    this.message = msg;
  }

  close() {
    this.ref.close(this.paymentResponse);
  }
}
