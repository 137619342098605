/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  BaseService,
  ISortModel,
  ProxAgFns,
  SaveAndQueryComponent,
  StatusChangeDialog,
  StatusService,
} from '@core';
import {
  Account,
  AccountAdmin,
  ActiveStatus,
  ActiveStatusEnum,
  Program,
  ProgramStartDateTypeEnum,
  ProximityRightEnum,
} from '@models';

import { UtilFns } from '@utils';

import { AccountDbQueryService } from '../services/account-db-query.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AccountDbSaveService } from '../services/account-db-save.service';

@Component({
  selector: 'prox-program-list',
  templateUrl: './program-list.component.html',
})
export class ProgramListComponent extends SaveAndQueryComponent {
  accountId!: string;
  account?: Account;
  accountAdmin?: AccountAdmin;
  programs: Program[] = [];
  selectedProgram?: Program;
  progGridOptions: GridOptions = {};
  activeStatuses: ActiveStatus[] = [];
  editLabel = 'Edit';
  editLabelWidth = 50;

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService,
    override dbSaveService: AccountDbSaveService,
    private statusService: StatusService,
    public pngDialogService: DialogService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');

    this.account = await this.dbQueryService.getAccountById(this.accountId);
    this.programs = await this.dbQueryService.getPrograms(this.accountId);
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    if (!this.hasRight(ProximityRightEnum.CanViewAnyProgram)) {
      UtilFns.assertNonNull(
        this.authUser?.accountAdminId,
        'accountAdminId cannot be null here'
      );
      this.accountAdmin = await this.dbQueryService.getAccountAdminById(
        this.authUser.accountAdminId
      );
      // filter out any programs where the current account admin is not in the approvalTree for the program
      const validApprovalTrees = await this.dbQueryService.getApprovalTreesByAccountAdmin(this.authUser.accountAdminId);
      const approvalTreeIdSet = new Set(validApprovalTrees.map(x => x.id));
      this.programs = this.programs.filter(p =>
        p.approvalTreeId == null || approvalTreeIdSet.has(p.approvalTreeId)
      );
    }

    this.editLabel = this.statusService.getWorkingStatus(this.account as any)
      .isReadOnly
      ? 'Read Only'
      : 'Edit';
    this.editLabelWidth = this.statusService.getWorkingStatus(
      this.account as any
    ).isReadOnly
      ? 100
      : 50;

    this.progGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onProgGridReady,
      onRowSelected: this.onProgRowSelected,
      rowSelection: 'single',
    });

    AgFns.captureGridRouteParams(this.progGridOptions, this.route, 'id');
    this.setTitle("Proximity Programs for " + this.account.name);
    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  onProgGridReady(event: GridReadyEvent) {
    const colDefs = [
      // { ...AgFns.createButtonProps(' ', this.onEdit.bind(this), { label: 'Edit'} ), width: 50 },
      {
        ...AgFns.createButtonProps(' ', this.onNarrative.bind(this), {
          label: 'Narrative',
        }),
        maxWidth: 80,
      },
      {
        ...AgFns.createButtonProps(' ', this.onEdit.bind(this), {
          label: this.editLabel,
        }),
        maxWidth: this.editLabelWidth,
      },
      { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter' },
      {
        headerName: 'Description',
        field: 'description',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        filter: 'agDateColumnFilter',
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        filter: 'agDateColumnFilter',
      },
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
    ];

    const sortModel: ISortModel = [
      { colId: 'name', sort: 'asc' },
      { colId: 'startDate', sort: 'asc' },
    ];

    AgFns.initGrid(this.progGridOptions, colDefs, sortModel);
  }

  async onProgRowSelected(event: RowSelectedEvent) {
    const program = event.data as Program;
    if (program == null) {
      return;
    }
    if (event.node.isSelected()) {
      this.selectedProgram = program;
      this.updateGridRouteParams(this.progGridOptions, program.id);
    } else {
      if (program == this.selectedProgram) {
        this.selectedProgram = undefined;
      }
    }
  }

  onDeleteProgram(program: Program) {
    // TODO:
  }

  async onChangeStatus(row: Program) {
    const errs: string[] = [];

    const selectedStatusId = await StatusChangeDialog.open(this.pngDialogService, {
      statuses: this.activeStatuses,
      isAvailable: () => true,
      currentStatus: row.activeStatus
    },{
      header: "Account Status"
    })

    if (selectedStatusId == row.activeStatusId) {
      return;
    }

    // TODO: Change this to determine if the current admin is in current approval tree.
    // if (selectedStatusId == ActiveStatusEnum.Active && !row.accountAdminGroupId) {
    //   errs.push('An Account Administrator with Editing Rights is required for ACTIVE Programs')
    // }

    if (selectedStatusId == ActiveStatusEnum.Active && !row.approvalTreeId) {
      errs.push('An Proximity Account Organization Tree is required for ACTIVE Programs')
    }

    if (selectedStatusId == ActiveStatusEnum.Active 
          && row.programStartDateTypeId == ProgramStartDateTypeEnum.CalendarDate 
          && row.endDate && row.endDate < new Date()) {
      errs.push('An expired Proximity Program may not be activated')
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(selectedStatusId, 'Program'), 'Program Status Change');
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.progGridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.progGridOptions.api?.refreshCells();
  }

  async onNarrative(row: Program)
  {
    this.updateGridRouteParams(this.progGridOptions, row.id);
    this.router.navigate(['narrative/', row.id], { relativeTo: this.route });
  }

  onEdit(row: Program) {
/*     if (this.account?.activeStatusId !== ActiveStatusEnum.Active) {
      this.dialogService.okDialog(
        'Cannot Edit Program',
        this.account?.name + " must have a status of 'Active' to edit Account Programs"
      );
      return;
    } */

    this.updateGridRouteParams(this.progGridOptions, row.id);
    this.router.navigate([row.id], { relativeTo: this.route });
  }

  onEditAlt(row: Program) {
    this.updateGridRouteParams(this.progGridOptions, row.id);
    this.router.navigate(['alt/', row.id], { relativeTo: this.route });
  }

  async onAdd() {
    if (<ActiveStatusEnum.Active>this.account?.activeStatusId > ActiveStatusEnum.Hold) {
      this.dialogService.okDialog(
        'Cannot Add Program',
        this.account?.name + " must have a status of 'Active' or 'Hold' to add Account Programs"
      );
      return;
    }

    const ok = await this.dialogService.askIfOk(
      'Add a New Program',
      'Make sure that a Proximity Account Organization Tree and an Administrator Group are ready for use before creating a new Proximity Program.  <br/>Proceed to Add Program?'
    );
    if (!ok) {
      return;
    }
    this.router.navigate(['add'], { relativeTo: this.route });
  }
}
