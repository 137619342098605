import { Injectable } from '@angular/core';
import { ActiveStatusEnum, BaseEntity } from '@models';
import { UtilDialogService } from './dialog.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

export enum IStatusEnum {
  Active = 1,
  Hold = 2,
  Phaseout = 3,
  Inactive = 4,
}

// Lowest to highest priority
export const StatusPriority = [
  IStatusEnum.Active,
  IStatusEnum.Hold,
  IStatusEnum.Phaseout,
  IStatusEnum.Inactive,
];

// Implemented on all Entities with a status.
export interface IEntityWithStatus extends BaseEntity {
  getStatusId: () => 1 | 2 | 3 | 4; // IStatusEnum  - because of the way that TS treats enums ( no overlap between them)
  getStatusParents: () => IEntityWithStatus[];
  name?: string; // name of the entity
  isApiEnabled: false;
}

export interface IStatusResult {
  baseEntity: IEntityWithStatus;
  baseStatusId: IStatusEnum;
  baseStatusName: string;
  maxStatusEntity: IEntityWithStatus;
  maxStatusId: IStatusEnum;
  isReadOnly: boolean;
  isActive: boolean;
  //isOkToAddEdit: boolean,
  shortDisplay: string;
  longDisplay: string;
  statusDisplay: string;
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  ref: DynamicDialogRef | undefined;
  constructor(
    private dialogService: UtilDialogService,
    private dynamicService: DialogService
  ) {}

  // TODO: discuss this approach with Jeff - untested.
  public getWorkingStatus(entity: IEntityWithStatus, isApiEnabled: boolean = false): IStatusResult {
    const maxStatusEntity = this.findMaxStatusEntity(entity, entity);
    const maxStatusId = maxStatusEntity.getStatusId();
    const maxStatusName = IStatusEnum[maxStatusId];
    const maxStatusEntityTypeName = maxStatusEntity.entityTypeName;
    const baseStatusName = IStatusEnum[entity.getStatusId()];
    const shortDisplay =
      entity == maxStatusEntity ||
      maxStatusEntity.getStatusId() <= IStatusEnum.Active
        ? baseStatusName
        : `${maxStatusName} ( ${maxStatusEntityTypeName}  )`;
    const longDisplay =
      entity == maxStatusEntity ||
      maxStatusEntity.getStatusId() <= IStatusEnum.Active
        ? baseStatusName
        : `${maxStatusName} set by ${maxStatusEntityTypeName} (${baseStatusName})`;
    const isReadOnly = maxStatusId > 2 || isApiEnabled;
    const isActive = maxStatusId == IStatusEnum.Active;
    const statusDisplay = this.getStatusDisplay(entity, maxStatusEntity, baseStatusName, maxStatusName, maxStatusEntityTypeName, isApiEnabled)

    return {
      baseEntity: entity,
      baseStatusId: entity.getStatusId(),
      baseStatusName: baseStatusName,
      maxStatusId: maxStatusId,
      maxStatusEntity: maxStatusEntity,
      shortDisplay: shortDisplay,
      longDisplay: longDisplay,
      statusDisplay: statusDisplay,
      isReadOnly: isReadOnly,
      isActive: isActive,
      //isOkToAddEdit: isOkToAddEdit
    };
  }

  private getStatusDisplay (
    entity: IEntityWithStatus,
    maxStatusEntity: IEntityWithStatus,
    baseStatusName: string,
    maxStatusName: string,
    maxStatusEntityTypeName: string,
    isApiEnabled: boolean
  ) {
    const lDisplay =
      entity == maxStatusEntity ||
      maxStatusEntity.getStatusId() <= IStatusEnum.Active
        ? baseStatusName
        : `${maxStatusName} set by ${maxStatusEntityTypeName} (${baseStatusName})`;

      if (isApiEnabled) {
        return "API-enabled";
      }
      
      if (maxStatusEntity.getStatusId() == ActiveStatusEnum.Active) {
        return "";
      }

      if (maxStatusEntity.getStatusId() == ActiveStatusEnum.Hold) {
        return lDisplay + ' - editable'
      }

      return lDisplay + ' - read-only'  
  }

  private findMaxStatusEntity(
    entity: IEntityWithStatus,
    maxStatusEntity: IEntityWithStatus
  ) {
    const statusId = entity.getStatusId();
    if (statusId == IStatusEnum.Inactive) return entity;
    maxStatusEntity =
      StatusPriority[statusId] >= StatusPriority[maxStatusEntity.getStatusId()]
        ? entity
        : maxStatusEntity;

    entity.getStatusParents().forEach((e) => {
      if (e == null) return maxStatusEntity;
      maxStatusEntity = this.findMaxStatusEntity(e, maxStatusEntity);
      return maxStatusEntity;
    });

    return maxStatusEntity;
  }

  private isWorkingStatusAvailable(workingStatus: IStatusResult) {
    return (
      workingStatus.maxStatusId == IStatusEnum.Active ||
      workingStatus.maxStatusId == IStatusEnum.Hold
    );
  }

  getStatusExplanation(statusEnum: ActiveStatusEnum, type: string): string {
    let s = '';
    if (statusEnum == ActiveStatusEnum.Active) {
      s = `This ${type} will be activated.  All transactions associated with this ${type} will be processed normally.`;
    }
    if (statusEnum == ActiveStatusEnum.Hold) {
      s = `This ${type} will be put on temporary hold.  All transactions associated with this ${type} will stop; new orders or returns will not be accepted.  Suppliers will be notified to temporarily stop processing orders and returns associated with this ${type}.  Proximity will automatically reactivate this ${type} in 48 hours.`;
    }
    if (statusEnum == ActiveStatusEnum.Phaseout) {
      s = `This ${type} will be phased out.  Any new order or return request associated with this ${type} will not be submitted by Proximity.  Suppliers may continue processing orders and returns associated with this ${type}.`;
    }
    if (statusEnum == ActiveStatusEnum.Inactive) {
      s = `This ${type} will be deactivated.  Any new order or return request associated with this ${type} will not be submitted by Proximity.  Suppliers will be notified to stop work-in-process orders and returns associated with this ${type}.  You may be subject to charges for in-process orders and returns that are stopped as a result of this status change.`;
    }
    if (s.length == 0) {
      return '';
    }
    return s;
  }
}
