/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService, SaveAndQueryComponent, StatusService } from '@core';
import {
  Account,
  AccountAdmin,
  AccountImportCodeOption,
  ActiveStatus,
  Program,
  ProximityAreaEnum,
  ProximityRight,
  ProximityUser,
  ProximityUserRight,
  ActiveStatusEnum,
} from '@models';
import { UtilFns } from '@utils';
import { AccountDbSaveService } from './services/account-db-save.service';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountUnitOfWork } from '@data';
import { Entity, EntityError } from 'breeze-client';
import { DialogService } from 'primeng/dynamicdialog';

export class ImportOption {
  public constructor(init?: Partial<ImportOption>) {
    Object.assign(this, init);
  }
  id?: number;
  name?: string;
}

@Component({
  selector: 'prox-account-frm',
  templateUrl: './account-frm.component.html',
})
export class AccountFrmComponent extends SaveAndQueryComponent {
  accountId!: string;
  account!: Account;

  proximityUser!: ProximityUser;
  accountAdmin!: AccountAdmin;
  accountAdmins: AccountAdmin[] = [];
  areas = [ProximityAreaEnum.Accounts];
  allRights: ProximityRight[] = [];
  appliedUserRights: ProximityUserRight[] = [];
  activeStatuses: ActiveStatus[] = [];
  accountImportCodeOptions: AccountImportCodeOption[] = [];
  importOptions: ImportOption[] = [];
  deleteLabel = 'Delete this Account';

  isBeingAdded!: boolean;
  addedEntities: Entity[] = [];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    private uow: AccountUnitOfWork,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public pngDialogService: DialogService,
    private statusService: StatusService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object) {
    const accountId = params['accountId'];
    UtilFns.assertNonEmptyString(accountId, 'accountId');

    this.isBeingAdded = accountId === 'add';

    if (this.isBeingAdded) {
      this.createEntities();
    } else {
      this.account = await this.dbQueryService.getAccountByIdWithAdmins(
        accountId
      );
      this.accountAdmins = this.account.accountAdmins;
    }
    UtilFns.assertNonNull(this.account, 'account');
    this.allRights = await this.uow.getAllOrQuery(ProximityRight);
    this.activeStatuses = await this.dbQueryService.getAll(ActiveStatus);
    this.accountImportCodeOptions = await this.dbQueryService.getAll(
      AccountImportCodeOption
    );

    this.accountImportCodeOptions.forEach((p) => {
      /*       const io = new ImportOption();
      io.id = p.id,
      io.name = p.description */
      this.importOptions.push(
        new ImportOption({
          id: p.id,
          name: p.description,
        })
      );
      //this.importOptions.push(io);
    });
    this.setTitle('Account Information');
    this.isPageReady = true;
  }

  override get entity() {
    return this.account;
  }

  private createEntities() {
    this.account = this.dbSaveService.createEntity(Account, {
      activeStatusId: ActiveStatusEnum.Hold,
      startDate: new Date(),
      fiscalMonthDayStartDate: new Date(),
    });

    this.proximityUser = this.dbSaveService.createEntity(ProximityUser, {
      activeStatusId: ActiveStatusEnum.Active,
    });

    this.accountAdmin = this.dbSaveService.createEntity(AccountAdmin, {
      accountId: this.account.id,
      proximityUserId: this.proximityUser.id,
    });

    this.appliedUserRights = [];
    this.allRights.forEach((p) => {
      if (p.areaId == 1) {
        const applied = this.dbSaveService.createEntity(ProximityUserRight, {
          proximityUserId: this.proximityUser.id,
          proximityRightId: p.id,
        });
        this.appliedUserRights.push(applied);
      }
    });

    this.addedEntities = [
      this.account,
      this.accountAdmin,
      this.proximityUser,
      ...this.appliedUserRights,
    ];
    this.addedEntities.forEach((e) => {
      e.entityAspect.setUnchanged();
    });
  }

  override async beforeSave() {
    if (this.isBeingAdded) {
      this.addedEntities.forEach((e) => e.entityAspect.setAdded());
      const ok = await this.dbQueryService.checkIfIsUnique(
        this.proximityUser,
        'id',
        'username'
      );
      if (!ok) {
        this.toastr.error(
          'This Administrator Login Name has already been taken.',
          'Unable to Add Account'
        );
        return false;
      }
    }
    return true;
  }

  override async afterSave() {
    if (this.isBeingAdded) {
      // because of circular dependency issues.
      this.account.primaryAccountAdminId = this.accountAdmin.id;
      await this.dbSaveService.saveChanges();
    }
    // no longer an added entity after a save.
    this.isBeingAdded = false;
  }

  override async afterUndo() {
    if (this.isBeingAdded) {
      this.createEntities();
    }
  }

  override async addCrossValidationErrors(): Promise<void> {
    if (!this.isBeingAdded) {
      if (this.account.primaryAccountAdminId == null) {
        this.createValidationError(
          this.account,
          null,
          'Active Proximity Accounts must have a Primary Administrator'
        );
      } else if (
        this.account.primaryAccountAdmin.getStatusId() !=
        ActiveStatusEnum.Active
      ) {
        this.createValidationError(
          this.account,
          null,
          'Account Primary Admninistrator must be status: Active'
        );
      }
    }
  }

  statusButtonLabel() {
    if (this.account.activeStatusId == ActiveStatusEnum.Active) {
      return 'Place Account on Hold'
    } else if (this.account.activeStatusId == ActiveStatusEnum.Hold) {
      return 'Activate Account'
    }
    return 'Contact Proximity Administrator'
  }

  disableViewAdministrator() {
    return (this.dbSaveService.hasChanges()) || (!this.account.primaryAccountAdmin);
  }

  gotoAdministrator() {
    this.router.navigate(['account', 'accounts', this.account.id, 'manage', 'account-administrators', this.account.primaryAccountAdmin.id]);  
  }

  override navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    const prop = error.propertyName;
    UtilFns.focusInputByEntity('#topLevel', errEnt, prop);
  }

  override navigateBack(): void {
    this.router.navigate(['account/accounts']);
  }

  statusMessage(): string {
    return <string>(
      this.statusService.getWorkingStatus(this.account as any).statusDisplay
    );
  }

  public isReadOnly(): boolean {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  okToDelete() {
    return (
      !this.isBeingAdded && this.account.activeStatusId == ActiveStatusEnum.Hold
    );
  }

  async onDelete() {
    const inUse = await this.dbQueryService.checkIfInUse(
      this.account,
      Program,
      'accountId'
    );
    if (inUse) {
      this.toastr.warning(
        `You cannot delete an Account that has applied Programs.`,
        'Cannot Delete'
      );
      return;
    }

    const ok = await this.dialogService.askIfOk(
      'Delete Supplier',
      'Are you sure that you want to delete ' +
        this.account?.name +
        '?  (This action cannot be reversed)'
    );
    if (!ok) {
      return;
    }

    // insure that everything we want to delete is in the cache.
    await this.dbQueryService.getAccountByIdForDelete(this.account.id);

    // circular ref here - clear it first.
    this.account.primaryAccountAdminId = undefined;
    await this.dbSaveService.saveChanges();

    this.account.accountImages.slice().forEach((c) => {
      c.entityAspect.setDeleted();
    });

    this.account.accountAdmins.slice().forEach((c) => {
      c.proximityUser.entityAspect.setDeleted();
      c.entityAspect.setDeleted();
    });

    this.account.accountAdminGroups.slice().forEach((c) => {
      c.accountAdminGroupMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.shippingUserGroups.slice().forEach((c) => {
      c.shippingUserGroupMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.shippingUserGroupAddressMaps.slice().forEach((e) => {
        e.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.programUserGroups.slice().forEach((c) => {
      c.programUserGroupBudgets.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.programUserGroupMaps.slice().forEach((f) => {
        f.entityAspect.setDeleted();
      });
      c.programUserGroupExclusions.slice().forEach((g) => {
        g.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.accountUsers.slice().forEach((c) => {
      c.proximityUser.entityAspect.setDeleted();
      c.entityAspect.setDeleted();
    });

    this.account.budgetProductTypeTags.slice().forEach((c) => {
      c.budgetProductTypeTagMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.programProductTypeTags.slice().forEach((c) => {
      c.programProductCategoryTagMaps.slice().forEach((d) => {
        d.entityAspect.setDeleted();
      });
      c.programProductTypeTagMaps.slice().forEach((e) => {
        e.entityAspect.setDeleted();
      });
      c.entityAspect.setDeleted();
    });

    this.account.accountAddresses.slice().forEach((c) => {
      c.entityAspect.setDeleted();
    });

    this.account.entityAspect.setDeleted();

    const r = await this.dbSaveService.saveChanges();
    this.toastr.success(
      'Account was successfully deleted',
      'Database Activity'
    );
    this.navigateBack();
  }
}
