
import { HttpClient } from '@angular/common/http';
import { DataProperty, Entity, EntityState } from 'breeze-client';
import { firstValueFrom } from 'rxjs';
import { EntityValues, UnitOfWork } from './unit-of-work';

export abstract class DbSaveService {
  constructor(
    public uow: UnitOfWork,
    private http: HttpClient,
  ) { }

  
  private _isBusy = false;

  isBusy() {
    return this._isBusy;
  }

  validateEntities(entities: Entity[]) {
    return this.uow.validateEntities(entities);
  }

  getAddedModifiedEntities(entityTypeNames?: string[]) {
    const changedEntities = this.uow.manager.getEntities(entityTypeNames, [
      EntityState.Modified,
      EntityState.Added,
    ]);
    return changedEntities;
  }

  getChangesByType<T extends Entity>(type: { new (): T }): T[] {
    return <T[]> this.uow.manager.getChanges(type.prototype.entityType);
  }

  getDeleted<T extends Entity>(type?: { new(): T; }) {
    const deletedEnts = this.uow.getChanges();
    if (type) {
      return deletedEnts.filter(e => e instanceof type && e.entityAspect.entityState.isDeleted()) as T[];
    } else {
      return deletedEnts.filter(e => e.entityAspect.entityState.isDeleted()) as T[];
    }
  }

  rejectDeletes<T extends Entity>(type: { new(): T; } | undefined, filter: (ent: T) => boolean) {
    const deletedEnts = this.getDeleted(type);
    const foundEntities = deletedEnts.filter(filter);
    if (foundEntities.length > 0) {
      foundEntities.forEach(e => e.entityAspect.rejectChanges());
    } 
    return foundEntities;
  }

  async saveChangesWrapBusy(shouldRollbackOnError = true) {
    // return await this.busyService.busy( async () => {
    return await this.saveChanges(shouldRollbackOnError);
    // });
  }

  async saveChanges(shouldRollbackOnError = true) {
    try {
      this._isBusy = true;
      
      return await this.uow.saveChanges();
    } catch (e: any) {
      if (shouldRollbackOnError) {
        this.uow.rollback();
      }
      if (e.message) {
        console.error(e.message);
      }
      if (e.entityErrors) {
        console.error(e.entityErrors);
      }
      throw e; 
    } finally {
      this._isBusy = false;
    }
  }

  async saveSelectedChanges(entities: Entity[], shouldRollbackOnError = true) {
    try {
      this._isBusy = true;
      return await this.uow.saveChanges(entities);
    } catch (e: any) {
      if (shouldRollbackOnError) {
        this.uow.rollback();
      }
      if (e.entityErrors) {
        console.error(e.entityErrors);
      }
      throw e;
    } finally {
      this._isBusy = false;
    }
  }

  createEntity<T extends Entity>(type: { new(): T; }, initalValues?: EntityValues<T>, entityState?: EntityState): T {
    return this.uow.createEntity(type, initalValues, entityState );
  }

  createOrUndoEntity<T extends Entity>(type: { new(): T; }, keyObj: EntityValues<T>): T {
    const kps = type.prototype.entityType.keyProperties as DataProperty[];
    const keyValues = kps.map(kp => keyObj[kp.name])
    
    let ent = this.uow.undoIfDeleted( type, keyValues);
    if (ent == null) {
      ent = this.createEntity(type, keyObj);
    }
    return ent;
  }

  rejectChanges() {
    return this.uow.manager.rejectChanges();
  }

  hasChanges() {
    return this.uow.manager.hasChanges();
  }

  getChanges(): Entity[] {
    return this.uow.manager.getChanges();
  }

  getChangesOfType<T extends Entity>(type: { new(): T; } ): T[] {
    return this.uow.manager.getChanges(type.name) as T[];
  }

  /** Post data or parameters to the given resource on the server
   * @param action - resource name on server; method name on this uow's controller
   * @param body - body to be posted
   * @param params - query parameters to be added to the url
   */
  postData<TResponse>(action: string, body: any, params: any): Promise<TResponse> {
    const url = this.uow.manager.serviceName + action;
    return firstValueFrom(this.http.post<TResponse>(url, body, { params: params}));
  }

}
