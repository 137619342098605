export enum AccountImportCodeOptionEnum {
  None = 0,
  Shipping = 1,
  Program = 2,
  Both = 3,
}

export enum ActiveStatusEnum {
  Active = 1,
  Hold = 2,
  Phaseout = 3,
  Inactive = 4,
}

export enum GenderEnum {
  NotSpecified = 0,
  Male = 1,
  Female = 2,
}

export enum CancellationReasonTypeEnum {
  NoLongerNeeded = 0,
  OrderedInError = 1,
  Other = 2,
}

export enum CancellationRequestStatusEnum {
  Setup = 0,
  AwaitingSubmission = 1,
  SupplierInProcess = 2,
  Complete = 3,
}

export enum CancellationRequestDetailStatusEnum {
  Setup = 0,
  AwaitingSubmission = 1,
  SupplierInProcess = 2,
  SupplierRejected = 3,
  SupplierAccepted = 4,
}

export enum LogTypeEnum {
  Unknown = 0,
  Add = 1,
  Update = 2,
  Delete = 3,
  CriticalAdd = 4,
  CriticalUpdate = 5,
  CriticalDelete = 6,
}

export enum DocSubmitTypeEnum {
  Print = 0,
  Email = 1,
  PrintAndEmail = 2,
}

export enum DurationUnitOfMeasureEnum {
  Days = 0,
  Weeks = 1,
  Months = 2,
  Years = 3,
}

export enum FeatureDataTypeEnum {
  String = 0,
  Int = 1,
  Float = 2,
  Bool = 3,
}

export enum JobOrderStatusEnum {
  Setup = 0,
  AwaitingApproval = 1,
  Rejected = 2,
  AwaitingPlacement = 3,
  Placed = 4,
  OnHold = 5,
  Closed = 6,
  Cancelled = 7,
  Invalid = -1,
}

export enum JobOrderTypeEnum {
  EmployeeOrder = 0,
  AccountStockOrder = 1,
  AccountReissuanceOrder = 2,
}

export enum ShipFrequencyEnum {
  Daily = 0,
  Every2Weeks = 1,
  Every4Weeks = 2,
  Every6Weeks = 3,
  WhenBinIsFull = 4,
}

export enum JobOrderOveragePaymentStatusEnum {
  Created = 0,
  Paid = 1,
  Void = 2,
  Refunded = 3,
  IsRefund = 4,
  Cancelled = 5,
}

export enum SupplierSubmissionStatusEnum {
  New = 0,
  Edited = 1,
  Sent = 2,
}

export enum NotificationEventEnum {
  JobOrderApprovalSubmission = 10,
  JobOrderSupplierSubmission = 11,
  JobOrderRejected = 12,
  JobOrdersAwaitingApprovalForLongerThan = 13,
  JobOrdersPlacedAfter = 14,
  ReturnRequestSubmission = 20,
  ReturnRequestStatusUpdate = 21,
  CancellationRequestSubmission = 30,
  CancellationRequestStatusUpdate = 31,
}

export enum NotificationQueryEnum {
  JobOrder = 1,
  JobOrderList = 2,
  JobOrdersAwaitingApprovalLongerThan = 3,
  ReturnRequest = 11,
  CancellationRequest = 21,
  AccountHelp = 110,
  SupplierHelp = 120,
}

export enum NotificationTypeEnum {
  Email = 0,
  SMS = 1,
  InApp = 2,
}

export enum NotificationStatusEnum {
  NotYetSubmitted = 0,
  Pending = 1,
  Complete = 2,
  TimeoutError = 3,
  SubmissionError = 4,
}

export enum OrderPaidByTypeEnum {
  Managed = 0,
  Storefront = 1,
}

export enum StockConditionEnum {
  Available = 0,
  InStock = 1,
  PhaseOut = 2,
  Unavailable = 3,
}

export enum ProgramViolationRuleEnum {
  Warn = 0,
  Stop = 1,
}

export enum ProgramAllowanceRemainderRuleEnum {
  RemainingAllowanceAddedToNewAllowances = 0,
  RemainingAllowancesLost = 1,
}

export enum ProgramApplicabilityTypeEnum {
  NewUser = 0,
  ExistingUser = 1,
  Both = 2,
}

export enum ProgramPcardTypeEnum {
  None = 0,
  ByAdminPerOrder = 1,
  ByProgram = 2,
  ByShippingUserGroup = 3,
  ByProgramUserGroup = 4,
}

export enum ProgramPurchaseOrderTypeEnum {
  None = 0,
  ByAdminPerOrder = 1,
  ByProgram = 2,
  ByShippingUserGroup = 3,
  ByProgramUserGroup = 4,
}

export enum ProgramStartDateTypeEnum {
  CalendarDate = 0,
  AnniversaryDate = 1,
}

export enum ProximityAreaEnum {
  Unknown = 0,
  Accounts = 1,
  Suppliers = 2,
}

export enum ProximityRightEnum {
  Unknown = 0,
  CanCreateAccountUser = 1,
  CanEditAnyAccountUser = 2,
  CanCreateSupplierAdmin = 3,
  CanEditAnySupplierAdmin = 4,
  CanEditManufacturer = 5,
  CanEditProduct = 6,
  CanEditProductFeature = 7,
  CanEditProductType = 8,
  CanEditAddon = 11,
  CanViewAnyJobOrder = 12,
  CanEditAnyJobOrder = 13,
  CanCreateProgram = 14,
  CanImpersonateOtherAdmins = 15,
  CanViewAnyProgram = 16,
  CanEditAnyProgram = 17,
  CanCreateAccountAdmin = 18,
  CanEditAnyAccountAdmin = 19,
  CanEditAccountRights = 20,
  CanEditSupplierRights = 21,
  CanEditAccountHelp = 22,
  CanEditSupplierHelp = 23,
  Last = 24,
}

export enum ReturnCreditTypeEnum {
  Credit = 0,
  AccountOwned = 1,
}

export enum ReturnReasonTypeEnum {
  Return = 0,
  Repair = 1,
}

export enum ReturnRequestStatusEnum {
  Setup = 0,
  AwaitingSubmission = 1,
  SupplierInProcess = 2,
  Complete = 3,
}

export enum ReturnRequestDetailStatusEnum {
  Setup = 0,
  AwaitingSubmission = 1,
  SupplierAwaitingReturn = 2,
  SupplierInProcess = 3,
  SupplierRejected = 4,
  SupplierAccepted = 5,
}
