import { Component, OnInit } from '@angular/core';
import { BaseService } from '@core';
import { AccountUser } from '@models';
import { UtilFns } from '@utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { PaymentProfile } from './payment-interfaces';
import { PaymentProfileDialogComponent, PaymentProfileDialogData, } from './payment-profile-dialog.component';
import { PaymentService } from './payment-service';

export interface PaymentSelectDialogData {
  title: string;
  amount: number;
  accountUserId: string;
}

/** Allows selecting a payment method.  Actual payment happens later. */
@Component({
  selector: 'prox-payment-select-dialog',
  templateUrl: './payment-select-dialog.component.html',
})
export class PaymentSelectDialogComponent implements OnInit {
  static config = {
    header: 'Select Payment Method',
    width: '740px',
    contentStyle: { height: '400px' },
  };
  amount?: number;
  accountUserId?: string;
  title?: string;
  paymentProfiles?: PaymentProfile[];
  selectedProfile?: PaymentProfile;
  customerProfileId?: string;
  customerProfileName?: string;
  accountUser?: AccountUser;
  message?: string;

  constructor(
    dialogConfig: DynamicDialogConfig<PaymentSelectDialogData>,
    private ref: DynamicDialogRef,
    private baseService: BaseService,
    private dbQueryService: AccountDbQueryService,
    private paymentService: PaymentService
  ) {
    // super(baseService, route, dbSaveService, dbQueryService);
    this.amount = dialogConfig.data?.amount;
    this.accountUserId = dialogConfig.data?.accountUserId;
    this.title = dialogConfig.data?.title;
  }

  async ngOnInit(): Promise<void> {
    // need to be an account user (or impersonating one) to use this page
    UtilFns.assertNonEmptyString(this.accountUserId, 'accountUserId');
    this.accountUser = await this.dbQueryService.getAccountUserById(
      this.accountUserId
    );

    await this.getCustomerProfile();
  }

  /** Load the user's payment profiles from Authorize.net */
  private async getCustomerProfile() {
    try {
      const result = await this.paymentService.getCustomerProfile();
      this.customerProfileId = result.profile.customerProfileId;
      this.customerProfileName = result.profile.description;
      this.paymentProfiles = result.profile.paymentProfiles as PaymentProfile[];
      if (this.paymentProfiles && this.paymentProfiles.length) {
        const def =
          this.paymentProfiles.find((x) => x.defaultPaymentProfile) ||
          this.paymentProfiles[0];
        this.selectedProfile = def || undefined;
      }
    } catch (e: any) {
      this.setMessage(e);
    }
  }

  /** Show a dialog for managing the user's payment profiles */
  async getProfilePage() {
    await this.baseService.dialogService.create<PaymentProfileDialogData>(
      PaymentProfileDialogComponent,
      { title: '' },
      PaymentProfileDialogComponent.config
    );
    // reload profile list after dialog closes
    this.getCustomerProfile();
  }

  get paymentName() {
    return this.paymentService.getProfileDisplayName(this.selectedProfile);
  }

  /** Set the message content using error messages from the result */
  private setMessage(result: any) {
    const msg = this.paymentService.getErrorMessage(result);
    this.message = msg;
  }

  ok() {
    if (this.selectedProfile) {
      this.selectedProfile.customerProfileId = this.customerProfileId as string;
    }
    this.ref.close(this.selectedProfile);
  }

  cancel() {
    this.ref.close();
  }
}
