<prox-main [parent]="this" [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate"></prox-main>

<ng-template #bodyTemplate>
  <h5 class="card-header">
    <div class="flex flex-row">
      <span *ngIf="!isBeingAdded">{{account.name}}</span>
      <span *ngIf="isBeingAdded">* New Account *</span>
      <span *ngIf="!isBeingAdded"
        style="margin-left:10px; padding-top: 4px;  font-size:small; font-variant: small-caps; color: red;">{{statusMessage()}}</span>
    </div>
  </h5>
  <div class="flex flex-row gap-6">
    <div class="card-body flex-grow-2 flex flex-column w-4" *ngIf="isPageReady">
      <fieldset style=" border-width: 0px; margin-left: -10px;">
        <!--       <div class="flex flex-column"> -->
        <div class="w-12 pb-3">
          <prox-field label="Account Name">
            <input type="text" [disabled]="isReadOnly()" pInputText autocomplete="off" pAutoFocus [autofocus]="true"
              [(ngModel)]="account.name" [bzModel]="account" name="name" />
          </prox-field>
        </div>
        <div class="flex flex-row">
          <div class="w-6 pb-3">
            <prox-field label="Start Date">
              <p-calendar [disabled]="!isBeingAdded" [(ngModel)]="account.startDate" [bzModel]="account"
                name="startDate"></p-calendar>
            </prox-field>
          </div>
          <div class="w-6 pl-3">
            <prox-field label="Fiscal Period">
              <p-calendar [disabled]="!isBeingAdded" [(ngModel)]="account.fiscalMonthDayStartDate" [bzModel]="account"
                name="fiscalMonthDayStartDate"></p-calendar>
            </prox-field>
          </div>
        </div>
        <div class="w-12">
          <prox-field label="User Import Code Requirements"></prox-field>
          <p-dropdown [options]="importOptions" [(ngModel)]="account.accountImportCodeOptionId" optionValue="id"
            [disabled]="isReadOnly()" optionLabel="name" placeholder="Choose Account User import requirements"
            name="importOptions"></p-dropdown>
        </div>
        <div class="w-12 mt-3">
          <div *ngIf="(!isBeingAdded)">
            <prox-field label="Primary Account Administrator"></prox-field>
            <p-dropdown [options]="account.accountAdmins" [(ngModel)]="account.primaryAccountAdminId" optionValue="id"
              [disabled]="isReadOnly()" optionLabel="proximityUser.name"
              placeholder="Select your Primary Account Administrator"
              name="accountAdmins"></p-dropdown>
            <span style="padding-left: 10px;">
              <button (click)="gotoAdministrator()" pButton type="button" label="View" class="p-button-sm"
                [disabled]="disableViewAdministrator()"></button>
            </span>
          </div>
        </div>
        <div class="flex mt-4">
          <div class="w-12" *ngIf="isBeingAdded">
            <div class="flex">
              <h5>Primary Account Administrator</h5>
            </div>
            <prox-user-frm [proximityUser]="proximityUser" [isBeingAdded]="isBeingAdded"></prox-user-frm>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="flex flex-column w-3 mt-5">
      <p-panel header="Current Status">
        <h3>{{account.activeStatus.name}}</h3>
        <p-button size="small" label={{statusButtonLabel()}}></p-button>
      </p-panel>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <prox-save-cancel [uow]="this" (undo)="onCancel()" (save)="onSave()" (cancel)="onCancel()"></prox-save-cancel>
</ng-template>