/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ColDef, ColumnApi, GetRowIdParams, GridApi, GridOptions, RowDoubleClickedEvent, GridReadyEvent, IDatasource, CellClickedEvent, GetContextMenuItemsParams, CellRangeParams, IServerSideDatasource, IServerSideGetRowsParams, FirstDataRenderedEvent, ValueGetterParams, ValueSetterParams, IRowNode, SortModelItem, SortChangedEvent, ValueFormatterParams
} from '@ag-grid-community/core';
import { ActivatedRoute, UrlTree } from '@angular/router';

import { Entity } from 'breeze-client';
import * as _ from 'lodash';
import { DateFns, UtilFns } from '@utils';
import { TypedQuery, UnitOfWork } from '@data';
import { IHasSuspendables, ISuspendable } from '../component-interfaces';
import { AgButtonProps, ButtonRendererComponent } from './button-renderer.component';
import { AgValidationErrorTooltip } from './ag-validation-error-tooltip';
import { GridToolTipValueDialog } from '../base/grid-tooltip-value.dialog';
import { UtilDialogService } from '../../services/dialog.service';
import { IconProps, IconRendererComponent } from './icon-renderer.component';
import { CellEditor } from 'primeng/table';


export enum GridOverlay {
  None = 1,
  Loading,
  NoRowsFound,
}

export interface GridState {
  fm?: unknown; // IFilterModel;
  sm?: ISortModel;
  pg?: number;
  key?: unknown;
  keyField?: string;
}

export interface IDisplayOrder {
  displayOrder: number;
}

export interface ExtraGridOptions {
  detailProperty?: string,
  hasId?: boolean;
  shouldSizeToFit?: boolean;
  isNavigating?: boolean;
}

export interface ISortCol {
  colId: string;
  sort?: "asc" | "desc" | null;
}

// export type ISortModel = ISortCol[];
export type ISortModel = SortModelItem[];

function hasSuspendables(obj: object): obj is IHasSuspendables {
  return 'suspend' in obj && obj['suspend'] instanceof Function;
}

export class AgFns {

  static CellClass = {
    editable: 'cell-editable',
    error: 'cell-error',
    notEditable: 'cell-not-editable',
    numeric: 'cell-numeric'
  }

  /** GridOptions for grids.  Automatically sets up master-detail functions if detailProperty is provided. */
  static initGridOptions(bindTo: object, baseGridOptions: GridOptions, extraGridOptions?: ExtraGridOptions): GridOptions {
    extraGridOptions = { hasId: true, shouldSizeToFit: true,  ...extraGridOptions}

    const opts = this.initBaseGridOptions(extraGridOptions);
    // self ref - used because context is passed in many places that gridOptions themselves are not.
    opts.context.gridOptions = opts;
    const dialogService = (bindTo as any).dialogService as UtilDialogService
    if (dialogService) {

      opts.context.showTooltipDialog = async () => {
        const value = await dialogService.create(GridToolTipValueDialog,
          { header: 'Enter value', value: '' },
          { closable: false });
        return value;
      }
    }

    Object.keys(baseGridOptions).forEach((key) => {
      const val = baseGridOptions[key];
      if (val?.bind) {
        baseGridOptions[key] = val.bind(bindTo);
      }
    });

    Object.assign(opts, baseGridOptions);
    if (opts.rowSelection == null) {
      opts.rowSelection = 'single';
    }
    if (opts.pagination == null) {
      opts.pagination = true;
    }
    if (hasSuspendables(bindTo)) {
      // context added in InitBAseGridOptions
      bindTo.addSuspendable(<ISuspendable>opts.context);
    }
    return opts;
  }

  private static initBaseGridOptions(extraGridOptions: ExtraGridOptions): GridOptions {
    const { detailProperty, hasId } = extraGridOptions;
    const opts = <GridOptions> {
      stopEditingWhenCellsLoseFocus: true,
      // if detailProperty is provided, this is a master/detail grid
      masterDetail: !!detailProperty,
      isRowMaster: (data) => {
        // return true when data has details, false  otherwise
        if (data == null || detailProperty == null) {
          return false;
        }
        let r = _.get(data, detailProperty, []);
        r = Array.isArray(r) ? r : [r]
        return r.length > 0;
        // Old code - which assumes that detailProperty was a simple string - and not a path
        // return (data && detailProperty && data[detailProperty]) ? data[detailProperty].length > 0 : false;
      },
      getRowHeight: (params) => {
        if (params.node && params.node.detail) {
          // height is a function of the number of detail rows
          const arr = _.get(params.data, detailProperty || '', []);
          // Old code - which assumed that detailProperty was a simple string - and not a path
          // const arr = detailProperty && params.data[detailProperty];
          const allDetailRowHeight = arr ? arr.length * 28 : 28;
          return allDetailRowHeight + 45; // rows + header + 10px for horizontal scrollbar
        } else {
          // otherwise return fixed master row height
          return 27;
        }
      },
      onRowDoubleClicked(e: RowDoubleClickedEvent) {
        // toggle row expansion
        if (!e.node) { return; }
        e.node.setExpanded(!e.node.expanded);
      },
      onSortChanged(e: SortChangedEvent) { 
        if (!e.context.isNavigating) {
          e.api.paginationGoToFirstPage();
        } 
        e.context.isNavigating = false;
      },
      context: {
        // The implementation of the ISuspendable interface.
        suspend: () => opts.api?.stopEditing(),
        isNavigating: false
      },
      // These may be turned off later - but to get the right behaviour we need to turn them on 'early'
      enableRangeSelection: true,
      allowContextMenuWithControlKey: true,
      getContextMenuItems: AgFns.getContextMenuItems,
      onFirstDataRendered: (event: FirstDataRenderedEvent) => {
        // TODO: base this on some other property with this as the default.
        // TODO: also this will throw a warning if this grid is on another tab that hasn't been shown yet.
        if (extraGridOptions.shouldSizeToFit) {
          event.api.sizeColumnsToFit();
        }
      }
    };


    if (hasId && opts.getRowId == null) {
      opts.getRowId = (params: GetRowIdParams) => {
        const data = params.data;
        return data?.id ? data.id.toString() : data?.toString() ?? 'none';
      };
    }
    return opts;
  }

  /** GridOptions for inner grids */
  static createDetailGridOptions(): GridOptions {
    return {
      headerHeight: 20,
      defaultColDef: {
        suppressMenu: true,
        flex: 1,
        sortable: true
      },
      tooltipShowDelay: 500
    };

  }

  static initGrid(gridOptions: GridOptions, colDefs: ColDef[], defaultSortModel: ISortModel | null = null, shouldAutoSize = false) {
    let gridState = gridOptions.context.gridState;
    if (!gridState) {
      gridState = <GridState>{};
      AgFns.attachGridState(gridOptions, gridState);
    }

    if (gridState.sm == null) {
      gridState.sm = defaultSortModel ?? undefined;
    }

    colDefs = colDefs.filter(x => !_.isEmpty(x));


    if (gridOptions.onModelUpdated == null && shouldAutoSize) {
      gridOptions.onModelUpdated = (e) => {
        e.columnApi?.autoSizeAllColumns();
      }
    };

    // gridOptions.loadingOverlayComponent = AgLoadingOverlayComponent; // uses the spinner - if we want it - right now the text seems clearer
    gridOptions.paginationPageSize = 100;

    gridOptions.stopEditingWhenCellsLoseFocus = true;
    gridOptions.singleClickEdit = true;
    gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      resizable: true,
    };
    // allows for range selection and apply value menu if any column in the grid is editable
    if (!colDefs.some(cd => cd.editable)) {
      gridOptions.enableRangeSelection = false;
      gridOptions.allowContextMenuWithControlKey = false;
      gridOptions.getContextMenuItems = () => [];
    }
    gridOptions.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      // gridOptions.overlayNoRowsTemplate = 
      //   '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No rows to show</span>',

      colDefs.forEach(c => c.floatingFilter = !!c.filter);
    gridOptions.columnTypes = {
      dateFmt: {
        valueFormatter: (params) => DateFns.fmtDate(params.value)
      },
      dateTimeFmt: {
        valueFormatter: (params) => DateFns.fmtDateTimeShort(params.value)
      }
    };
    AgFns.updateColDefs(colDefs);
    UtilFns.wait(1);
    const gridApi = gridOptions.api;
    const colApi = gridOptions.columnApi;
    if (gridApi == null || colApi == null) {
      return;
    }

    gridApi.setColumnDefs(colDefs);

    if (gridState.fm != null) {
      gridApi.setFilterModel(gridState.fm);
    }
    if (gridState.sm != null) {
      colApi.applyColumnState({ state: Object.values(gridState.sm) });
    }

  }

  static isGridOptions(object: any): object is GridOptions {
    if (!object) return false;
    return 'onGridReady' in object;
  }

  static updateMasterDetail(gridInfo?: GridOptions | GridReadyEvent, item?: object) {
    if (gridInfo == null) return;
    let gridApi!: GridApi;
    let gridOptions!: GridOptions;
    if (this.isGridOptions(gridInfo)) {
      gridOptions = gridInfo;
      gridApi = gridInfo.api as GridApi;

    } else {
      gridOptions = gridInfo.context.gridOptions as GridOptions;
      gridApi = gridInfo.api;
    }
    if (!gridApi || !item) return;
    if (!(gridOptions.isRowMaster && gridOptions.getRowId)) return;
    const rowId = gridOptions.getRowId({ data: item } as GetRowIdParams)
    if (!rowId) return;
    const rowNode = gridApi.getRowNode(rowId);
    if (!rowNode) return;
    rowNode.master = gridOptions.isRowMaster(item),
    gridApi.redrawRows();

  }

  static forceMasterDetail(gridApi?: GridApi, rowNodeKeyValue?: string, isMaster?: boolean) {
    if (!gridApi || !rowNodeKeyValue) return;
    const rowNode = gridApi?.getRowNode(rowNodeKeyValue);

    if (rowNode) {
      rowNode.master = !!isMaster;
      gridApi
      gridApi?.redrawRows();
    }
  }

  static extractApis(event: GridReadyEvent) {
    const { api, columnApi } = event;
    return [api, columnApi] as const;
  }

  // the keyField is the property path to the key value on whatever 'entity' type the grid is bound to.
  static createGridState(queryParams: object, keyField: string | null = null) {
    const gridState = <GridState>{
      fm: this.decodeUriEncodedQueryParam(queryParams['fm']),
      sm: this.decodeUriEncodedQueryParam(queryParams['sm']),
      pg: queryParams['pg'] ? +queryParams['pg'] : null,
      key: queryParams['key'],
      keyField: keyField
    };
    return gridState;
  }

  // gets the gridState from the gridOptions and saves it back as a variable on the gridOptions
  static getAndAttachGridState(gridOptions: GridOptions, key: string | null = null, keyField: string | null = null) {
    const gridApi = gridOptions.api;
    if (gridApi == null) {
      return {};
    }
    const oldGridState = <GridState>gridOptions?.context?.gridState || {};
    const gridState: GridState = {
      fm: gridApi.getFilterModel(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sm: this.getSortRules(gridOptions.columnApi!),
      pg: gridApi.paginationGetCurrentPage() + 1,
      key: key, // don't use old key because null value is intended to be used to clear search
      keyField: keyField ?? oldGridState.keyField
    }
    this.attachGridState(gridOptions, gridState);
    return gridState;
  }

  static attachGridState(gridOptions: GridOptions, gridState: GridState = <GridState>{}) {
    // next line is needed by the buildDatasource method
    gridOptions.context = { ...gridOptions.context, gridOptions: gridOptions, gridState: gridState };
  }

  static buildGridRouteParamsUrl(urlTree: UrlTree, gridOptions: GridOptions, key: string | null = null) {
    let url = urlTree.toString();
    if (gridOptions == null || gridOptions.api == null) {
      return url;
    }
    const gridState = this.getAndAttachGridState(gridOptions, key);
    const fmJson = JSON.stringify(gridState.fm);
    const smJson = JSON.stringify(gridState.sm);
    const urlSuffix = 'fm=' + encodeURIComponent(fmJson)
      + '&sm=' + encodeURIComponent(smJson)
      + '&pg=' + gridState.pg
      + (gridState.key ? '&key=' + gridState.key : '');

    const firstDelim = (url.indexOf('?') > 0) ? '&' : '?';
    url = url + firstDelim + urlSuffix;
    return url;
  }

  // uniqSortModel?: ISortModel; // used to guarantee uniquenss.
  // initFn is a function that is run before every getRows call
  // returnFn is a function that runs at the end of every getRows call and is passed the result T[]
  static buildDatasource<T extends Entity>(queryFn: () => TypedQuery<T>, uniqSortModel: ISortModel | string= [{ colId: 'id', sort: 'asc' }], opts?: {
    initFn?: () => void;
    returnFn?: ((x: T[]) => void);

  }): IServerSideDatasource {
    if (typeof(uniqSortModel) == 'string' ) {
      uniqSortModel = [ { colId: uniqSortModel, sort: 'asc' } ] as ISortModel
    }
    // let oldSortRules = [] as ISortModel;
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const context = params.context;
        if (context == null) {
          throw Error('attachGridState was never called for this gridOptions');
          return;
        }
        const gridOptions = context.gridOptions as GridOptions;
        const gridApi = gridOptions.api;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const columnApi = gridOptions.columnApi!;
        const gridState = <GridState>(context.gridState || {});

        if (gridApi == null) {
          throw Error('buildDatasource cannot be called until the gridApi is available');
          return;
        }

        // puts up 'waiting' icon
        this.showOverlay(gridApi, GridOverlay.Loading);
        // tslint:disable-next-line:no-unused-expression
        opts?.initFn && opts.initFn();

        let q = queryFn();
        const fm = params.request.filterModel;
        const isFilterPresent = fm && Object.keys(fm).length > 0;
        if (isFilterPresent) {
          const keys = Object.keys(fm);
          keys.forEach(k => {
            const f = fm[k];
            if (f) {
              const clause = {};
              let subclause: object | null = null;
              if (f.type == 'blank' || f.type == 'notBlank') {
                subclause = {};
                subclause[f.type] = null;
              } else if (f.filterType === 'date') {
                const year = new Date(f.dateFrom).getFullYear();
                if (year > 2010 && year < 2060) {
                  subclause = {};
                  subclause[f.type] = f.dateFrom;
                }
              } else if (f.filterType === 'set') {
                subclause = {};
                subclause['in'] = f.values;
              } else {
                subclause = {};
                subclause[f.type] = f.filter;
              }

              if (subclause != null) {
                if ('blank' in subclause) {
                  clause[k] = null;
                } else if ('notBlank' in subclause) {
                  clause[k] = { ne: null };
                } else {
                  clause[k] = subclause;
                }
                q = q.where(clause);

              }
            }
          });
        }

        // Note: the uniqSortModel is used to provide a final column that always comes last that can guarantee uniqueness for paging
        // let sortRules = this.getSortRules(columnApi);
        
        let sortRules = params.request.sortModel;
        // const startAtFirstPage = !_.isEqual(sortRules, oldSortRules);
        // oldSortRules = sortRules;
        if (uniqSortModel) {
          sortRules = [...sortRules, ...uniqSortModel as ISortModel]
        }
        sortRules.forEach(sr => {
          q = q.orderBy(sr.colId, sr.sort === 'desc');
        });

        const startRow = params.request.startRow ?? 0;
        const endRow = params.request.endRow ?? 0;
        // if (startAtFirstPage) {
        //   endRow = endRow-startRow;
        //   startRow = 0;
        //   params.request.startRow = startRow;
        //   params.request.endRow = endRow;
        // }
        const count = endRow - startRow;
        q = q.skip(startRow).take(count);
        const p1 = q.execute().then(r => {
          if (opts?.returnFn) {
            opts.returnFn(r);
          }
          // NOTE - this is necessary because you might navigate away from this component while query is still pending
          // but destroyCalled is NOT part of the public api
          if ((params.api as any).destroyCalled) {
            return;
          }
          if (r.length < count) {
            // return the number of rows
            const rowCount = startRow + r.length;
            params.success({
              rowData: r,
              rowCount: rowCount
            });
          } else {
            params.success({
              rowData: r
            });
          }

          // we are not using the closure version of gridApi, columnApi because the grid may have been destroyed by the time this call executes.

          const gridApi = gridOptions.api;
          const columnApi = gridOptions.columnApi;
          if (gridApi == null) {
            return;
          }
          if (gridState.key != null) {
            if (gridState.pg != null) {
              // Needed because ag-grid sometimes calls for getRows for rows that are not on the 'next' page
              if ((gridState.pg - 1) * (gridOptions.paginationPageSize ?? 0) === startRow) {
                this.selectGridStateKey(gridOptions);
              }
            } else {
              this.selectGridStateKey(gridOptions);
            }
          } else if (r.length > 0) {
            // Don't  preselect first row if the grid is using checkboxSelection.
            if (!gridApi.getColumnDefs()?.some((cd: ColDef) => cd.checkboxSelection == true)) {
              this.selectFirstRow(gridApi);
            }

          }

          // setTimeout( () => columnApi?.autoSizeAllColumns(), 0);

          AgFns.showOverlay(gridApi, r.length ? GridOverlay.None : GridOverlay.NoRowsFound);
        });
      }
    };
  }

  static getSortRules(columnApi: ColumnApi): ISortModel {

    let state = columnApi.getColumnState();
    // keep only columns that have sort defined; order them by sort index
    state = state.filter(s => !!s.sort);
    if (state.length > 1) {
      state = state.sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0));
      return state.map(s => { return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex } }) as ISortModel
    } else {
      return state.map(s => { return { colId: s.colId, sort: s.sort } }) as ISortModel;
    }
  }

  static addFilterClause(filter: object, fieldName: string, fieldType: 'number' | 'text', fieldValue: unknown, operator = 'equals') {
    if (filter == null) {
      filter = {};
    }
    filter[fieldName] = {
      filterType: fieldType,
      type: operator,
      filter: fieldValue
    };
    return filter;
  }


  static decodeUriEncodedQueryParam(val: string) {
    if (val == null) {
      return null;
    }
    return JSON.parse(decodeURIComponent(val));
  }

  static moveColumnsToEnd(colApi: ColumnApi, fieldNames: string[]) {
    if (colApi == null) {
      return;
    }
    const columns = colApi.getColumns();
    if (columns != null && columns.length > 0) {
      const lastColIx = columns.length - 1;
      colApi.moveColumns(fieldNames, lastColIx);
    }
  }

  static updateColDefs(colDefs: ColDef[]) {
    colDefs.forEach(cd => {
      if (cd.filter === 'agTextColumnFilter') {
        cd.filterParams = { filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'blank', 'notBlank'] };
      } else if (cd.filter === 'agNumberColumnFilter') {
        cd.filterParams = {
          filterOptions:
            ['equals', 'notEqual', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'blank', 'notBlank']
        };
        cd.type = 'numericColumn';
      } else if (cd.filter === 'agDateColumnFilter') {
        cd.filterParams = {
          filterOptions:
            ['equals', 'notEqual', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'blank', 'notBlank']
        };
      }
      if (cd.filterParams) {
        cd.filterParams.buttons = ['clear', 'apply'];
        cd.filterParams.suppressAndOrCondition = true;
      }
      if (cd.field || cd.colId) {
        const fieldName = cd.field ?? cd.colId as string;
        const lcFieldName = fieldName.toLowerCase();
        if (fieldName.endsWith('Date')) {
          // doesn't do anything yet but will later
          // cd.type = 'dateColumn';
          cd.valueFormatter = cd.valueFormatter ?? this.dateFormatter;
          cd.valueParser = (params) => this.parseDate(params.newValue)
        }
        if (fieldName.endsWith('Amt') || lcFieldName.endsWith('price') || lcFieldName.endsWith('amt')) {
          cd.type = 'numericColumn';
          cd.valueFormatter = cd.valueFormatter ?? this.currencyFormatter
        }
        if (fieldName.endsWith('Qty') || lcFieldName.endsWith('qty')) {
          cd.type = 'numericColumn';
        }
        if (fieldName.endsWith('Pct') || fieldName.endsWith('Rate')) {
          cd.type = 'numericColumn';
          cd.valueFormatter = cd.valueFormatter ?? this.pctFormmater;
        }
        if (fieldName.endsWith('Ts')) {
          if (cd.type !== 'dateFmt') {
            cd.valueFormatter = cd.valueFormatter ?? this.dateTimeFormatter;
          }
        }
      }
      if (cd.resizable == null) {
        cd.resizable = true;
      }
      this.updateColDefValidation(cd);
    });
  }

  static dateFormatter(params) {
    return DateFns.fmtDate(params.value);
  }

  static dateTimeFormatter(params) {
    return DateFns.fmtDateTimeShort(params.value);
  }

  static currencyFormatter(params) {
    return UtilFns.fmtCurrency(params.value);
  }

  static pctFormmater(params) {
    return UtilFns.fmtPct(params.value, 2);
  }

  static updateColDefValidation(cd: ColDef) {
    cd.cellClass = cd.cellClass ?? this.getCellClassFn(cd);
    if (!cd.tooltipValueGetter) {
      cd.tooltipValueGetter = (params) => {
        const colDef = params.colDef as ColDef;
        if (!colDef.editable) return;
        if (!params.data) return;
        const entityAspect = (params.data as Entity).entityAspect;
        if (entityAspect == null || entityAspect.entityState.isUnchanged()) return;
        const fieldName = colDef.field as string;
        const errors = entityAspect.getValidationErrors(fieldName);
        return errors.map(ve => ve.errorMessage).join('</br>')
      }
      cd.tooltipComponent = AgValidationErrorTooltip
    }
  }

  static getCellClassFn(cd: ColDef) {
      return (params) => {
        const cellClass = this.getValCellClass(params);
        if (cd.type == 'numericColumn') {
          return [this.CellClass.numeric, cellClass];
        } else {
          return cellClass;
        }
    }
  }

  static getValCellClass(params) {
    if (!params.colDef.editable) return '';
    if (!params.data) return '';

    const entityAspect = (params.data as Entity)?.entityAspect;
    if (entityAspect) {
      if (entityAspect.entityState.isUnchanged()) {
        return this.CellClass.editable;
      }
      const fieldName = params.colDef.field as string;
      const errors = entityAspect.getValidationErrors(fieldName);

      if (errors.length > 0) {
        return this.CellClass.error;
      } else {
        return this.CellClass.editable;
      }
    } else {
      return this.CellClass.editable;
    }
  }

  static async refreshGrid(gridOptions: GridOptions, data?: object[], adjColumns?: 'sizeToFit' | 'autoSize') {
    
    // HACK: to avoid 'cannot get grid to draw rows when it is in the middle of drawing rows.'
    await UtilFns.wait(0);
    if (gridOptions == null || gridOptions.api == null) return;
    gridOptions.api.setRowData(data ?? []);
    // needed for rowClass operations to be triggered. 
    gridOptions.api.redrawRows();
    gridOptions.api.refreshCells();
    if (adjColumns == 'autoSize') {
      gridOptions.columnApi?.autoSizeAllColumns();
    } else if (adjColumns == 'sizeToFit') {
      gridOptions.api.sizeColumnsToFit();
    }
  }

  static getContextMenuItems(params: GetContextMenuItemsParams) {
    const column = params.column;
    const context = params.context;
    if (column == null) return [];
    const colDef = column.getColDef();
    if (colDef == null || !colDef.editable) return [];
    const cellDataType = (colDef as any).cellDataType;
    let getFmtValue = (params) => params.value;
    const valueFormatter = colDef.valueFormatter;

    if (typeof valueFormatter == 'function') {
      getFmtValue = (params) => valueFormatter(params);
    }

    const gridApi = params.api;
    gridApi.stopEditing();
    const cellRanges = gridApi.getCellRanges();
    if (cellRanges == null) return [];
    // shrinks all cellRanges to just this one column.
    const newRanges = cellRanges.map(cr => {
      const range: CellRangeParams = {
        rowStartIndex: cr.startRow?.rowIndex ?? null,
        rowStartPinned: cr.startRow?.rowPinned,
        rowEndIndex: cr.endRow?.rowIndex ?? null,
        rowEndPinned: cr.endRow?.rowPinned,
        columns: [column]
      };
      return range;
    });
    gridApi.clearRangeSelection();
    newRanges?.forEach(r => gridApi.addCellRange(r));
    const applyValue = (value: string) => {
      newRanges.forEach(r => {
        let [from, to] = [r.rowStartIndex, r.rowEndIndex];
        if (from == null || to == null) return;
        if (from > to) [from, to] = [to, from];
        for (let ix = from; ix <= to; ix++) {
          const rowNode = gridApi.getDisplayedRowAtIndex(ix);
          
          rowNode?.setDataValue(column, value);
        }
      })
    };

    const convertValue =(value: any, cellDataType: any) => {
      if (cellDataType == 'number') {
        return Number(value);
      } else if (cellDataType == 'date') {
        return Date.parse(value);
      } else if (cellDataType == 'boolean') {
        return Boolean(value);
      } else {
        return value;
      }
    }
    
    const result = [
      {
        name: `Copy '${getFmtValue(params)}' to selected cells`,
        action: () => {
          applyValue(params.value)
        },
      },
      {
        name: `Input value for selected cells`,
        action: async () => {
          const value = await context.showTooltipDialog();
          const cvtValue = convertValue(value, cellDataType)
          applyValue(cvtValue);
        }
      }
    ];
    return result;
  }

  // New version - because of bugs with previous version due to ag grid changes - ( 6/15/2024)
  static createDropdownEditor(fieldPath: string, items: object[], onChange: (params) => void = () => null, idProp = 'id', nameProp = 'name') {
    return {
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        // extract the names
        values: items.map(x => x[nameProp]),
      },
      valueSetter: function(params) {
        //  When we select a value from our drop down list, this function will make sure
        //  that our row's record receives the "id" (not the text value) of the chosen selection.
        const val =  items.find(x => x[nameProp] == params.newValue)?.[idProp];
        _.set(params.data, fieldPath, val);
        if (onChange) {
          onChange(params);
        }
        return true;
      }, 
      valueGetter: function(params) {
          //  We don't want to display the raw "id" value.. we actually want 
          //  the "name" string for that id.
          return items.find(x => x[idProp] == _.get(params.data, fieldPath))?.[nameProp];
      }
    }
  }

  static createRichDropdownEditor(fieldPath: string, items: object[], onChange: (params) => void = () => null, idProp = 'id', nameProp = 'name') {
    return {
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: items.map(x => x[nameProp]),
      },
      valueSetter: function(params) {
        const val =  items.find(x => x[nameProp] == params.newValue)?.[idProp];
        _.set(params.data, fieldPath, val);
        if (onChange) {
          onChange(params);
        }
        return true;
      }, 
      valueGetter: function(params) {
          return items.find(x => x[idProp] == _.get(params.data, fieldPath))?.[nameProp];
      }
    }
  }

  // // OBSOLETE (see above) - REMOVE AFTER ALL REFS CONVERTED 
  // static createDropdownEditorPropsFromArray(items: object[], idProp = 'id', nameProp = 'name') { 
  //   const map = UtilFns.objectArrayToMap(items, idProp, nameProp);
  //   return this.createDropdownEditorPropsFromMap(map)
  // }

  // OBSOLETE (see above) - REMOVE AFTER ALL REFS CONVERTED 
  // keys of map are actual bound values
  // values of map are display values
  // static createDropdownEditorPropsFromMap(map: Map<any, any>) {
  //   const sortedKeys = _.sortBy(Array.from(map.keys()), k => map.get(k));
  //   return {

  //     cellEditorSelector: () => {
  //       return {
  //         component: 'agRichSelect',
  //         params: { values: sortedKeys },
  //       };
  //     },
  //     valueFormatter: (params) => {
  //       // convert code to value
  //       return map.get(params.value);
  //     },
  //     valueParser: (params) => {
  //       // convert value to code
  //       const r = Array.from(map.entries()).find( ([k,v]) => v = params.newValue);
  //       if (r) {
  //         return r[0];
  //       }
  //     },
      
  //   }
  // }

  // OBSOLETE (see above) - REMOVE AFTER ALL REFS CONVERTED 
  // keys of map are actual bound values
  // values of map are display values
  // static createDropdownEditorPropsFromMapObj(map: object) {
  //   // '.map' below needed because Object.keys method converts null -> 'null' which is undesirable here. 
  //   const sortedKeys = _.sortBy(Object.keys(map), k => map[k]).map( x => (x == 'null') ? null : x);
  //   return {

  //     cellEditorSelector: () => {
  //       return {
  //         component: 'agRichSelect',
  //         params: { values: sortedKeys },
  //       };
  //     },
  //     valueFormatter: (params) => {
  //       // convert code to value
  //       return map[params.value];
  //     },
  //     valueParser: (params) => {
  //       // convert value to code
  //       return _.findKey(map, (v) => v === params.newValue);
  //     },
      
  //   }
  // }

  // OBSOLETE (see above) - REMOVE AFTER ALL REFS CONVERTED 
  // items are the actual bound values
  // keyName is the name of the display property
  // static createDropdownEditorObjectProps(items: object[], keyName: string) {
  //   const sortedItems = _.sortBy(items, i => i[keyName]);
  //   return {
  //     cellEditorSelector: () => {
  //       return {
  //         component: 'agRichSelect',
  //         params: { values: sortedItems },
  //       };
  //     },
  //     valueFormatter: (params) => {
  //       // convert value to display
  //       return params.value && params.value[keyName];
  //     },
  //   };
  // }

  // static createCellButtonProps(headerName: string, templateRef?: TemplateRef<unknown>, colId?: string, width?: number ) {
  //   return {
  //     headerName: headerName,
  //     colId: colId || _.camelCase(headerName),
  //     cellRenderer: TemplateRendererComponent,
  //     hide: false,
  //     sortable: false,
  //     cellRendererParams: {
  //       ngTemplate: templateRef,
  //     },
  //     width: width,
  //   };
  // }

  static createButtonProps(headerName: string, onClick: (item: any, event?: CellClickedEvent, origEvent?: UIEvent) => void,
    buttonProps?: AgButtonProps) {
    return {
      headerName: headerName,
      hide: false,
      sortable: false,
      cellRenderer: ButtonRendererComponent,
      cellRendererParams: {
        onClick: onClick,
        ...buttonProps
      },
    };
  }

  // default ag-grid header, according to https://www.ag-grid.com/angular-data-grid/column-headers/#header-templates
  // eslint-disable-next-line @typescript-eslint/member-ordering
  static defaultHeaderTemplate = `<div class="ag-cell-label-container" role="presentation">
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
      <!-- icon -->
      <span ref="eText" class="ag-header-cell-text"></span>
      <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
      <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
      <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
      <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
      <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
  </div>
</div>`;

  /** Create a ColDef for an icon column, optionally with an icon header. */
  static createIconProps(headerName: string, headerIcon: string, onClick: (item: any, event?: CellClickedEvent, origEvent?: UIEvent) => void,
    iconProps?: IconProps) {
    const config: Partial<ColDef> = {
      headerName: headerName,
      hide: false,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      cellRenderer: IconRendererComponent,
      cellRendererParams: {
        onClick: onClick,
        ...iconProps
      },
    };
    if (headerIcon) {
      const template = this.defaultHeaderTemplate.replace('<!-- icon -->', '<i class="' + headerIcon + ' -mr-2"></i>');
      config.headerComponentParams = { template: template };
    }
    return config;
  }

  static createCheckboxProps(headerName: string, field?: string, width?: number) {
    return {
      headerName: headerName,
      field: field,
      cellEditor: 'agCheckboxCellEditor', 
      cellRenderer: 'agCheckboxCellRenderer',
      suppressKeyboardEvent: ( p) => { return p.event.key === ' '; },
      width: width,
      maxWidth: width
    };
  }

  // static createCheckboxPropsOld(headerName: string, field: string, width?: number) {
  //   return {
  //     headerName: headerName,
  //     field: field,
  //     cellRenderer: CheckboxRendererComponent,
  //     width: width,
  //     // needed because the onCellClick event on a checkbox cell ( not the checkbox itself) opens an text editor and we want to suppress that. 
  //     onCellClicked: (event: CellClickedEvent) => { event.api?.stopEditing() }

  //   };
  // }

  static createSetFilterObjectProps(headerName: string, field: string, values: object[], valueProp = 'name') {
    const sortedValues = values.map(v => v[valueProp] as string).sort();
    return this.createSetFilterProps(headerName, field, sortedValues);
  }

  static createSetFilterProps(headerName: string, field: string, strValues: string[]) {
    return {
      headerName: headerName,
      field: field,
      filter: 'agSetColumnFilter',
      filterParams: {
        cellHeight: 25,
        values: strValues,
        debounceMs: 1000,
      }
    };
  }

  static createIncludedInListCheckBoxProps<T extends Entity>(type: new () => T, uow: UnitOfWork, headerName: string, 
    isIncluded: (e: ValueGetterParams) => boolean,
    keyObjFn: (e: ValueSetterParams) => object, 
    canSet: (boolean | ((e: ValueSetterParams) => boolean ) ) = true )  {
    const  canSetFn = (typeof canSet === 'function') ? canSet : () => canSet;
    return {
      ...AgFns.createCheckboxProps(headerName, '', 100), editable: true,
      valueGetter: (e) => {
        return isIncluded(e);
      },
      valueSetter: (e: ValueSetterParams) => {
        // get the current map entity if it exists.
        const keyObj = keyObjFn(e);
        if (keyObj == null) return false;
        const keyValues = Object.values(keyObj);
        const ent = uow.getEntityByKey(type, keyValues);
        if (ent) {
          const es = ent?.entityAspect.entityState;
          if (es?.isAdded()) {
            if (!canSetFn(e)) {
              e.api.redrawRows();
              return false;
            }
            ent?.entityAspect.setDetached();
          } else if (es?.isDeleted()) {
            ent?.entityAspect.rejectChanges();
          } else {
            if (!canSetFn(e)) {
              e.api.redrawRows();
              return false;
            }
            ent.entityAspect.setDeleted();
          }
        } else {
          if (!canSetFn(e)) {
            e.api.redrawRows();
            return false;
          }
          uow.createEntity(type, keyObj);
        }
        return true;
      }
    }
  }

  static captureGridRouteParams(gridOptions: GridOptions, route: ActivatedRoute, keyName?: string) {
    const gridState = AgFns.createGridState(route.snapshot.queryParams, keyName);
    AgFns.attachGridState(gridOptions, gridState);
  }

  static applyGridRouteParams(gridOptions: GridOptions) {
    const gridApi = gridOptions.api;
    if (gridApi == null) {
      return;
    }

    const gridState = gridOptions.context?.gridState;
    const pg = gridState?.pg;
    if (pg == null) {
      if (gridOptions.rowModelType == null || gridOptions.rowModelType == 'clientSide') {
        this.selectGridStateKey(gridOptions, true);
      } 
      return;
    }
    
    // causes next sortChanged to be ignored. - see onSortChanged in 
    gridOptions.context.isNavigating  = true;

    if (gridOptions.rowModelType == 'serverSide') {
      const ix = (pg - 1) * (gridOptions.paginationPageSize ?? 100);
      // first up, need to make sure the grid is actually showing enough rows
      gridOptions.serverSideInitialRowCount = ix+1;
      gridApi.setRowCount(ix + 1, false);
      // if ((gridApi.getInfiniteRowCount() ?? 0) < ix) {
      //   // gridApi.setInfiniteRowCount(ix + 1, false);
      //   gridApi.setRowCount(ix + 1, false);
      // }
      if (gridApi.paginationGetCurrentPage() != (pg - 1)) {
        gridApi.paginationGoToPage(pg - 1);
      }
      // next, we can jump to the row ( page actually)
      gridApi.ensureIndexVisible(ix, 'top');
    } else {
      gridApi.paginationGoToPage(pg - 1);
      const ix = (pg - 1) * (gridOptions.paginationPageSize ?? 100);
      gridApi.ensureIndexVisible(ix, 'top');
    }
    
    if (gridOptions.rowModelType == null || gridOptions.rowModelType == 'clientSide') {
      this.selectGridStateKey(gridOptions, true);
    }
  }

  static refreshDetailRowHeights(gridXXX: GridOptions | GridApi) {
    const gridApi = this.isGridOptions(gridXXX) ? gridXXX['api'] : gridXXX;
    if (!gridApi) return;
    // gridApi.forEachNode(row => row.detailNode && row.detailNode.setRowHeight(null));
    gridApi.resetRowHeights();
    gridApi.redrawRows();
  }

  static async selectDefaultRow(gridXXX: GridOptions | GridApi) {
    if (!AgFns.anyRowsSelected(gridXXX)) {
      await AgFns.selectFirstRow(gridXXX);
    }
  }

  static async selectFirstRow(gridXXX: GridOptions | GridApi, colKey?: any) {
    const gridApi = this.isGridOptions(gridXXX) ? gridXXX['api'] : gridXXX;
    if (!gridApi) { return; }

    // Ugh... cannot seem to get this right all of the time - lots of approaches but ...

    await UtilFns.wait(0);
    const node = gridApi.getDisplayedRowAtIndex?.(0);
    if (node != null) {
      // sometimes node gets selected before onRowSelected fires so this insure that we first onRowSelected.
      if (node.isSelected != null) {
        node.setSelected(false);
      }
      node.setSelected(true);
    }
    if (colKey != null) {
      gridApi.startEditingCell( { rowIndex: 0, colKey: colKey})
    }

    // Two other approaches

    // setTimeout( () => {
    //   gridApi.forEachNode(node => node.rowIndex == 0 && node.setSelected(true));
    // });

    // Hack from https://github.com/ag-grid/ag-grid/issues/2662
    // const firstNode = gridApi.getDisplayedRowAtIndex(0);
    // firstNode?.addEventListener('dataChanged', (event: any) => {
    //   // console.log(event.node);
    //   event.node.setSelected(true);
    // });
    return node?.data;
  }

  static anyRowsSelected(gridXXX: GridOptions | GridApi) {
    const gridApi = this.isGridOptions(gridXXX) ? gridXXX['api'] : gridXXX;
    if (!gridApi) return;
    const rows = gridApi.getSelectedRows();
    return rows.length > 0;
  }

  // selects a row in the grid - uses the keyField set in createGridState
  static selectGridStateKey(gridOptions: GridOptions, showSelectFirstIfNotFound = true) {
    const gridApi = gridOptions.api;
    if (!gridApi) return;
    const gridState = gridOptions.context.gridState;
    const key = gridState.key;
    const keyField = gridState.keyField;
    if (key == null || keyField == null) {
      if (showSelectFirstIfNotFound) {
        this.selectFirstRow(gridOptions);  
      }
      return;
    }
    gridState.key = null;
    let found = false;
    gridApi.forEachNode((node, ix) => {
      // _.get used because keyField may be a dotted path.
      const nodeKeyValue = _.get(node.data, keyField);
      if (nodeKeyValue == key) {
        gridApi.ensureIndexVisible(node.rowIndex ?? 1, 'middle');
        node.setSelected(true);
        found = true;
      }
    });
    if (!found && showSelectFirstIfNotFound) {
      this.selectFirstRow(gridOptions);
    }
    return found;
  }

  static selectGridRowByLocation(gridXXX: GridApi | GridOptions, locationParams: any , keyFn?: (e) => string, key?: string, colKey?: any) {
    if (!_.isEmpty(locationParams)) {
      if (keyFn == null) {
        keyFn = (e: any) => e.id;
      }
      if (key == null) {
        key = locationParams['id'] as string;
      }
      AgFns.selectGridRowByKey(gridXXX, keyFn, key, colKey);
    } else {
      AgFns.selectFirstRow(gridXXX);
    }
  }

  // selects a row in the grid by value of some datafield that the grid is bound to. - cannot not be a virtual row
  static async selectGridRowByKey(gridXXX: GridApi | GridOptions, keyFn: (e) => string, key: string, colKey?: any) {
    const gridApi = this.isGridOptions(gridXXX) ? gridXXX['api'] : gridXXX;
    if (!gridApi) { return; }
    await UtilFns.wait(0);
    let firstNode: any = null;
    gridApi.forEachNode((node, ix) => {
      if (node.data && keyFn(node.data) === key) {
        gridApi.ensureIndexVisible(node.rowIndex ?? 1, 'middle');
        node.setSelected(true);
        firstNode = node;
      }
    });
    if (firstNode != null && colKey != null) {
      gridApi.startEditingCell( { rowIndex: firstNode.rowIndex, colKey: colKey});
    }
    return firstNode as IRowNode;
  }

  static getRowIndex(gridApi: GridApi, rowId: string) {
    const rowNode = gridApi.getRowNode(rowId);
    return rowNode?.rowIndex ?? null;
  }

  static getNextToSelectedNode(gridOptions: GridOptions) {
    const nodes = gridOptions.api?.getSelectedNodes();
    if (nodes == null || nodes.length == 0) { return null; }

    let rowIndex = _.first(nodes)?.rowIndex || 0;
    rowIndex = rowIndex > 0 ? rowIndex - 1 : rowIndex + 1;
    const node = gridOptions.api?.getDisplayedRowAtIndex(rowIndex);
    return node;
  }

  static async waitForGrid(gridOptions: GridOptions) {
    let isReady = false;
    let count = 0;
    while (count < 3 && !isReady) {
      await UtilFns.wait(0);
      count++;
      isReady = gridOptions.api != null;
    }
    return isReady;
  }

  // // busyService can be null in which case we are just overlaying the grid - and not setting the busy flag.
  // // This is useful if we are going to put a dialog up within the busyGrid op. 
  // static async busyGrid<T>(gridOptions: GridOptions | GridOptions[], busyService: BusyService, op:  (() => Promise<T>)) {
  //   gridOptions = Array.isArray(gridOptions) ? gridOptions : [gridOptions];
  //   gridOptions = gridOptions.filter( go => go?.api != null);
  //   gridOptions.forEach(go => go.api?.showLoadingOverlay());
  //   try {
  //     if (busyService != null) {
  //       return await busyService.busy(op);
  //     } else {
  //       return await op();
  //     }
  //   } finally {
  //     // note ? is because api can disappear before this is run if navigating to another page
  //     gridOptions.forEach(go => go.api?.hideOverlay());
  //   }
  // }



  static showOverlay(gridApi: GridApi, goEnum: GridOverlay) {
    if (!gridApi) { return; }
    if (goEnum === GridOverlay.Loading) {
      gridApi.showLoadingOverlay();
    } else if (goEnum === GridOverlay.NoRowsFound) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }

  // Note columnApi.autoSizeAllColumns() doesn't seem to work in some cases.
  static autoSizeAllColumns(gridOptions: GridOptions) {
    const columnApi = gridOptions?.columnApi;
    if (columnApi) {
      setTimeout(() => {
        columnApi.autoSizeAllColumns();
      }, 0);
    }
    // const allColIds = columnApi.getAllColumns().map((col) => col.getColId());
    // columnApi.autoSizeColumns(allColIds);
  }

  static sizeColumnsToFit(gridXXX: GridOptions | GridApi) {
    const gridApi = this.isGridOptions(gridXXX) ? gridXXX['api'] : gridXXX;
    if (gridApi instanceof GridApi) {
      setTimeout(() => {
        gridApi?.sizeColumnsToFit();
      }, 0);
    }
  }

  static parseDate(value) {
    const date = new Date(value);
    return isFinite(date.getTime()) ? date : null;
  }

  // static fmtDateCell(cell, zeroDisplay = '') {
  //   if (cell.value == null) { return '-'; }
  //   return UtilFns.fmtDate(cell.value, zeroDisplay);
  // }

  

  static implementsIDatasouce(object: object): object is IDatasource {
    return 'getRows' in object;
  }

  static implementsIServerSideDatasouce(object: object): object is IServerSideDatasource {
    return 'getRows' in object;
  }

  static stripTagsFormatter(params: ValueFormatterParams): string {
    return (params.value || '').toString().replace(/(<([^>]+)>)/gi, '');
  }
}
