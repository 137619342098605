// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { CancellationRequest } from './cancellation-request';
import { ProximityUser } from './proximity-user';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class CancellationRequestNote extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK cancellationRequest */
  cancellationRequestId!: string;
  /** String */
  note!: string;
  /** DateTimeOffset */
  noteTs!: Date;
  /** Guid FK proximityUser */
  proximityUserId?: string;
  /** FK cancellationRequestId */
  cancellationRequest!: CancellationRequest;
  /** FK proximityUserId */
  proximityUser!: ProximityUser;
}

