// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountUser } from './account-user';
import { CancellationRequestDetail } from './cancellation-request-detail';
import { CancellationRequestNote } from './cancellation-request-note';
import { CancellationRequestStatus } from './cancellation-request-status';
import { JobOrder } from './job-order';
import { CancellationRequestStatusEnum } from './enums';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class CancellationRequest extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountUser */
  accountUserId?: string;
  /** Enum FK cancellationRequestStatus */
  cancellationRequestStatusId!: CancellationRequestStatusEnum;
  /** Int32 */
  displayId!: number;
  /** Guid FK jobOrder */
  jobOrderId!: string;
  /** DateTimeOffset */
  requestCreatedTs!: Date;
  /** DateTimeOffset */
  requestPlacedTs?: Date;
  /** Binary */
  rowVersion!: any;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** Inv FK cancellationRequestId */
  cancellationRequestDetails!: CancellationRequestDetail[];
  /** Inv FK cancellationRequestId */
  cancellationRequestNotes!: CancellationRequestNote[];
  /** FK cancellationRequestStatusId */
  cancellationRequestStatus!: CancellationRequestStatus;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
}

