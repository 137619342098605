// Defines the interfaces used by the Authorize.net API on the server and returned to the client

/** Base type for all responses*/
export interface PaymentsResponseBase {
  messages: {
    resultCode: number; // 'Ok' | 'Error';
    message: { code: string; text: string };
  };
}

/** Response when retrieving a customer profile */
export interface CustomerProfileResponse extends PaymentsResponseBase {
  profile: CustomerProfile;
}

/** Response when creating a transaction */
export interface CreateTransactionResponse extends PaymentsResponseBase {
  transactionResponse: Transaction;
}

/** Response when retrieving transaction details */
export interface TransactionDetailsResponse extends PaymentsResponseBase {
  transaction: TransactionDetails;
}

/** Defines a customer profile (set of payment methods belonging to a customer) */
export interface CustomerProfile {
  customerProfileId: string;
  description: string;
  email: string;
  paymentProfiles: PaymentProfile[];
}

/** Defines a single payment method */
export interface PaymentProfile {
  customerProfileId: string;
  customerPaymentProfileId: string;
  defaultPaymentProfile: boolean;
  payment: { Item: BankAccount | CreditCard };
}

export interface BankAccount {
  accountType: number; // 'checking' | 'savings' | 'businessChecking';
  routingNumber: string;
  accountNumber: string;
  nameOnAccount: string;
  echeckType: number;
  bankName: string;
}

export interface CreditCard {
  cardNumber: string;
  expirationDate: string;
  cardType: string;
  // cardArt: CardArt;
  // issuerNumber: string;
  // isPaymentToken: boolean;
}

/** Response to all types of payment transactions, including void and refund */
export interface Transaction {
  accountType: string;
  accountNumber: string;
  authCode: string;
  networkTransId: string;
  transId: string;
  errors?: { errorCode: string; errorText: string }[];
}

export interface TransactionPayment extends Transaction {
  amount: number;
}

export interface TransactionDetails extends Transaction {
  refTransId: string;
  submitTimeUTC: Date;
  submitTimeLocal: Date;
  transactionType: string;
  transactionStatus: string;
  authAmount: string;
  settleAmount: string;
  payment: { Item: BankAccount | CreditCard };
}

// Payloads for Proximity API in TransactionController

export interface PaymentPayload {
  /** numeric */
  customerProfileId: string;
  /** numeric */
  customerPaymentProfileId: string;
  amount: number;
  /** JO number or other Proximity reference. Max 25 characters */
  poNumber: string;
}

export interface RefundPayload {
  /** numeric */
  refTransId: string;
  /** Only needed if different from original transaction amount */
  amount?: number;
  /** JO number or other Proximity reference. Max 25 characters */
  poNumber: string;
}
