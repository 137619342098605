import { Injectable } from '@angular/core';
import { AccountUser, ActiveStatusEnum, JobOrder, JobOrderStatusEnum, Program, ProgramAllowance, ProgramStartDateTypeEnum } from '@models';
import { AccountDbQueryService } from './account-db-query.service';
import { DateFns } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class ProgramRulesService {
  constructor(
    public dbQueryService: AccountDbQueryService,
  ) { }

  public async findOpenProgramsByAccountUser(au: AccountUser, jobOrders: JobOrder[]) {
    if (!(au.proximityUser.activeStatusId == ActiveStatusEnum.Active)) {
      return [];
    }
    let allPrograms = await this.dbQueryService.getProgramsForAccountUser(au.id);
    allPrograms = allPrograms.filter(x => x.activeStatusId == ActiveStatusEnum.Active);
    
    const tmpPrograms = allPrograms.filter(p => this.isAvailableProgram(p, au));

    const currentProgramIdSet = new Set(
      jobOrders
        .filter(
          (jo) =>
            jo.jobOrderStatusId == JobOrderStatusEnum.Setup ||
            jo.jobOrderStatusId == JobOrderStatusEnum.AwaitingApproval ||
            jo.jobOrderStatusId == JobOrderStatusEnum.Rejected ||
            jo.jobOrderStatusId == JobOrderStatusEnum.OnHold
        )
        .map((jo) => jo.programId)
    );
    const openPrograms = tmpPrograms.filter((p) => !currentProgramIdSet.has(p!.id));
    return openPrograms;
  }

  public isAvailableProgram(program: Program, accountUser: AccountUser) {
    if (this.isExpiredProgram(program, accountUser))  return false;
    if (!program.isUserApplicable(accountUser)) return false;
    return true;
  }

  private isExpiredProgram(program: Program, accountUser: AccountUser) {
    const now = new Date().getTime();
    if (program.programStartDateTypeId == ProgramStartDateTypeEnum.CalendarDate) {
      return now >= program.endDate!.getTime();
    } else {
      // TODO: not sure this should be allowed. 
      // This means that a user without a ProgramAnniversaryDate cannot participate in this program...
      if (accountUser.programAnniversaryDate == null) return false;
      const endDate = DateFns.dateAdd(accountUser.programAnniversaryDate!, program.endsAfterAnniversaryNumDays!, 'days')
      return now >= endDate.getTime();
    }
  }

  public async findOpenProgramAllowancesByAccountUser(au: AccountUser, openPrograms: Program[]) {
    const openAllowances: ProgramAllowance[] = [];
    const userProgramUserGroups = au.programUserGroupMaps.map(p => p.programUserGroup);
    openPrograms.forEach(pro => {
      pro.programAllowances.forEach(pa => {
        pa.programAllowanceUserGroupMaps.forEach(map => {
          if (userProgramUserGroups.includes(map.programUserGroup)) {
            openAllowances.push(pa);
          }
        }) 
      })  
    });
    return openAllowances;
  }

/*   public async placedCurrentAllowanceByAccountUserAmt (jobOrdersByAccountUser: JobOrder[] | null, openProgramsByAccountUser: Program[] | null, accountUser: AccountUser) {
    let amt = 0.00;
    if (!jobOrdersByAccountUser || !openProgramsByAccountUser) {
      openProgramsByAccountUser = await this.findOpenProgramsByAccountUser(accountUser, []);
      if (openProgramsByAccountUser.length == 0) {
        jobOrdersByAccountUser = await this.dbQueryService.getJobOrdersByAccountUser(accountUser.id) 
      }
    };
    if (openProgramsByAccountUser?.length == 0 || jobOrdersByAccountUser?.length == 0) { return 0.00 };
    openProgramsByAccountUser?.forEach(p => {
      p.programAllowances.forEach(j => {
        if (j.)
      })
    })
   return 0.00;

  } */
}
