import { Component } from '@angular/core';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { BaseService, QueryComponent } from '@core';
import { ActivatedRoute } from '@angular/router';
import {
  Account,
  ActiveStatusEnum,
  
  NarrativeUserGroupDto,
  ProgramAllowanceRemainderRule,
  Program,
  ProgramAllowance,
  ProgramAllowanceRemainderRuleEnum,
  ProgramIssuance,
  ProgramViolationRuleEnum,
} from '@models';
import { UtilFns } from '@utils';

@Component({
  selector: 'prox-program-narrative',
  templateUrl: './program-narrative.component.html',
  styleUrls: ['./program-narrative.component.css']
})
export class ProgramNarrativeComponent extends QueryComponent {
  programId?: string;
  program!: Program;
  account!: Account;
  narrative: string = '';
  userGroupDtos: NarrativeUserGroupDto[] = [];

  titleHtml = '';
  subTitleHtml = '';
  isSummary = false;
  summaryHtml = '';
  isStatus = false;
  statusHtml = '';
  isTerm = false;
  termHtml = '';
  isParameters = false;
  allowanceParametersHtml = '';
  allowancesHtml = "";
  issuanceParametersHtml = '';
  issuancesHtml = "";
  isUserGroups = false;
  noParametersHtml = "";

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbQueryService: AccountDbQueryService
  ) {
    super(baseService, route, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.programId = params['programId'];
    UtilFns.assertNonEmptyString(this.programId, 'programId');

    this.program = await this.dbQueryService.getProgramById(this.programId);
    UtilFns.assertNonNull(this.program, 'Program');

    this.account = await this.dbQueryService.getAccountById(
      this.program.accountId
    );
    UtilFns.assertNonNull(this.account, 'Account');

    this.userGroupDtos = await this.dbQueryService.getNarrativeUserGroupDtos(
      this.programId,
      this.program.approvalTreeId!
    );

    this.buildNarrative();
    this.isPageReady = true;
  }

  buildNarrative(): void {
    this.narrative = '';
    this.buildTitle();
    this.buildSummarySection();
    this.buildStatusSection();
    this.buildTermSection();
    this.buildParametersSection();
    this.buildUserGroupsSection();
  }

  private buildTitle() {
    this.titleHtml = '';
    this.subTitleHtml = '';
    this.titleHtml += `${this.account.name} Program: <b>${this.program.name}</b>`;
    this.subTitleHtml += this.program.description;
  }

  hasParams(): boolean {
    return (
      (this.program.programAllowances &&
        this.program.programAllowances.length > 0) ||
      (this.program.programIssuances &&
        this.program.programIssuances.length > 0)
    );
  }

  addNewline(ct: number) {
    let _res = '';
    for (let i = 0; i < ct; i++) {
      _res += '<br/>';
    }
    return _res;
  }

  private addSubTitle(value: string): void {
    this.narrative += "<div class='narrative-subheader'>" + value + '</div>';
  }

  buildUserGroupsSection() {
    this.isUserGroups = true;
  }

  buildSummarySection() {
    this.summaryHtml = '';
    this.summaryHtml += `The <b>${
      this.program.name
    } Program</b> is a <b>Proximity ${
      this.program.orderPaidByType.name
    } Program</b>.  ${this.programTypeStub()}`;
    this.isSummary = true;
  }

  private programTypeStub(): string {
    // Managed
    let _res = '';
    if (this.program.orderPaidByTypeId == 0) {
      _res = `A Managed Program is a service offered by <b>${this.account.name}</b> that lets employees, staff and team-members 
        easily obtain products and services that they need and want as part of their daily job responsbilities.  Depending on policy, employees may place requisition requests 
        directly by personal Proximity website access or through a Supervisor or an Administrator.  Individuals might have access to more than one Managed Program depending on their particular 
        requirement(s).`;
    }

    // Store Front
    if (this.program.orderPaidByTypeId == 1) {
      _res = `A Store Front Program is a service offered by <b>${this.account.name}</b> that lets employees, staff and team-members
        purchase personalized products and services offered through an easy-to-use online website.  This is a great place to find corporate-identity gifts and incentives. Items and services offered within
        this program may be individually purchased or they may be discounted or free-of-charge through corporate incentive programs.`;
    }
    return _res;
  }

  private buildStatusSection() {
    this.statusHtml += `The current status of this program is: <b>${
      this.program.activeStatus.name
    }</b>.  ${this.statusStub()}`;
    this.isStatus = true;
  }

  private buildTermSection() {
    this.termHtml = '';
    // Calendar type
    if (this.program.programStartDateTypeId === 0) {
      this.termHtml += `The program started on ${this.program.startDate?.toLocaleDateString()} and terminates on ${this.program.endDate?.toLocaleDateString()}.  `;
    }

    // Anniversary type
    if (this.program.programStartDateTypeId === 1) {
      this.termHtml += `The program starting date is based on a per-employee anniversary date 
      and starts ${this.program.startAfterAnniversaryNumDays} days after the anniversary date and ends ${this.program.endsAfterAnniversaryNumDays} .  `;
    }
    this.isTerm = true;
  }

  private statusStub(): string {
    if (this.program.activeStatusId === ActiveStatusEnum.Active) {
      return `The status of ${this.program.activeStatus.name} signifies a Proximity Program is that is currently in use and functioning to manage orders.  `;
    }

    if (this.program.activeStatusId === ActiveStatusEnum.Hold) {
      return `Status of <b>${this.program.activeStatus.name}</b> signifies a Proximity Program that is temporarily placed on hold and undergoing some 
      revisions or changes. <b><i>Programs in this status will continue to allow shipments on existing orders but will not accept new orders.</i></b>  
      Typically these programs will return to Active status in a short period of time.  `;
    }

    if (this.program.activeStatusId === ActiveStatusEnum.Phaseout) {
      return `Status of <b>${this.program.activeStatus.name}</b> signifies a Proximity Program that is in the process of being discontinued. <b><i>Programs in this status will continue to allow shipments on existing orders but will not accept new orders.</i></b>  
      Typically these programs will ultimately be permanently deactivated (status: Inactive) and replaced with updated active programs.  `;
    }

    if (this.program.activeStatusId === ActiveStatusEnum.Inactive) {
      return `Status of <b>${this.program.activeStatus.name}</b> signifies a Proximity Program that is discontinued. <b><i>Programs in this status stop unshipped orders and will not accept new orders.  Unshipped orders on Inactive programs will be canceled.</i></b>  
      Typically these programs will ultimately be replaced with updated active programs.  `;
    }

    return ``;
  }

  private async buildParametersSection() {
    this.allowanceParametersHtml = '';
    this.issuanceParametersHtml = '';
    this.noParametersHtml = '';

    this.allowanceParametersHtml += this.getAllowanceParameters();
    this.issuanceParametersHtml += this.getIssuanceParameters();
    this.noParametersHtml += this.getNoParametersHtml();
    this.isParameters = true;
  }

  private getNoParametersHtml(): string {
    let _stub = "";
    if (!this.hasParams()) {
      _stub +=
        'There are no distribution parameters or controls for this Proximity Program.  Proximity Users are therefore able to acquire items and/or services without limitation.  ';
      if (this.program.doAllOrdersRequireApproval) {
        _stub += `However, every order must be submitted to a ${this.account.name} Administrator for approval.  `;
      }
    }
    return _stub;
  } 

  private getAllowanceParameters(): string {
    let _stub = ``;
    if (this.program.programAllowances.length > 0) {
      if (this.program.programAllowances.length == 1) {
        _stub += `The <b>${this.program.name} Program</b> is an <b>Allowance Program</b> with one Allowance Plan (below).  `;
      }

      if (this.program.programAllowances.length > 1) {
        _stub += `The <b>${this.program.name} Program</b> is an <b>Allowance Program</b> with ${this.program.programAllowances.length} Allowance Plans (below).  `;
      }

      _stub += `Proximity Allowance Programs allow ${this.account.name} employees to acquire products and/or services up to a pre-authorized monetary limit.  `;
      _stub += this.allowanceProgramViolationRuleStub();
      
      _stub += this.programAllowanceRemainderRuleStub();
    }

    return _stub;
  }

  getAllowance(programAllowance: ProgramAllowance) {
    let _stub = '';
    _stub += `This Allowance Plan which is is called "<b>${
      programAllowance.name
    }</b>" and it's a part of the <b>${
      this.program.name
    } Program</b>. It offers an allowance of ${UtilFns.fmtCurrency(
      programAllowance.allowanceAmt
    )} per ${this.account.name} employee</b>.  `;
    //_stub += this.allowanceProgramViolationRuleStub() + this.addNewline(2);
    _stub += this.allowOverAllowancePurchasesStub(programAllowance);
    _stub +=
      this.allowanceApplyShippingStub(programAllowance) + this.addNewline(1);
    return _stub;
  }

  getIssuanceParameters() {
    let _stub = "";
    if (this.program.programIssuances.length > 0) {
      if (this.program.programAllowances.length > 0) {
        _stub += `The <b>${this.program.name} Program</b> is also an <b>Issuance Program</b> with `;
      } else {
        _stub += `The <b>${this.program.name} Program</b> is an <b>Issuance Program</b> with `;
      }

      if (this.program.programIssuances.length === 1) {
        _stub += `<b>one Issuance Plan</b> (below).  `;
      } else {
        _stub += `<b>${this.program.programIssuances.length} Issuance Plans</b> (below).  `;
      }
    }

    _stub += `Proximity Issuance Programs allow ${this.account.name} employees to acquire products and services within categories that have minimum required quantities and / or maximum quantity limits.  `;
    _stub += this.issuanceProgramViolationRuleStub();
    return _stub
  }

  getIssuance(programIssuance: ProgramIssuance) {
    let _stub = "";
    _stub += this.issuanceInfoStub(programIssuance); 
    return _stub;
  }

  allowanceApplyShippingStub(programAllowance: ProgramAllowance) {
    let _stub = '';
    if (
      programAllowance.isFreightChargedToAllowance &&
      programAllowance.estimatedFreightChargeRate &&
      programAllowance.estimatedFreightChargeRate > 0
    ) {
      _stub += `Order shipping costs are charged against the employee's allowance at a rate of ${
        programAllowance.estimatedFreightChargeRate * 100
      }% of the total order value.  `;
    } else {
      _stub += `Order shipping costs are not charged to the employee's allowance.  `;
    }
    return _stub;
  }

  allowOverAllowancePurchasesStub(programAllowance: ProgramAllowance) {
    let _stub = '';
    if (programAllowance.allowOverAllowancePurchases) {
      _stub += `Employees may, at their discretion, place orders that exceed available allowance amount, however Proximity will charge the employee directly for the difference.  `;
    } else {
      _stub += `This Allowance Plan does not allow employees at ${this.account.name} to make personal payments for orders that exceed the available allowance amount.  `;
    }
    return _stub;
  }

  allowanceProgramViolationRuleStub(): string {
    let _stub = '';
    if (
      this.program.allowanceProgramViolationRuleId ===
      ProgramViolationRuleEnum.Stop
    ) {
      _stub =
        'Orders that exceed allowance limits may not be submitted for fulfillment.  ';
    }

    if (
      this.program.allowanceProgramViolationRuleId ===
      ProgramViolationRuleEnum.Warn
    ) {
      if (this.program.doAllOrdersRequireApproval) {
        _stub = `All orders for this program, even those without allowance limit violations, must be submitted for approval.  If a submitted order exceeds the allowance limit, the ${this.account.name} Approving Administrator will be notified accordingly.  `;
      } else {
        _stub = `Orders that exceed allowance limits may be submitted to the assigned ${this.account.name} Administrator for approval.  `;
      }
    }
    return _stub;
  }

  issuanceProgramViolationRuleStub(): string {
    let _stub = '';
    if (
      this.program.issuanceProgramViolationRuleId ===
      ProgramViolationRuleEnum.Stop
    ) {
      _stub =
        'Orders that exceed issuance limits may not be submitted for fulfillment.  ';
    }

    if (
      this.program.issuanceProgramViolationRuleId ===
      ProgramViolationRuleEnum.Warn
    ) {
      if (this.program.doAllOrdersRequireApproval) {
        _stub = `All orders for this program, even those without issuance limit violations, must be submitted for approval.  If a submitted order exceeds issuance limits, the ${this.account.name} Approving Administrator will be notified accordingly.  `;
      } else {
        _stub = `Orders that exceed issuance limits may be submitted to the assigned ${this.account.name} Administrator for approval.  `;
      }
    }
    return _stub;
  }

  
  programAllowanceRemainderRuleStub() {
    let _stub = '';
    if (
      this.program.programAllowanceRemainderRuleId ===
      ProgramAllowanceRemainderRuleEnum.RemainingAllowancesLost
    ) {
      _stub = `Employee allowances that are not used when the program term expires will not be carried over into the next allowance program.  `;
    }

    if (
      this.program.programAllowanceRemainderRuleId ===
      ProgramAllowanceRemainderRuleEnum.RemainingAllowanceAddedToNewAllowances
    ) {
      _stub = `Employee Allowances that are not used when the program term expires will be added to each employee's allowance in the next allowance program.  `;
    }
    return _stub;
  }

  private issuanceInfoStub(programIssuance: ProgramIssuance): string {
    let _stub = '';
    _stub += `Issuance Plan: <b>${programIssuance.name}</b>, `;
    if (programIssuance.minimumQty && programIssuance.minimumQty > 0) {
      _stub += ` requires a minimum of ${programIssuance.minimumQty} units `;
      if (programIssuance.maximumQty && programIssuance.maximumQty > 0) {
        _stub += `and `;
      }
    }

    if (programIssuance.maximumQty && programIssuance.maximumQty > 0) {
      _stub += `allows a maximum of ${programIssuance.maximumQty} units `;
    }

    _stub += `of catalog products that are tagged as: <b>${programIssuance.programProductTypeTag?.name}</b>.`;
    return _stub;
  }
}
