import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { accountRoutes } from './account.routes';

import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';

import { AccountEntityManagerProvider, AccountUnitOfWork } from '@data';

import { AccountAdminFrmComponent } from './account-administrator/account-administrator-frm.component';
import { AccountAdminListComponent } from './account-administrator/account-administrator-list.component';

import { AccountFrmComponent } from './account-frm.component';
import { AccountListComponent } from './account-list.component';
import { AccountManagerComponent } from './account-manager.component';

import { AccountUserFinderDialog } from './account-users/account-user-finder.dialog';
import { AccountUserFrmComponent } from './account-users/account-user-frm.component';
import { AccountUserListComponent } from './account-users/account-user-list.component';
import { ProgramUserGroupsComponent } from './account-users/program-user-groups.component';
import { ProgramFrmComponent } from './programs/program-frm.component';
import { ProgramListComponent } from './programs/program-list.component';

import { ProgramUserGroupFinderDialog } from './account-users/program-user-group-finder.dialog';
import { DashboardFrmComponent } from './dashboard/dashboard-frm.component';
import { ProductTypeConfigFinderDialog } from './programs/product-type-config.finder.dialog';
import { AccountDbQueryService } from './services/account-db-query.service';
import { AccountDbSaveService } from './services/account-db-save.service';

import { AccountAddressFrmComponent } from './addresses/account-address-frm.component';
import { BudgetItemsListComponent } from './budgets/budget-items-list.component';
import { PriceListsComponent } from './price-lists/price-lists.component';

import {
  BreadcrumbService,
  CoreModule,
  ProximityWizardModule,
  TemplatesService,
} from '@core';
import { AccountAdminFinderDialog } from './account-administrator/account-admin-finder.dialog';
import { AccountAdminGroupFinderDialog } from './account-administrator/account-admin-group-finder.dialog';
import { AccountAdminGroupsComponent } from './account-administrator/account-admin-groups.component';
import { ShippingUserGroupFinderDialog } from './account-users/shipping-user-group-finder.dialog';
import { ShippingUserGroupsComponent } from './account-users/shipping-user-groups.component';
import { ActionFrmComponent } from './actions/action-frm.component';
import { ActionListComponent } from './actions/action-list.component';
import { AccountAddressFinderDialog } from './addresses/account-address-finder.dialog';
import { BillingAccountAddressesComponent } from './addresses/billing-account-addresses.component';
import { ShippingAccountAddressesComponent } from './addresses/shipping-account-addresses.component';
import { ApiSendStatusComponent } from './api-send-status.component';
import { ApprovalTreesComponent } from './approval-trees/approval-trees.component';
import { AccountBudgetsComponent } from './budgets/account-budgets.component';
import { ImportUsersComponent } from './import-users/import-users.component';
import { AccountNotificationSettingsComponent } from './notifiications/account-notification-settings.component';
import { AccountNotificationTemplateListComponent } from './notifiications/account-notification-template-list.component';
import { NotificationTemplateDialogComponent } from './notifiications/notification-template-dialog.component';
import { TemplatePreviewDialogComponent } from './notifiications/template-preview-dialog.component';
import { PaymentDialogComponent, PaymentProfileDialogComponent, PaymentsComponent, PaymentSelectDialogComponent, PaymentService, PaymentViewDialogComponent } from './payments';
import { BudgetProductTypeTagsComponent } from './product-type-tags/budget-product-type-tags.component';
import { ProgramProductTypeTagsComponent } from './product-type-tags/program-product-type-tags.component.';
import { ProgramAllowancesSubComponent } from './programs/program-allowances.sub.component';
import { ProgramBillingSubComponent } from './programs/program-billing.sub.component';
import { ProgramBudgetsSubComponent } from './programs/program-budgets.sub.component';
import { ProgramChangeStatusFrmComponent } from './programs/program-change-status-frm.component';
import { ProgramInfoSubComponent } from './programs/program-info.sub.component';
import { ProgramIssuancesSubComponent } from './programs/program-issuances.sub.component';
import { ProgramNarrativeComponent } from './programs/program-narrative.component';
import { ProgramProductTypeConfigsSubComponent } from './programs/program-product-type-configs.sub.component';
import { ProgramProductTypePickerDialogComponent } from './programs/program-product-type-picker.dialog.component';
import { ProgramProductTypeTagFinderDialog } from './programs/program-product-type-tag-finder.dialog';
import { ProgramProductTypeTagsSubComponent } from './programs/program-product-type-tags.sub.component';
import { ProgramRapidTemplatesSubComponent } from './programs/program-rapid-templates.sub.component';
import { ProgramSuppliersSubComponent } from './programs/program-suppliers.sub.component';
import { ProgramUserGroupsSubComponent } from './programs/program-user-groups.sub.component';
import { ProgramViewsSubComponent } from './programs/program-views.sub.component';
import { AccountBlanketPurchaseOrdersComponent } from './purchase-orders/account-blanket-purchase-orders.component';
import { AccountProcurementCardsComponent } from './purchase-orders/account-procurement-cards.component';
import { AccountImageHandlerComponent } from './services/account-image-handler.component';
import { ProgramStatusService } from './services/program-status.service';
import { TestComponent } from './test.component';
import { ProgramRulesService } from './services/program-rules.service';
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    AgGridModule,
    ProximityWizardModule,
    TabViewModule,
    FileUploadModule,
    RouterModule.forChild(accountRoutes),
  ],
  declarations: [
    AccountAddressFrmComponent,
    AccountAddressFinderDialog,
    AccountBudgetsComponent,
    AccountListComponent,
    AccountFrmComponent,
    AccountManagerComponent,
    AccountAdminListComponent,
    AccountAdminGroupsComponent,
    AccountAdminFrmComponent,
    AccountAdminFinderDialog,
    AccountAdminGroupFinderDialog,
    AccountUserListComponent,
    AccountUserFrmComponent,
    AccountUserFinderDialog,
    ProgramProductTypeTagsComponent,
    BudgetProductTypeTagsComponent,
    ProgramUserGroupsComponent,
    ProgramUserGroupFinderDialog,
    ShippingUserGroupsComponent,
    ShippingUserGroupFinderDialog,
    ShippingAccountAddressesComponent,
    BillingAccountAddressesComponent,
    ProgramProductTypeTagFinderDialog,
    ApprovalTreesComponent,
    PriceListsComponent,
    BudgetItemsListComponent,
    AccountBlanketPurchaseOrdersComponent,
    AccountProcurementCardsComponent,
    AccountImageHandlerComponent,

    PaymentsComponent,
    PaymentDialogComponent,
    PaymentProfileDialogComponent,
    PaymentSelectDialogComponent,
    PaymentViewDialogComponent,

    ProgramListComponent,

    ProgramFrmComponent,
    ProgramInfoSubComponent,
    ProgramUserGroupsSubComponent,
    ProgramProductTypeConfigsSubComponent,
    ProgramProductTypeTagsSubComponent,
    ProgramViewsSubComponent,
    ProgramIssuancesSubComponent,
    ProgramAllowancesSubComponent,
    ProgramRapidTemplatesSubComponent,
    ProgramBudgetsSubComponent,
    ProgramBillingSubComponent,
    ProgramSuppliersSubComponent,

    ProductTypeConfigFinderDialog,
    DashboardFrmComponent,

    ActionListComponent,
    ActionFrmComponent,
    ProgramChangeStatusFrmComponent,
    ImportUsersComponent,
    AccountNotificationTemplateListComponent,
    AccountNotificationSettingsComponent,
    NotificationTemplateDialogComponent,
    TemplatePreviewDialogComponent,
    ProgramNarrativeComponent,

    ApiSendStatusComponent,
    TestComponent,
    ProgramProductTypePickerDialogComponent,
  ],
  exports: [
    PaymentsComponent,
    PaymentDialogComponent,
    PaymentProfileDialogComponent,
    PaymentSelectDialogComponent,
    PaymentViewDialogComponent,
  ],
  providers: [
    DialogService,
    ConfirmationService,
    BreadcrumbService,
    AccountDbQueryService,
    AccountDbSaveService,
    AccountEntityManagerProvider,
    AccountUnitOfWork,
    MessageService,
    TemplatesService,
    ProgramStatusService,
    PaymentService,
    ProgramRulesService
  ],
})
export class AccountModule {
  constructor() {
    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      InfiniteRowModelModule,
      ServerSideRowModelModule,
      MasterDetailModule,
      SetFilterModule,
      RichSelectModule,
      RangeSelectionModule,
      RowGroupingModule,
      MenuModule,
    ]);
  }
}
