// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from '../base-entity';
import { AccountAdmin } from './account-admin';
import { AccountUser } from './account-user';
import { JobOrder } from './job-order';
import { ProgramAllowance } from './program-allowance';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

/// <module-code> Place module level code between <module-code> tags

/// </module-code>

export class ProgramAllowanceUserLog extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  /** Guid key */
  id!: string;
  /** Guid FK accountAdmin */
  accountAdminId?: string;
  /** Guid FK accountUser */
  accountUserId!: string;
  /** String */
  adjustmentNote?: string;
  /** Decimal */
  amt!: number;
  /** Guid FK jobOrder */
  jobOrderId?: string;
  /** DateTimeOffset */
  logTs!: Date;
  /** Guid FK programAllowance */
  programAllowanceId!: string;
  /** FK accountAdminId */
  accountAdmin!: AccountAdmin;
  /** FK accountUserId */
  accountUser!: AccountUser;
  /** FK jobOrderId */
  jobOrder!: JobOrder;
  /** FK programAllowanceId */
  programAllowance!: ProgramAllowance;
}

