
import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AgFns, ISortModel, ProductFns, ProxAgFns, UtilDialogService } from '@core';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgramFrmComponent } from './program-frm.component';
import { ProductTypeConfigAddon, ProductTypeConfigProduct, ProgramProductTypeConfig, ProgramProductTypeTag, ProgramProductTypeTagMap } from '@models';
import { ProductTypeConfigFinderDialog } from './product-type-config.finder.dialog';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { ToastrService } from 'ngx-toastr';
import { EntityFns } from '@data';
import * as _ from 'lodash';
import { ProgramProductTypePickerDialogComponent } from './program-product-type-picker.dialog.component';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'prox-program-product-type-configs-sub',
  templateUrl: './program-product-type-configs.sub.component.html',
})
export class ProgramProductTypeConfigsSubComponent implements OnInit, OnChanges {
  @Input() visible!: boolean;
  @Input() parent!: ProgramFrmComponent;
  
  pptcGridOptions: GridOptions<ProgramProductTypeConfig> = {};
  allPptcs: ProgramProductTypeConfig[] = [];
  pptcs: ProgramProductTypeConfig[] = [];
  selectedPptc?: ProgramProductTypeConfig;
  ptcpGridOptions: GridOptions<ProductTypeConfigProduct> = {};
  ptcps: ProductTypeConfigProduct[] = [];
  ptcaGridOptions: GridOptions<ProductTypeConfigAddon> = {};
  ptcas: ProductTypeConfigAddon[] = [];
  selectCount = 0;

  shouldShowOnlyProductsWithNoTags = false;

  constructor(
    public dbQueryService: AccountDbQueryService,
    public dbSaveService: AccountDbSaveService,
    public toastr: ToastrService,
    public dialogService: UtilDialogService,
    public pngDialogService: DialogService) {
    //
  }

  refreshPptcs() {
    if (this.shouldShowOnlyProductsWithNoTags) {
      this.pptcs =  this.allPptcs.filter(x => x.productTypeConfig.pricedProductType.productTags == '');
    } else {
      this.pptcs =  this.allPptcs;
    }
  }

  get program() {
    return this.parent.program;
  }

/*   pptcRowSelection = {
    mode: 'multiRow',
    checkboxes: true,
    headerCheckbox: false,
  }; */

  ngOnInit() {
    this.pptcGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPptcGridReady,
        onRowSelected: this.onPptcRowSelected,
        rowSelection: 'multiple',
        rowModelType: 'clientSide',
        // params.data is ProgramProductTypeConfig
        getRowId: (params) =>
          (params.data as ProgramProductTypeConfig).productTypeConfigId,
      },
    );

    this.ptcpGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPtcpGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        getRowId: (params) =>
          (params.data as ProductTypeConfigProduct).productId,
      },
    );

    this.ptcaGridOptions = AgFns.initGridOptions(
      this,
      {
        onGridReady: this.onPtcaGridReady,
        rowSelection: 'single',
        rowModelType: 'clientSide',
        getRowId: (params) =>
          (params.data as ProductTypeConfigAddon).pricedAddonId,
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  async refresh(forceRefresh = false) {
    if (!this.visible && !forceRefresh) return;
    this.parent.updateComponentNameMap(this);

    this.allPptcs = this.program.programProductTypeConfigs.slice();
    this.refreshPptcs();
    AgFns.refreshGrid(this.pptcGridOptions, this.pptcs);
  }

  onPptcGridReady() {
    // ProgramProductTypeConfig
    const prodTypePath = 'productTypeConfig.pricedProductType.productType';
    const colDefs = [
      {
        headerName: 'Supplier',
        field: prodTypePath + '.supplier.name',
        filter: 'agTextColumnFilter',
        // checkboxSelection: true,
      },
      {
        headerName: 'Manufacturer',
        field: prodTypePath + '.manufacturer.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SKU',
        field: prodTypePath + '.productKey',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Name',
        field: prodTypePath + '.name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Product Group',
        field: 'productTypeConfig.name',
        filter: 'agTextColumnFilter',
      },
      { ...AgFns.createButtonProps('Program Product Tags', this.onTags.bind(this), { calcLabel: this.calcTagsLabel.bind(this), calcDisabled: () => this.parent.isReadOnly() }), minWidth: 250 },
/*       {
        headerName: 'Product Tags',
        field: 'productTypeConfig.pricedProductType.productTags'
      }, */
      {
        headerName: 'Base Price',
        field: 'productTypeConfig.pricedProductType.basePrice',
        width: 100
      },
      {
        headerName: 'Reissue Price',
        field: 'productTypeConfig.pricedProductType.reissuancePrice',
        width: 100
      },
      { ...AgFns.createCheckboxProps("Discontinued?", 'isDiscontinued', 100), editable: true},
      this.parent.ifEditable(ProxAgFns.getEntityDeleteColDef(this.onPptcDelete.bind(this), { calcDisabled: () => this.parent.isReadOnly() })),
    ];
    const sortModel: ISortModel = [
      { colId: prodTypePath + '.supplier.name', sort: 'asc' },
      { colId: prodTypePath + '.manufacturer.name', sort: 'asc' },
      { colId: prodTypePath + '.name', sort: 'asc' },
    ];
    // this.updatePtcMasterDetail(this.pptcGridOptions);
    AgFns.initGrid(this.pptcGridOptions, colDefs, sortModel);
  }

  async onTags(pptc: ProgramProductTypeConfig) {
    await this.dialogService.createFinder(ProgramProductTypePickerDialogComponent, {
      tags: this.parent.pptts, parents: [pptc.productTypeConfig.pricedProductType], rowSelection: 'multiple',
    });
  }

  async onMultiTags() {
    const selectedNodes = this.pptcGridOptions.api?.getSelectedNodes();
    if (!selectedNodes || !selectedNodes.length) { return; }

    const pricedProductTypes = selectedNodes.map(x => x.data?.productTypeConfig.pricedProductType).filter(x => !!x) as any[];

    await this.dialogService.createFinder(ProgramProductTypePickerDialogComponent, {
      tags: this.parent.pptts, parents: pricedProductTypes, rowSelection: 'multiple',
    });
  }

  calcTagsLabel(pptc: ProgramProductTypeConfig ) {
    // This can happen during deletion. 
    if (pptc.entityAspect.entityState.isDeleted()) {
      return '';
    }
    
    return (pptc.productTypeConfig.pricedProductType.productTags == '' ? ' - NONE - ' : pptc.productTypeConfig.pricedProductType.productTags);
    
  }

/*   valueFormatter (params: ValueFormatterParams) {
    const { value } = params;
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };

  valueParser (params: ValueParserParams) {
    const { newValue } = params;
    if (newValue == null || newValue === "") {
      return null;
    }
    return params.newValue.split(",");
  }; */

  onPtcpGridReady() {
    // ProductTypeConfigProducts
    const colDefs =  [
      // { headerName: 'SKU', field: 'product.productKey', filter: 'agSetColumnFilter', maxWidth: 200 },
       ProductFns.getAllFeaturesColDef( x => {
        return x.data.product;
       }),
       {
          headerName: "Add to Base Price", 
          colId: 'ttlFeature.price', 
          editable: false,
          sortable: false,
          valueGetter: (params) => {
              return ProductFns.getProductTypeConfigPriceInfo(params.data).totalFeaturesPrice;
          }
        }
    ];
    const sortModel: ISortModel = [
      { colId: 'productKey', sort: 'asc' }
    ];
    AgFns.initGrid(this.ptcpGridOptions, colDefs, sortModel);
  }

  onPtcaGridReady() {
    // ProductTypeConfigAddons
    const colDefs =  [
      { headerName: 'Addon', field: 'pricedAddon.name',  },
      { headerName: 'Add to Base Price', field: 'pricedAddon.price',  },
    ];
    const sortModel: ISortModel = [
      { colId: 'pricedAddon.name', sort: 'asc' }
    ];
    AgFns.initGrid(this.ptcaGridOptions, colDefs, sortModel);
  }

  async onPptcRowSelected(event: RowSelectedEvent) {
    this.selectCount = <number>this.pptcGridOptions.api?.getSelectedRows().length;
    if (!event.node.isSelected()) return;
    const pptc = event.data as ProgramProductTypeConfig;
    this.selectedPptc = pptc;
    if (pptc == null) return; 

    // get features and add-ons for the selected config
    const ptc = await this.dbQueryService.getProductTypeConfig(pptc.productTypeConfigId);

    this.ptcps = ptc?.productTypeConfigProducts ?? []
    this.ptcas = ptc?.productTypeConfigAddons ?? []
  }

  /// ------------------------------------------------------------------

  async onPptcAdd() {
    if (this.allPptcs == null) return;
    const ptcSet = new Set(
      this.allPptcs.map((pg) => pg.productTypeConfig)
    );
    const foundPtcs = await this.dialogService.createFinder(
      ProductTypeConfigFinderDialog,
      {
        header: 'Select Products',
        accountId: this.program.accountId,
        rowSelection: 'multiple',
      }
    );
    const newPtcs = foundPtcs.filter((x) => !ptcSet.has(x));
    if (newPtcs.length != foundPtcs.length) {
      this.toastr.warning(
        'Some Products were not added because they have already been added previously.',
        'Already added'
      );
    }

    const newPptcs = newPtcs.map((x) => {
      return this.dbSaveService.createEntity(ProgramProductTypeConfig, {
        programId: this.program.id,
        productTypeConfigId: x.id,
      });
    });
    this.allPptcs?.push(...newPptcs);
    AgFns.refreshGrid(this.pptcGridOptions, this.pptcs);
    // anytime the productTypeConfigs change the paFeatures change - but they don't change any other time
    // this.parent.calcPaFeatures();
  }

  async onPptcDelete(pptc: ProgramProductTypeConfig) {
    if (!this.allPptcs) return;
    // TODO: need to detach all of this ProductTypeConfigs tied ProgramViews, ProgramRapidTemplates

    // detach all of this ProductTypeConfig tied to ProgramViews, ProgramRapidTemplates
    const promises = [
       this.dbQueryService.getProgramViews(this.program.id),
       this.dbQueryService.getRapidOrders(this.program.id),
    ];
    await Promise.all(promises);
    
    this.program.entityAspect.markNavigationPropertyAsLoaded('programViews');
    this.program.entityAspect.markNavigationPropertyAsLoaded('programRapidTemplates');

    const ptcId = pptc.productTypeConfigId;

    const pvMaps = _.flatMap(this.program.programViews, 
      pv => pv.programViewProductTypeConfigs.filter(x => x.productTypeConfigId == ptcId )
    );
    const prtMaps = _.flatMap(this.program.programRapidTemplates, 
      pr => pr.programRapidTemplateProductTypeConfigs.filter(x => x.productTypeConfigId == ptcId)
    );
    [ ...pvMaps, ...prtMaps].forEach(map => {
      EntityFns.deleteOrDetach(map.entityAspect);
    })
    EntityFns.deleteOrDetach(pptc.entityAspect);
    _.remove(this.allPptcs, pptc);
    const x = this.pptcs.filter(x => x.productTypeConfig.pricedProductType == null);
    AgFns.refreshGrid(this.pptcGridOptions, this.pptcs); 
  }


}
