/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColDef,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  IRichCellEditorParams,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AgFns,
  ISortModel,
  ProxAgFns,
  SaveAndQueryComponent,
  StatusChangeDialog,
  StatusService,
} from '@core';
import { BaseService } from '@core';
import { DateFns, UtilFns } from '@utils';
import * as _ from 'lodash-es';
import { EntityFns } from '@data';
import { EntityError } from 'breeze-client';
import {
  ProgramUserGroup,
  ActiveStatus,
  ActiveStatusEnum,
  ApprovalTreeUserGroup,
  ProgramUserGroupBudget,
  Account,
  JobOrderBudgetLog,
  ProgramUserGroupMap,
  AccountBlanketPurchaseOrder,
  PurchaseOrder,
  AccountProcurementCard,
} from '@models';
import { AccountDbSaveService } from '../services/account-db-save.service';
import { AccountDbQueryService } from '../services/account-db-query.service';
import { AccountUserFinderDialog } from './account-user-finder.dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { BudgetItemsListComponent } from '../budgets/budget-items-list.component';

@Component({
  selector: 'prox-program-user-groups',
  templateUrl: './program-user-groups.component.html',
})
export class ProgramUserGroupsComponent extends SaveAndQueryComponent {
  @ViewChild(BudgetItemsListComponent)
  budgetListItemsComponent!: BudgetItemsListComponent;
  supplierId!: string;
  accountId!: string;
  account!: Account;
  tabViewIndex = 0;
  activeStatuses!: ActiveStatus[];

  allPugs!: ProgramUserGroup[];
  pugGridOptions!: GridOptions;
  pugs: ProgramUserGroup[] = [];
  selectedPug?: ProgramUserGroup;

  pugmGridOptions!: GridOptions;
  pugms: ProgramUserGroupMap[] = [];

  programUserGroupBudgets: ProgramUserGroupBudget[] = [];
  joBudgetLogs: JobOrderBudgetLog[] = [];
  purchaseOrders: PurchaseOrder[] = [];
  pcards: AccountProcurementCard[] = [];

  languages = ["English", "Spanish", "French", "Portuguese", "(other)"];

  constructor(
    baseService: BaseService,
    route: ActivatedRoute,
    override dbSaveService: AccountDbSaveService,
    override dbQueryService: AccountDbQueryService,
    public pngDialogService: DialogService,
    private statusService: StatusService
  ) {
    super(baseService, route, dbSaveService, dbQueryService);
  }

  override async updateFromParams(params: object): Promise<void> {
    this.accountId = params['accountId'];
    UtilFns.assertNonEmptyString(this.accountId, 'accountId');

    const [r0, r1, r2, r3, r4] = await Promise.all([
      this.dbQueryService.getAccountById(this.accountId),
      this.dbQueryService.getAll(ActiveStatus),
      this.dbQueryService.getAccountBlanketPurchaseOrders(this.accountId),
      this.dbQueryService.getAccountProcurementCards(this.accountId),
      this.dbQueryService.getProgramUserGroupsWithBudgets(this.accountId),
    ]);
    this.account = r0;
    UtilFns.assertNonNull(this.account, 'Account');
    this.activeStatuses = r1;
    const po = r2;
    po.forEach(p => {
      this.purchaseOrders.push(new PurchaseOrder (p.id, p.purchaseOrder));
    })

    this.purchaseOrders.unshift({ id: null, purchaseOrder: '- None -', } as PurchaseOrder);

    this.pcards = r3;
    this.pcards.unshift({ id: null, namedesc: '- None -', } as any as AccountProcurementCard);

    this.pugGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPugGridReady,
      onRowSelected: this.onPugRowSelected,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const pug = rowIdParams.data as ProgramUserGroup;
        return pug.id;
      },
    });

    AgFns.captureGridRouteParams(this.pugGridOptions, this.route, 'id');
    this.pugmGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onPugmGridReady,
      rowModelType: 'clientSide',
      getRowId: (rowIdParams: GetRowIdParams) => {
        const pugm = rowIdParams.data as ProgramUserGroupMap;
        return pugm.accountUserId;
      },
    });

    this.allPugs = r4;
    this.setTitle("Program User Groups for " + this.account.name);

    this.isPageReady = true;
  }

  isReadOnly() {
    return this.statusService.getWorkingStatus(this.account as any).isReadOnly;
  }

  canDelete() {
    return !this.isReadOnly();
  }

  statusMessage() {
    return this.statusService.getWorkingStatus(this.account as any).statusDisplay;
  }

  // Aug Grid

  onPugGridReady(event: GridReadyEvent) {
    const gridOptions = event.context.gridOptions as GridOptions;
    const [colDefs, sortModel] = this.getPugColDefsAndSortModel();
    this.updatePugMasterDetail(gridOptions);
    AgFns.initGrid(gridOptions, colDefs, sortModel);
    this.refreshPugGrid();
    AgFns.applyGridRouteParams(this.pugGridOptions);
  }

  getPugColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Program User Group Name',
        field: 'name',
        editable: !this.isReadOnly(),
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Purchase Order',
        editable: !this.isReadOnly(),
        width: 200,
        ...AgFns.createRichDropdownEditor(
          'accountBlanketPurchaseOrderId',
          this.purchaseOrders,
          this.onChangeSelectPurchaseOrder.bind(this),
          'id',
          'purchaseOrder'
        ),
      },
      {
        headerName: 'Procurement Card',
        editable: !this.isReadOnly(),
        width: 200,
        ...AgFns.createRichDropdownEditor(
          'accountProcurementCardId',
          this.pcards,
          this.onChangeSelectPCard.bind(this),
          'id',
          'namedesc'
        ),
      },
/*       {
        headerName: 'Program User Group Purchase Order',
        editable: !this.isReadOnly(),
        width: 200,
        ...AgFns.createDropdownEditor(
          'accountBlanketPurchaseOrderId',
          this.purchaseOrders,
          this.onChangeSelectPurchaseOrder.bind(this),
          'id',
          'purchaseOrder'
        ),
      }, */
      ProxAgFns.getWorkingStatusDef('Status', this.statusService),
      ProxAgFns.getEntityStatusColDef(this.onChangeStatus.bind(this)),
      ProxAgFns.getEntityDeleteColDef(this.onPugDelete.bind(this), { canDisplay: this.canDelete.bind(this)}),
    ];
    const sortModel: ISortModel = [{ colId: 'name', sort: 'asc' }];
    return [colDefs, sortModel] as const;
  }

  async onChangeSelectPurchaseOrder() {}

  async onChangeSelectPCard() {}

  async onChangeStatus(row: ProgramUserGroup) {
    if (this.dbSaveService.hasChanges()){
      this.toastr.warning("Save or undo all changes before changing status", 'Unable to Proceed');
      return;
    }
  
    const errs: string[] = [];
    const selectedStatusId = await StatusChangeDialog.open(
      this.pngDialogService,
      {
        statuses: this.activeStatuses,
        isAvailable: () => true,
        currentStatus: row.activeStatus,
      },
      {
        header: 'Program User Group Status',
      }
    );

    if (selectedStatusId == row.activeStatusId) {
      return;
    }

    if (selectedStatusId != null) {
      if (!(row as ProgramUserGroup).entityAspect.validateEntity()) {
        errs.push('Program User Group failed validation test.  Fix Program User Group before changing status.');
      }
    }

    if (errs.length > 0) {
      this.dialogService.statusErrorDialog(errs);
      return;
    }

    if (selectedStatusId != null) {
      row.activeStatusId = selectedStatusId;
      this.dialogService.verifyProceedDialog(this, this.statusService.getStatusExplanation(selectedStatusId, 'Program User Group'), 'Program User Group Status Change');
    }
  }

  async proceedDialog() {
    await this.dbSaveService.saveChanges();
    this.toastr.success('Status change updated', 'Database Activity');
    this.pugGridOptions.api?.refreshCells();
  }

  cancelDialog() {
    this.dbSaveService.rejectChanges();
    this.pugGridOptions.api?.refreshCells();
  }

  updatePugMasterDetail(parentGridOptions: GridOptions) {
    parentGridOptions.masterDetail = true;
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      {
        field: 'accountUser.proximityUser.lastName',
        headerName: 'Last Name',
        editable: false,
        filter: 'agTextColumnFilter',
      },
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      refreshStrategy: 'everything',
      getDetailRowData: (params) => {
        const pug = params.data as ProgramUserGroup;
        params.successCallback(pug.programUserGroupMaps.map(x => x.accountUser));
      },
    };
  }

  async onPugRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) return;
    const pug = event.data as ProgramUserGroup;
    if (pug == null) return;
    this.selectedPug = pug;
    if (pug.entityAspect.entityState.isDeleted()) {
      this.selectedPug = undefined;
      this.pugGridOptions.api?.deselectAll();
      return;
    }

    this.updateGridRouteParams(this.pugGridOptions, pug.id);

    await this.dbQueryService.getProgramUserGroupMaps(pug.id);
    // we want to pick up all added (but not yet saved) records here. 
    this.pugms = pug.programUserGroupMaps;
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
    this.programUserGroupBudgets = pug.programUserGroupBudgets;

    this.joBudgetLogs =
      await this.dbQueryService.getJobOrderBudgetLogsForProgramUserGroup(
        pug.id,
        this.account.getCurrentYearFiscalStartDate()
      );
    // this.budgetListItemsComponent.refreshBudgets(this.programUserGroupBudgets);

    this.budgetListItemsComponent.refreshGrid();
  }

  async onPugAdd() {
    const newAug = this.dbSaveService.createEntity(ProgramUserGroup, {
      accountId: this.accountId,
      activeStatusId: ActiveStatusEnum.Hold,
    });
    newAug.entityAspect.validateEntity();
    this.allPugs.push(newAug);
    this.refreshPugGrid();
    const gridApi = this.pugGridOptions.api;
    if (!gridApi) return;
    const rowIndex = AgFns.getRowIndex(gridApi, newAug.id);
    this.pugGridOptions.api?.setFocusedCell(rowIndex ?? 0, 'name');
  }

  async onPugDelete(pug: ProgramUserGroup) {
    const inUse = await this.dbQueryService.checkIfInUse(
      pug,
      ApprovalTreeUserGroup,
      'programUserGroupId'
    );
    if (inUse) {
      this.toastr.warning(
        `You cannot delete an Program User Group that is in use.`,
        'Cannot delete'
      );
      return;
    }

    pug.programUserGroupMaps.forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    pug.programUserGroupExclusions.forEach(x => EntityFns.deleteOrDetach(x.entityAspect));
    EntityFns.deleteOrDetach(pug.entityAspect);
    this.selectedPug = undefined;
    this.pugms = [];
    if (this.allPugs) {
      _.remove(this.allPugs, pug);
      this.refreshPugGrid();
    }
  }

  async refreshPugGrid(fullRefresh: boolean = false) {
    this.pugs = this.allPugs.slice();
    if (this.selectedPug) {
      this.selectedPug = this.pugs.includes(this.selectedPug)
        ? this.selectedPug
        : undefined;
    }
    AgFns.refreshGrid(this.pugGridOptions, this.pugs || []);
    if (fullRefresh) {
      AgFns.refreshGrid(this.pugGridOptions, this.pugs || []);
      if (this.selectedPug) {
        AgFns.selectGridRowByKey(
          this.pugGridOptions,
          (e) => e.id,
          this.selectedPug.id
        );
      } else if (this.pugs.length > 0) {
        this.selectedPug = await AgFns.selectFirstRow(this.pugGridOptions);
      }
    } else {
      if (this.selectedPug) {
        this.selectedPug = this.pugs.includes(this.selectedPug)
          ? this.selectedPug
          : undefined;
      }
    }

    if (this.selectedPug == null) {
      this.pugms = [];
      this.programUserGroupBudgets = [];
    } else {
      this.pugms = this.selectedPug.programUserGroupMaps;
      this.programUserGroupBudgets = this.selectedPug.programUserGroupBudgets;
    }
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);

    this.budgetListItemsComponent.refreshGrid();
  }

  // User Grid

  onPugmGridReady(event: GridReadyEvent) {
    const [colDefs, sortModel] = this.getPugmColDefsAndSortModel();
    AgFns.initGrid(event.context.gridOptions, colDefs, sortModel);
  }

  getPugmColDefsAndSortModel() {
    const colDefs: ColDef[] = [
      ProxAgFns.getEntityStateColDef(),
      {
        headerName: 'Last Name',
        field: 'accountUser.proximityUser.lastName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'First Name',
        field: 'accountUser.proximityUser.firstName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Middle Name',
        field: 'accountUser.proximityUser.middleName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Email Address',
        field: 'accountUser.proximityUser.email',
        filter: 'agTextColumnFilter',
      },
      ProxAgFns.getEntityDeleteColDef(this.onPugmDelete.bind(this), { canDisplay: this.canDelete.bind(this)})
    ];

    const sortModel: ISortModel = [
      { colId: 'accountUser.proximityUser.lastName', sort: 'asc' },
      { colId: 'accountUser.proximityUser.firstName', sort: 'asc' },
    ];
    return [colDefs, sortModel] as const;
  }

  async onPugmAdd() {
    const newPugms = await this.attachUsers();
    if (newPugms.length == 0) return;
    this.pugms.push(...newPugms);
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
    AgFns.updateMasterDetail(this.pugGridOptions, this.selectedPug);
  }

  async attachUsers() {
    if (this.selectedPug == null) return [];
    const pug = this.selectedPug;

    const allAccountUsers = await this.dbQueryService.getAccountUsers(
      this.accountId
    );

    const currentAccountUserIds = new Set(
      this.selectedPug.programUserGroupMaps.map(y => y.accountUserId)
    );
    const remainingAccountUsers = allAccountUsers.filter(
      (au) => !currentAccountUserIds.has(au.id)
    );
    const newAccountUsers = await this.dialogService.createFinder(
      AccountUserFinderDialog,
      {
        accountUsers: remainingAccountUsers,
        rowSelection: 'multiple',
      }
    );
    if (newAccountUsers.length == 0) return [];
    const newPugms = newAccountUsers.map((accountUser) => {
      let pugm = this.dbSaveService.uow.undoIfDeleted(ProgramUserGroupMap, [ pug.id, accountUser.id]);
      if (pugm == null) {
        pugm = this.dbSaveService.createEntity(ProgramUserGroupMap, {
          programUserGroupId: pug.id,
          accountUserId: accountUser.id
        });
      }
      return pugm;
    });
    return newPugms;
  }

  async onPugmDelete(pugm: ProgramUserGroupMap) {
    if (!this.selectedPug) return;
    // TODO: ??? why is this next line necessary
    this.addEntitiesToValidate(pugm);
    EntityFns.deleteOrDetach(pugm.entityAspect);
    _.remove(this.pugms, pugm);
    AgFns.refreshGrid(this.pugmGridOptions, this.pugms);
  }

  // UgBudget grid

  get pugBudgetGridOptions() {
    return this.budgetListItemsComponent?.budgetGridOptions;
  }

  createPugBudget() {
    const ugb = this.dbSaveService.createEntity(ProgramUserGroupBudget, {
      accountId: this.accountId,
      programUserGroupId: this.selectedPug?.id,
      fiscalStartDate: this.account.getCurrentYearFiscalStartDate(),
    });
    return ugb;
  }

  // -----------------------------------------------------------------------

  onTabChange(event: any) {
    if (event.index == 1) {
      AgFns.sizeColumnsToFit(this.pugBudgetGridOptions);
    }
  }

  override async addCrossValidationErrors() {
    EntityFns.checkForDupErrors(
      this.allPugs,
      (x) => x.name,
      (e, dupName) =>
        this.createValidationError(
          e,
          'name',
          `This user group name is a duplicate: '${dupName}.`
        )
    );

    const pugs = this.getEntitiesToValidate().filter(
      (x) => x instanceof ProgramUserGroup
    ) as ProgramUserGroup[];

    // User Groups do not need a record to be saved.  Records may be imported.

/*     pugs.forEach((s) => {
      if (
        s.activeStatusId == ActiveStatusEnum.Active &&
        s.programUserGroupMaps.length == 0
      ) {
        this.createValidationError(
          s,
          'name',
          `The Active Proximity User Group, '${s.name}', must have at least one Proximity User.`
        );
      }
    }); */

    this.allPugs.forEach((x) => {
      const budgets = x.programUserGroupBudgets;
      if (
        !budgets.some(
          (y) =>
            y.entityAspect.entityState.isAdded() ||
            y.entityAspect.entityState.isModified()
        )
      ) {
        return;
      }
      EntityFns.checkForDupErrors(
        budgets,
        (x) =>
          (x.budgetProductTypeTag?.name || '- Any -') +
          ' - ' +
          DateFns.fmtDateShort(x.fiscalStartDate),
        (e, dupName) =>
          this.createValidationError(
            e,
            'name',
            `Duplicate Budget Product Tag Name within the same fiscal year: '${dupName}'`
          )
      );
    });
  }

  override async afterSave() {
    return this.afterUndo();
  }

  override async afterUndo() {
    this.allPugs = await this.dbQueryService.getProgramUserGroups(
      this.accountId
    );
    this.budgetListItemsComponent.refreshBudgets();
    this.refreshPugGrid(true);

    // AgFns.updateMasterDetail(this.augGridOptions, this.selectedAug);
  }

  override async navigateToValidationError(error: EntityError) {
    const errEnt = error.entity;
    if (errEnt instanceof ProgramUserGroup) {
      AgFns.selectGridRowByKey(
        this.pugGridOptions,
        (e: ProgramUserGroup) => e.id,
        errEnt.id,
        error.propertyName
      );
    } else if (errEnt instanceof ProgramUserGroupBudget) {
      this.tabViewIndex = 1;
      AgFns.selectGridRowByKey(
        this.pugGridOptions,
        (e: ProgramUserGroup) => e.id,
        errEnt.programUserGroupId
      );
      UtilFns.wait(0);
      AgFns.selectGridRowByKey(
        this.pugBudgetGridOptions,
        (e: ProgramUserGroupBudget) => e.id,
        errEnt.id,
        error.propertyName
      );
    }
  }
}
